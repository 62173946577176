import { getTrainings } from "../../api/trainings";
import { requestStatus } from "../../services/index";

export default {
  namespaced: true,
  state: {
    training: null,
    anything: {
      1200: [
        {
          kalories: "1200",
          cardbs: "60",
          fat: "27",
          protein: "60",
          breakfast: [
            {
              name: "Italian Eggs",
              description:
                "1 serving of italian eggs contains 106 Calories. The macronutrient breakdown is 21% carbs, 51% fat, and 27% protein. This is a good source of vitamin c (97% of your Daily Value) and vitamin k (21% of your Daily Value).",
              directions: [
                "Put olive oil in a pan and heat it up. Chop all the vegetables and the basil. Sauté them until soft. Add tomato paste.",
                "Put eggs and egg whites in a bowl. Add seasonings and beat the eggs.",
                "Put the eggs in the pan. Let them sit for a minute on medium-low heat, then mix them as if making scrambled eggs. Continue until cooked.",
              ],
              ingredients: [
                "Egg: Whole, fresh eggs - ½ large or 25g",
                "Olive oil: Salad or cooking - 3g",
                "Pepper: Spices, black - 2g",
                "Onions: Raw - 3g",
                "Garlic: Raw - ¼ clove or 0.8g",
                "Tomatoes: Red, ripe, raw, year round average - ¹⁄₁₆ slice, medium or 1g",
                "Parsley: Raw - 0.9g",
                "Oregano: Spices, dried - 0.8g",
                "Basil: Fresh - ¼ leaf, whole or 0.1g",
                "Parmesan cheese: Grated - 0.5g",
                "Tomato paste: Canned, without salt added - 1g",
                "Yellow peppers: Sweet, raw - ¼ pepper, large or 47g",
                "Egg white: Raw, fresh eggs - ¾ egg white (separated from yolk) or 30g",
              ],
            },
            {
              name: "Green Power Protein Smoothie",
              description:
                "1 serving of green power protein smoothie contains 205 Calories. The macronutrient breakdown is 49% carbs, 20% fat, and 31% protein. This is a good source of protein (31% of your Daily Value), fiber (22% of your Daily Value), and potassium (20% of your Daily Value).",
              directions: [
                "Combine all ingredients in corresponding order into a blender and pulse until smooth. Add water to get the desired consistency. Enjoy!",
              ],
              ingredients: [
                "Spinach: Raw - 90g",
                "Whey protein powder: Gold Standard, Any flavor, 100% whey protein - ½ scoop (30g) or 15g",
                "Banana: Raw - 75g",
                "Celery: Raw - 2 stalks, small or 34g",
                "Ginger root: Raw - 3g",
                "Poppy seed: Spices - 9g",
                "Lime juice: Raw - 15g",
              ],
            },
          ],
          lunch: [
            {
              name: "Quick Buffalo Chicken Salad",
              description:
                "1 serving of quick buffalo chicken salad contains 228 Calories. The macronutrient breakdown is 15% carbs, 35% fat, and 50% protein. This is a good source of protein (51% of your Daily Value), potassium (13% of your Daily Value), and vitamin a (26% of your Daily Value).",
              directions: [
                "Mix hot sauce with chicken. Put on top of spinach, and add tomatoes to top. Toss together and enjoy!",
              ],
              ingredients: [
                "Pepper or hot sauce: Ready-to-serve - 29g",
                "Canned chicken: No broth - 103g",
                "Spinach: Raw - 30g",
                "Tomatoes: Green, raw - 1 medium or 123g",
              ],
            },
            {
              name: "Brussels Sprout Slaw",
              description:
                "1 serving of brussels sprout slaw contains 109 Calories. The macronutrient breakdown is 35% carbs, 53% fat, and 13% protein. This is a good source of fiber (15% of your Daily Value), potassium (9% of your Daily Value), and vitamin c (109% of your Daily Value).",
              directions: [
                "Keeping the stems intact, trim any loose or yellow leaves on the Brussels sprouts.",
                "If you have a mandoline or vegetable slicer: Holding each sprout by the stem end, finely shred them on the slicer. If you dont, chop the sprouts finely with a kitchen knife.",
                "Drizzle the slaw with olive oil, lemon juice, and salt and toss well.",
              ],
              ingredients: [
                "Salt: Table - 0.8g",
                "Olive oil: Salad or cooking - 7g",
                "Lemon juice: Raw - 5g",
                "Brussels sprouts: Raw - 113g",
              ],
            },
          ],
          snack: [
            {
              name: "Hummus Chickpea Snack Sandwiches",
              description:
                "1 serving of hummus chickpea snack sandwiches contains 142 Calories. The macronutrient breakdown is 57% carbs, 23% fat, and 20% protein. This is a good source of fiber (17% of your Daily Value).",
              directions: [
                "Place chickpeas in a large bowl and mash lightly with a fork. Add hummus and mix.",
                "Chop celery and stir into hummus mix.",
                "Slice pickle into thin rounds and line half the slices bread with these slices. Divide chickpea salad evenly over the slices. Top with roasted red pepper slices and remaining slices of bread. Press down lightly. If desired, cut away edges then cut each sandwich in half to make snack-sized sandwiches. Store in a covered container until ready to serve.",
              ],
              ingredients: [
                "Chickpeas (garbanzo beans, bengal gram), mature seeds, canned - 40g",
                "Hummus, Commercial - 21g",
                "Celery, Raw - ³⁄₁₆ stalk, smallor 3g",
                "Whole-wheat bread, Commercially prepared - 1 slice or 28g",
                "Pickles, Cucumber, dill or kosher dill - ³⁄₁₆ small or 6g",
                "Roasted Red Peppers, Freshdirect - 5g",
              ],
            },
          ],
          dinner: [
            {
              name: "Grilled Cod",
              description:
                "1 (8 oz) Fillet of grilled cod contains 240 Calories. The macronutrient breakdown is 5% carbs, 24% fat, and 71% protein. This is a good source of protein (73% of your Daily Value), potassium (21% of your Daily Value), and magnesium (18% of your Daily Value).",
              directions: [
                "Prepare grates and preheat grill to medium.",
                "Dice and mince garlic and add to a bowl with the oil, salt, pepper, rosemary, and onion powder.",
                "Coat fish fillet evenly.",
                "Place on grill and cook for about 15 minutes, flipping halfway through. Enjoy!",
              ],
              ingredients: [
                "Garlic, Raw - 1 clove or 3g",
                "Olive oil, Salad or cooking - 5g",
                "Pepper, Spices, black - 1 dash or 0.1g",
                "Salt, Table - 1 dash or 0.4g",
                "Rosemary, Fresh - 0.7g",
                "Onion powder, Spices - 2g",
                "Cod, Fish, atlantic, raw - 227g",
              ],
            },
            {
              name: "Stir Fried Mixed Veggies in Soy Sauce",
              description:
                "1 serving of stir fried mixed veggies in soy sauce contains 166 Calories. The macronutrient breakdown is 46% carbs, 40% fat, and 13% protein. This is a good source of fiber (21% of your Daily Value), potassium (7% of your Daily Value), and vitamin a (40% of your Daily Value).",
              directions: [
                "Melt coconut oil in a pan over medium-high heat.",
                "Add vegetables and stir, coating in the oil. Add soy sauce and cook until vegetables are tender. Serve hot and enjoy!",
              ],
              ingredients: [
                "Coconut oil - 7g",
                "Mixed vegetables, Frozen, unprepared - 142g",
                "Soy sauce, Made from soy (tamari) - 9g",
              ],
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "60",
          fat: "27",
          protein: "60",
          breakfast: [
            {
              name: "Egg Cups",
              description:
                "1 serving of egg cups contains 95 Calories. The macronutrient breakdown is 31% carbs, 6% fat, and 62% protein. This is a good source of protein (26% of your Daily Value), potassium (9% of your Daily Value), and vitamin a (14% of your Daily Value).",
              directions: [
                "Preheat oven to 375 degrees F.",
                "Spray muffin tin with non-stick cooking spray.",
                "Fill muffin cup halfway with liquid egg whites (you may not fill the full tray, depending on number of servings).",
                "Add chopped veggies to each cup until almost full.",
                "Bake for 20 minutes until eggs are set.",
                "Cool and enjoy!",
              ],
              ingredients: [
                "Pam cooking spray - Oil, original: 1 spray, about 1/3 second or 0.3g",
                "Egg white - Raw, fresh eggs: 122g",
                "Spinach - Raw: 15g",
                "Red bell pepper - Sweet, raw: ¼ medium or 30g",
                "Green bell pepper - Sweet, green, raw: ¼ medium or 30g",
                "Onions - Raw: ¼ medium or 28g",
                "Salt - Table: 1 dash or 0.4g",
              ],
            },
            {
              name: "Guacamole on Tostada",
              description:
                "1 serving of guacamole on tostada contains 192 Calories. The macronutrient breakdown is 36% carbs, 59% fat, and 6% protein. This is a good source of fiber (23% of your Daily Value), potassium (12% of your Daily Value), and vitamin b6 (24% of your Daily Value).",
              directions: [
                "Mash the avocado and mix with the tomatoes, lemon juice, onion and cilantro. Smear on the tostada. Enjoy.",
              ],
              ingredients: [
                "Avocados - California, raw: ½ fruit, without skin and seed or 68g",
                "Roma tomatoes - Red, ripe, raw. Also called Italian or plum tomato: 1 tomato or 62g",
                "Lemon juice - Raw: 15g",
                "Onions - Raw: 14g",
                "Fresh cilantro - Coriander leaves, raw: 1g",
                "Tostada shells - Corn: 1 piece or 12g",
              ],
            },
          ],
          lunch: [
            {
              name: "Quick Nori Roll with Cucumber and Avocado",
              description:
                "1 serving of quick nori roll with cucumber and avocado contains 232 Calories. The macronutrient breakdown is 21% carbs, 54% fat, and 26% protein. This is a good source of protein (28% of your Daily Value), fiber (17% of your Daily Value), and potassium (16% of your Daily Value).",
              ingredients: [
                "1 sheet of raw laver (3g)",
                "52g cucumber with peel, raw",
                "¼ avocado (50g), raw, all commercial varieties",
                "2 slices of silken firm tofu (168g), Mori-nu, soy",
                "8g alfalfa sprouts, raw",
                "16g regular sodium soy sauce, Shoyu",
                "5g whole roasted and toasted sesame seeds",
              ],
              directions: [
                "Thinly slice cucumber into slices or matchsticks, avocado into thin wedges, and tofu into thin strips.",
                "Place a sheet of nori on a clean and dry cutting board, shiny side down and longest edge facing you.",
                "Starting from the left edge, arrange the cucumber slices in overlapping rows on the nori, leaving a 1-inch margin of uncovered nori at right edge. Sprinkle with sesame seeds.",
                "Arrange avocado, tofu, and sprouts in an even, vertical pattern, 2 inches from the left edge.",
                "Rotate the cutting board by a quarter of a turn counter-clockwise so the uncovered strip of nori is furthest from you.",
                "Using both hands, start rolling the sheet of nori from the edge closest to you, folding it up and over the filling, then rolling it tightly away from you.",
                "Just as you're about to reach the uncovered strip of nori at the end, dip your fingertips in water and dab the nori lightly so it will stick and create a seal.",
                "Slice into halves or thick slices using a sharp chef knife. Serve with soy sauce for dipping.",
              ],
            },
            {
              name: "Rice Cake with Strawberries and Honey",
              description:
                "1 serving of rice cake with strawberries and honey contains 125 Calories. The macronutrient breakdown is 93% carbs, 3% fat, and 4% protein. This is a good source of vitamin C (54% of your Daily Value).",
              ingredients: [
                "83g raw strawberries",
                "21g honey",
                "1 plain brown rice cake (9g)",
              ],
              directions: [
                "Slice strawberries.",
                "Place on rice cake and drizzle with honey.",
                "Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Almonds",
              description:
                "1 ounce of almonds contains 164 Calories. The macronutrient breakdown is 14% carbs, 72% fat, and 14% protein. This is a good source of magnesium (18% of your Daily Value), vitamin E (48% of your Daily Value), and riboflavin (B2) (25% of your Daily Value).",
              ingredients: ["28g raw almonds"],
              directions: [
                "Enjoy almonds as a snack or add them to dishes for extra flavor and nutrition.",
              ],
            },
          ],
          dinner: [
            {
              name: "Pumpkin Ravioli",
              description:
                "1 serving of pumpkin ravioli contains 281 Calories. The macronutrient breakdown is 39% carbs, 47% fat, and 13% protein. This is a good source of protein (17% of your Daily Value), fiber (20% of your Daily Value), and calcium (16% of your Daily Value).",
              ingredients: [
                "61g 4 Cheese Ravioli (Kirkland Signature)",
                "13g raw onions",
                "37g ³⁄₁₆ large apples with skin",
                "5g unsalted butter",
                "2g salad or cooking olive oil",
                "25g table white wine",
                "0.5g dried thyme",
                "0.3g ground sage",
                "0.5g dried rosemary",
                "0.4g black pepper",
                "20g canned pumpkin, without salt",
                "6g raw almonds",
              ],
              directions: [
                "Boil a pot of lightly salted water and cook ravioli according to package directions. Make sure to save ¾-cup of pasta water once the ravioli have finished cooking.",
                "In a large skillet, sauté the onions and apples in butter and olive oil over high heat until they become tender and translucent, approximately 8 minutes. Stir often to avoid burning.",
                "Reduce heat to medium-low and add the white wine, thyme, sage, rosemary, and black pepper. Cook for 5 minutes.",
                "Add the pumpkin and stir for an additional minute. Add the reserved pasta water and simmer on medium-low heat for an additional 8 minutes, or until the sauce thickens.",
                "Add the ravioli to the pumpkin sauce and coat well. Portion out the ravioli, garnish with toasted almonds, and enjoy!",
              ],
            },
            {
              name: "Spinach, Lemon, and Shrimp Salad",
              description:
                "1 serving of spinach, lemon, and shrimp salad contains 115 Calories. The macronutrient breakdown is 11% carbs, 43% fat, and 46% protein. This is a good source of protein (24% of your Daily Value), potassium (9% of your Daily Value), and vitamin A (36% of your Daily Value).",
              ingredients: [
                "60g raw spinach",
                "3g raw lemon juice",
                "5g salad or cooking olive oil",
                "85g raw shrimp, mixed species",
              ],
              directions: [
                "Cook shrimp in boiling water until pink and opaque (done). Remove shells if present.",
                "Whisk lemon juice and olive oil together. Toss spinach leaves and baby shrimp in this dressing and enjoy!",
              ],
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "60",
          fat: "27",
          protein: "60",
          breakfast: [
            {
              name: "Veggie Omelet",
              description:
                "1 serving of veggie omelet contains 149 Calories. The macronutrient breakdown is 30% carbs, 6% fat, and 64% protein. This is a good source of protein (43% of your Daily Value), potassium (18% of your Daily Value), and vitamin A (22% of your Daily Value).",
              ingredients: [
                "30g raw spinach",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "40g raw onions",
                "37g sweet red bell pepper",
                "70g raw white mushrooms",
                "182g raw egg whites",
                "15g plain almond milk",
              ],
              directions: [
                "Clean the spinach off and place it into a pan while still wet. Cook on medium heat and season with salt and pepper.",
                "Once the spinach is wilted (2-3 minutes), add the onion, bell pepper, and mushroom, and cook until the onions are translucent, the pepper chunks are soft, and mushrooms are tender.",
                "Whisk the egg whites with the almond milk.",
                "Add the eggs to the pan and scramble until cooked. Top with salt and pepper. Enjoy!",
              ],
            },
            {
              name: "Peanut Butter and Peach Toast",
              description:
                "1 serving of peanut butter and peach toast contains 194 Calories. The macronutrient breakdown is 44% carbs, 40% fat, and 16% protein. This is a good source of protein (14% of your Daily Value), fiber (15% of your Daily Value), and potassium (7% of your Daily Value).",
              ingredients: [
                "75g raw peaches (½ medium)",
                "28g commercially prepared whole-wheat bread (1 slice)",
                "16g chunk style peanut butter, without salt",
              ],
              directions: [
                "Toast bread.",
                "While bread is toasting, cut half of a peach into slices.",
                "Top toast with peanut butter and sliced peaches. Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Chia Cottage Cheese with Blueberries",
              description:
                "1 serving of chia cottage cheese with blueberries contains 190 Calories. The macronutrient breakdown is 29% carbs, 40% fat, and 31% protein. This is a good source of protein (26% of your Daily Value), fiber (18% of your Daily Value), and calcium (17% of your Daily Value).",
              ingredients: [
                "113g creamed cottage cheese (large or small curd)",
                "37g raw blueberries",
                "12g dried chia seeds",
              ],
              directions: [
                "Put the cottage cheese in a bowl, add the blueberries and stir well.",
                "Sprinkle on the chia seeds.",
                "Stir together once more and enjoy!",
              ],
            },
            {
              name: "Banana Mango Yogurt Smoothie",
              description:
                "1 serving of banana mango yogurt smoothie contains 99 Calories. The macronutrient breakdown is 88% carbs, 4% fat, and 7% protein. This is a good source of potassium (7% of your Daily Value) and vitamin C (38% of your Daily Value).",
              ingredients: [
                "51g raw banana (½ small)",
                "39g plain, clean water",
                "8g nonfat, plain Greek yogurt",
                "83g raw mangos",
                "111g frozen water (5 ice cubes)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Easy Spinach and Scallion Salad",
              description:
                "1 serving of easy spinach and scallion salad contains 173 Calories. The macronutrient breakdown is 21% carbs, 68% fat, and 11% protein. This is a good source of fiber (17% of your Daily Value), potassium (21% of your Daily Value), and calcium (19% of your Daily Value).",
              ingredients: [
                "150g raw spinach",
                "50g raw scallions (2 large)",
                "14g olive oil (salad or cooking)",
                "12g raw lemon juice (¼ lemon yields)",
                "0.1g black pepper (1 dash)",
              ],
              directions: [
                "Wash spinach well, drain, and chop. Squeeze out excess water.",
                "Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions/green onions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and serve.",
              ],
            },
          ],
          dinner: [
            {
              name: "Spicy Chicken Soup",
              description:
                "1 serving of spicy chicken soup contains 318 Calories. The macronutrient breakdown is 33% carbs, 30% fat, and 38% protein. This is a good source of protein (54% of your Daily Value), fiber (24% of your Daily Value), and potassium (25% of your Daily Value).",
              ingredients: [
                "118g raw chicken breast (½ full breast, both fillets)",
                "1g fresh cilantro (½ sprigs)",
                "0.2g cayenne pepper (½ pinch)",
                "1g raw lime juice",
                "50g raw avocado (¼ fruit)",
                "31g raw carrots (½ medium)",
                "107g raw potato (½ medium)",
              ],
              directions: [
                "Boil chicken for 15 mins with enough water to cover the pieces. Shred when cooked. KEEP broth!",
                "Peel and cut carrot and potato into small pieces.",
                "Place chicken, carrots, potato, and cayenne pepper back into pot with broth and cook for 5 minutes.",
                "Dice avocado.",
                "Serve in bowl, garnish with avocado, cilantro leaves, and drizzle the lime juice over it, or serve with 1 lime slice per serving.",
              ],
            },
            {
              name: "Garlic Balsamic Green Beans",
              description:
                "1 serving of garlic balsamic green beans contains 81 Calories. The macronutrient breakdown is 51% carbs, 37% fat, and 12% protein. This is a good source of vitamin K (17% of your Daily Value).",
              ingredients: [
                "3g olive oil (salad or cooking)",
                "113g raw green beans (snap beans)",
                "4g balsamic vinegar",
                "2g garlic powder",
                "2g black pepper",
              ],
              directions: [
                "Heat oil in frying pan over medium-high heat.",
                "Add green beans and keep moving around in pan until they get some color (approx 3-5 minutes).",
                "Add balsamic vinegar, garlic powder, and the pepper, sprinkling over all of the green beans.",
                "Remove from heat and cover for a few minutes to let flavors develop.",
                "Serve.",
              ],
            },
          ],
        },
      ],
      1700: [
        {
          kalories: "1700",
          cardbs: "85",
          fat: "38",
          protein: "85",
          breakfast: [
            {
              name: "Avocado-egg Toast",
              description:
                "1 serving of avocado-egg toast contains 233 Calories. The macronutrient breakdown is 28% carbs, 54% fat, and 18% protein. This is a good source of protein (19% of your Daily Value), fiber (18% of your Daily Value), and potassium (8% of your Daily Value).",
              ingredients: [
                "1 slice (28g) whole-wheat bread (commercially prepared)",
                "¼ fruit (50g) raw avocado (all commercial varieties)",
                "1 large (50g) whole egg (fresh)",
                "1g olive oil (salad or cooking)",
                "1 dash (0.4g) table salt",
                "1 dash (0.1g) black pepper",
              ],
              directions: [
                "Toast bread.",
                "Mash avocado into a spreadable consistency and spread onto toast.",
                "Heat olive oil in a pan over medium heat.",
                "Crack egg into the pan and season with salt and pepper to taste. Cook egg to desired doneness.",
                "Top toast with the cooked egg and enjoy.",
              ],
            },
            {
              name: "Very Berry Cottage Cheese",
              description:
                "1 serving of very berry cottage cheese contains 229 Calories. The macronutrient breakdown is 38% carbs, 11% fat, and 51% protein. This is a good source of protein (53% of your Daily Value), fiber (17% of your Daily Value), and potassium (8% of your Daily Value).",
              ingredients: [
                "226g lowfat cottage cheese (1% milkfat)",
                "55g raw strawberries",
                "49g raw blueberries",
                "48g raw blackberries",
              ],
              directions: [
                "Mix cottage cheese with sliced strawberries, blueberries, and blackberries.",
                "Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Peanut Butter Mango Smoothie",
              description:
                "1 Smoothie of peanut butter mango smoothie contains 427 Calories. The macronutrient breakdown is 38% carbs, 29% fat, and 33% protein. This is a good source of protein (67% of your Daily Value), fiber (24% of your Daily Value), and potassium (21% of your Daily Value).",
              ingredients: [
                "83g raw mango",
                "244g reduced fat milk (2% milkfat)",
                "237g plain, clean water",
                "8g ground cinnamon",
                "16g chunk style peanut butter (without salt)",
                "240g nonfat plain Greek yogurt",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
            },
            {
              name: "Sliced Bell Pepper",
              description:
                "1 pepper of sliced bell pepper contains 37 Calories. The macronutrient breakdown is 78% carbs, 9% fat, and 13% protein. This is a good source of vitamin A (21% of your Daily Value), vitamin B6 (27% of your Daily Value), and vitamin C (169% of your Daily Value).",
              ingredients: ["1 medium red bell pepper (119g)"],
              directions: [
                "Wash the bell pepper, slice it in half, then remove the seeds and stem.",
                "Slice into strips and enjoy! Optionally eat with a side of hummus dip.",
              ],
            },
          ],
          snack: [
            {
              name: "Ham and Salami Roll-ups",
              description:
                "1 serving of ham and salami roll-ups contains 254 Calories. The macronutrient breakdown is 13% carbs, 60% fat, and 27% protein. This is a good source of protein (31% of your Daily Value), potassium (8% of your Daily Value), and vitamin A (19% of your Daily Value).",
              ingredients: [
                "15g hummus (commercial)",
                "28g Italian salami (pork)",
                "27g roasted red peppers",
                "1 small banana pepper (33g, raw)",
                "57g sliced ham (regular, approximately 11% fat)",
              ],
              directions: [
                "Stack ham, hummus, salami, and banana peppers.",
                "Roll up and enjoy!",
              ],
            },
          ],
          dinner: [
            {
              name: "Beef and Broccoli Stir Fry",
              description:
                "1 serving of beef and broccoli stir fry contains 330 Calories. The macronutrient breakdown is 24% carbs, 40% fat, and 35% protein. This is a good source of protein (53% of your Daily Value), potassium (17% of your Daily Value), and iron (52% of your Daily Value).",
              makes: "4 servings",
              prepTime: "15 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "6g cornstarch",
                "30g water (plain, clean)",
                "0.4g garlic powder (spices)",
                '113g beef round (raw, trimmed to 0" fat)',
                "7g vegetable oil (Natreon canola, high stability, non-trans, high oleic)",
                "91g broccoli (raw)",
                "28g onion (¼ medium, raw)",
                "24g soy sauce (made from soy, tamari)",
                "5g brown sugar",
                "0.5g ginger (spices, ground)",
              ],
              directions: [
                "In a bowl, combine 2 tablespoons cornstarch, 2 tablespoons water, and garlic powder until smooth. Add sliced beef and toss to coat evenly.",
                "In a large skillet or wok over medium-high heat, stir-fry beef in 1 tablespoon oil until it reaches desired doneness; remove beef and keep warm.",
                "Stir-fry broccoli and onion in remaining oil for 4-5 minutes until tender. Return beef to the pan.",
                "Combine soy sauce, brown sugar, ginger, and the remaining cornstarch and water until smooth; add to the pan. Cook and stir for 2 minutes.",
              ],
            },
            {
              name: "Tuna in Cucumber Cups",
              description:
                "1 serving of tuna in cucumber cups contains 190 Calories. The macronutrient breakdown is 19% carbs, 45% fat, and 35% protein. This is a good source of protein (31% of your Daily Value), potassium (8% of your Daily Value), and vitamin B6 (25% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "83g tuna (light, canned in water, drained solids, ½ can)",
                "151g cucumber (with peel, raw, ½ cucumber)",
                "40g light mayonnaise (salad dressing, light)",
                "0.5g black pepper (spices)",
                "0.09g fresh dill",
              ],
              directions: [
                'Cut cucumber into 1" rounds. Using a melon baller or small spoon, scoop out the seeds, leaving about 1/4" on the bottom to create a little cup.',
                "In a small bowl, mix drained tuna, mayo, and pepper together until well blended.",
                "Fill each cup with the tuna salad and top with a little sprinkle of fresh dill. Serve.",
              ],
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "85",
          fat: "38",
          protein: "85",
          breakfast: [
            {
              name: "Cinnamon Protein Oats",
              description:
                "1 serving of cinnamon protein oats contains 260 Calories. The macronutrient breakdown is 51% carbs, 7% fat, and 42% protein. This is a good source of protein (51% of your Daily Value) and fiber (22% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "237g water (plain, clean water)",
                "40g rolled oats (plain, dry oatmeal)",
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein, 1 scoop)",
                "3g cinnamon (spices, ground)",
              ],
              directions: [
                "Boil water.",
                "Mix in oats, protein powder, and cinnamon. Boil until tender, 5-10 minutes.",
                "Serve immediately and enjoy!",
              ],
            },
            {
              name: "Peaches & Almond Butter on Toast",
              description:
                "1 serving of peaches & almond butter on toast contains 198 Calories. The macronutrient breakdown is 42% carbs, 43% fat, and 14% protein. This is a good source of fiber (16% of your Daily Value), potassium (7% of your Daily Value), and vitamin E (35% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "75g peaches (raw, ½ medium)",
                "28g whole-wheat bread (commercially prepared, 1 slice)",
                "16g almond butter (nuts, plain, without salt added)",
              ],
              directions: [
                "Toast bread.",
                "While bread is toasting, cut half of a peach into slices.",
                "Top toast with almond butter and sliced peaches.",
                "Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Tuna Stuffed Pepper",
              description:
                "1 serving of tuna stuffed pepper contains 200 Calories. The macronutrient breakdown is 20% carbs, 12% fat, and 68% protein. This is a good source of protein (61% of your Daily Value), potassium (15% of your Daily Value), and iron (46% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "119g red bell pepper (sweet, raw, 1 medium)",
                "165g tuna (light, canned in water, drained solids, 1 can)",
                "101g cucumber (peeled, raw, ½ medium)",
                "15g mustard (prepared, yellow)",
              ],
              directions: [
                "Mix the tuna with the sliced cucumber and one tablespoon of mustard.",
                "Slice the bell pepper in half and clean out the seeds.",
                "Fill both halves with the tuna mixture.",
              ],
            },
            {
              name: "Veggie Apple Smoothie",
              description:
                "1 serving of veggie apple smoothie contains 264 Calories. The macronutrient breakdown is 47% carbs, 48% fat, and 5% protein. This is a good source of fiber (35% of your Daily Value), potassium (22% of your Daily Value), and vitamin A (59% of your Daily Value).",
              makes: "1 serving",
              prepTime: "7 minutes",
              ingredients: [
                "61g raw carrot (1 medium)",
                "4g raw ginger root",
                "101g raw avocado (all commercial varieties)",
                "75g raw cucumber (¼ cucumber with peel)",
                "40g raw celery (1 medium stalk)",
                "124g unsweetened apple juice (canned or bottled, with added ascorbic acid)",
                "89g ice cubes (4 cubes)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
              ],
            },
          ],
          snack: [
            {
              name: "Almond Butter & Celery",
              description:
                "1 serving of almond butter & celery contains 217 Calories. The macronutrient breakdown is 17% carbs, 70% fat, and 13% protein. This is a good source of fiber (19% of your Daily Value), potassium (12% of your Daily Value), and calcium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "2 minutes",
              ingredients: [
                "32g plain almond butter (without salt added)",
                "128g raw celery (2 large stalks, 11 inches long)",
              ],
              directions: ["Spread almond butter on celery and enjoy!"],
            },
          ],
          dinner: [
            {
              name: "Pan Fried Pork Chops",
              description:
                "1 serving of pan fried pork chops contains 426 Calories. The macronutrient breakdown is 7% carbs, 51% fat, and 42% protein. This is a good source of protein (77% of your Daily Value), fiber (17% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "3 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "12g organic coconut flour",
                "2g table salt",
                "199g fresh pork loin, center loin (chops), bone-in, raw (1 chop without refuse)",
                "0.7g black pepper",
                "0.6g cayenne pepper",
                "5g unsalted butter",
              ],
              directions: [
                "Mix together all of the dry ingredients in a container that can fit the pork chops. Dry off the pork chops.",
                "Heat a skillet on high and add the butter. Coat the pork chops in the coating and add to the pan to fry.",
                "Cook for 4-5 minutes per side or until the pork chop reads a temperature of at least 145 degrees F for medium or 160 degrees F for well done. Enjoy!",
              ],
            },
            {
              name: "Zesty Honey Kale",
              description:
                "This is a simple steamed kale recipe that’s a bit tangy and sweet. 1 serving contains 135 Calories. The macronutrient breakdown is 52% carbs, 27% fat, and 22% protein. This is a good source of protein (16% of your Daily Value), fiber (26% of your Daily Value), and potassium (21% of your Daily Value).",
              makes: "5 servings",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "201g raw kale",
                "12g lemon juice",
                "3g olive oil",
                "2g honey",
                "2g Dijon mustard",
                "0.06g crushed red pepper flakes",
                "0.3g garlic powder",
                "0.02g black pepper",
                "0.08g table salt",
              ],
              directions: [
                "Wash kale in cold water. Remove stems, and cut leaves into 2-3 pieces.",
                "Steam kale for 6 minutes. Drain in sink and run cold water over cooked kale to stop it from continuing to cook.",
                "Whisk remaining ingredients in a bowl. Toss kale in the dressing mixture.",
              ],
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "85",
          fat: "38",
          protein: "85",
          breakfast: [
            {
              name: "Blackberry Yogurt Parfait",
              description:
                "1 serving of blackberry yogurt parfait contains 340 Calories. The macronutrient breakdown is 32% carbs, 34% fat, and 34% protein. This is a good source of protein (54% of your Daily Value), fiber (46% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "227g nonfat Greek yogurt, plain",
                "144g raw blackberries",
                "12g raw almonds (about 10 almonds)",
                "14g flaxseed",
              ],
              directions: [
                "Top yogurt with the blackberries, almonds, and flax.",
                "Serve and enjoy!",
              ],
            },
            {
              name: "Buttered Toast",
              description:
                "1 slice of buttered toast contains 121 Calories. The macronutrient breakdown is 39% carbs, 49% fat, and 12% protein. This has a relatively high calorie density, with 346 Calories per 100g.",
              makes: "1 slice",
              prepTime: "3 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "1 slice (28g) whole-wheat bread, commercially prepared",
                "7g unsalted butter",
              ],
              directions: [
                "Toast bread to desired doneness.",
                "Spread butter across until evenly distributed.",
                "Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "All American Tuna",
              description:
                "1 serving of all American tuna contains 256 Calories. The macronutrient breakdown is 25% carbs, 21% fat, and 55% protein. This is a good source of protein (63% of your Daily Value), potassium (10% of your Daily Value), and iron (48% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 can (165g) light tuna, canned in water, drained solids",
                "15g light mayonnaise",
                "19g cooked celery, boiled and drained without salt",
                "½ large dill or kosher dill pickle (68g)",
                "1 slice (25g) wheat bread",
              ],
              directions: [
                "Mix all ingredients together in a bowl.",
                "Serve with bread.",
              ],
            },
            {
              name: "Mixed Greens with Sliced Cucumber and Avocado",
              description:
                "1 serving of mixed greens with sliced cucumber and avocado contains 160 Calories. The macronutrient breakdown is 32% carbs, 61% fat, and 7% protein. This is a good source of fiber (26% of your Daily Value), potassium (10% of your Daily Value), and vitamin K (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "113g field greens salad mix (Dole)",
                "67g peeled raw cucumber",
                "½ California avocado (68g), without skin and seed",
                "15g reduced fat Italian dressing",
                "0.4g salt (1 dash)",
                "0.1g black pepper (1 dash)",
              ],
              directions: [
                "Combine salad greens, cucumber, and avocado.",
                "Toss with low-carb salad dressing of your choice and season to taste with salt and freshly ground black pepper.",
              ],
            },
          ],
          snack: [
            {
              name: "Grown-Up PB&J Toast",
              description:
                "1 serving of grown-up PB&J toast contains 226 Calories. The macronutrient breakdown is 48% carbs, 38% fat, and 14% protein. This is a good source of protein (14% of your Daily Value), fiber (23% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "16g almond butter (plain, without salt added)",
                "½ medium nectarine (71g, raw)",
                "36g blackberries (raw)",
                "4g honey",
                "1 slice whole-wheat bread (commercially prepared)",
              ],
              directions: [
                "Toast the bread and spread almond butter on top.",
                "Chop nectarine into small cubes. In a small bowl, mix the chopped nectarines and blackberries.",
                "Spoon the fruit mixture on top of the toast.",
                "Drizzle honey on top of the fruit toast. Serve immediately.",
              ],
            },
          ],
          dinner: [
            {
              name: "Tomato-Basil Salmon with Garbanzo Beans",
              description:
                "1 serving of tomato-basil salmon with garbanzo beans contains 316 Calories. The macronutrient breakdown is 31% carbs, 24% fat, and 45% protein. This is a good source of protein (65% of your Daily Value), fiber (32% of your Daily Value), and vitamin D (103% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "240g red, ripe canned tomatoes, packed in tomato juice",
                "3g raw garlic",
                "142g raw pink salmon",
                "1 dash salt (approximately 0.4g)",
                "1 dash black pepper (approximately 0.1g)",
                "1 spray PAM cooking spray (about 0.3g)",
                "55g cooked chickpeas (garbanzo beans), without salt",
                "5g fresh basil",
              ],
              directions: [
                "Combine tomatoes and garlic in a small bowl; set aside.",
                "Season both sides of the salmon fillet with salt and pepper.",
                "Heat a medium skillet lightly covered with spray over medium-high heat.",
                "Add salmon; cook for 2 minutes on one side. Turn the salmon.",
                "Layer the tomato mixture and chickpeas over the salmon in the skillet; cook, covered, for 5 minutes, or until the salmon is cooked through and flakes easily when tested with a fork.",
                "Garnish with basil and enjoy!",
              ],
            },
            {
              name: "Peppers Cucumber & Avocado Salad",
              description:
                "1 serving of peppers cucumber & avocado salad contains 275 Calories. The macronutrient breakdown is 30% carbs, 64% fat, and 7% protein. This is a good source of fiber (43% of your Daily Value), potassium (26% of your Daily Value), and vitamin A (28% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "36g green leaf lettuce, raw",
                "136g cherry tomatoes (about 8 cherry tomatoes), red, ripe, raw",
                "26g cucumber, with peel, raw",
                "136g California avocado, without skin and seed (1 fruit)",
                "30g red bell pepper, sweet, raw (about 3 rings)",
                "23g raw lemon juice",
              ],
              directions: [
                "Chop all ingredients and toss together well.",
                "Drizzle with lemon juice and enjoy!",
              ],
            },
          ],
        },
      ],
      2200: [
        {
          kalories: "2200",
          cardbs: "110",
          fat: "49",
          protein: "110",
          breakfast: [
            {
              name: "Ham and Egg Mushroom Cups",
              description:
                "2 cups of ham and egg mushroom cups contains 362 Calories. The macronutrient breakdown is 10% carbs, 62% fat, and 28% protein. This is a good source of protein (46% of your Daily Value), potassium (22% of your Daily Value), and vitamin B6 (50% of your Daily Value).",
              makes: "2 cups",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "100g whole fresh eggs (2 large)",
                "168g portabella mushrooms (2 whole)",
                "57g extra lean sliced ham",
                "2g black pepper",
                "14g olive oil (salad or cooking)",
              ],
              directions: [
                "Remove stem and clean out mushroom cups with a damp cloth.",
                "Rub olive oil on the outside of the mushrooms.",
                "Line mushroom cup with ham, then crack an egg into each cup.",
                "Sprinkle with black pepper and herbs to taste.",
                "Bake for 20-30 minutes at 375°F in a toaster oven or preheated oven.",
              ],
            },
            {
              name: "Yogurt with Apricot and Grapefruit",
              description:
                "1 serving of yogurt with apricot and grapefruit contains 339 Calories. The macronutrient breakdown is 66% carbs, 4% fat, and 30% protein. This is a good source of protein (49% of your Daily Value), fiber (22% of your Daily Value), and potassium (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "8 minutes",
              ingredients: [
                "240g nonfat plain Greek yogurt",
                "128g raw pink/red/white grapefruit (½ medium)",
                "65g dried apricots, sulfured and uncooked",
              ],
              directions: [
                "Chop dried apricots into pieces and section out the grapefruit.",
                "Mix both into yogurt and enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Cilantro Crab & Corn Stuffed Peppers",
              description:
                "2 servings of cilantro crab & corn stuffed peppers contain 401 Calories. The macronutrient breakdown is 34% carbs, 37% fat, and 29% protein. This is a good source of protein (53% of your Daily Value), potassium (22% of your Daily Value), and vitamin A (27% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "135g canned blue crab",
                "75g raw cherry tomatoes, red and ripe",
                "164g canned sweet yellow corn, drained",
                "14g olive oil",
                "5g raw lemon juice",
                "7g fresh cilantro (3 sprigs)",
                "119g medium red bell pepper",
              ],
              directions: [
                "Chop tomatoes and cilantro.",
                "Combine with drained crab, corn, olive oil, and lemon juice.",
                "Cut a bell pepper in half length-wise and remove seeds.",
                "Stuff each half with crab mixture and serve.",
              ],
            },
            {
              name: "Ginger, Apple, and Mint Green Smoothie",
              description:
                "1 serving of ginger, apple, and mint green smoothie contains 106 Calories. The macronutrient breakdown is 91% carbs, 4% fat, and 5% protein. This is a good source of fiber (19% of your Daily Value), potassium (8% of your Daily Value), and vitamin A (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "237g plain, clean water",
                "0.05g fresh peppermint (1 whole leaf)",
                "6g raw ginger root",
                "182g medium apple (with skin)",
                "30g raw spinach",
              ],
              directions: [
                "Combine all ingredients in a blender.",
                "Pulse until smooth, adding more water if necessary.",
                "Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Matcha Green Tea Chia Pudding",
              description:
                "1 serving of matcha green tea chia pudding contains 288 Calories. The macronutrient breakdown is 49% carbs, 42% fat, and 9% protein. This is a good source of fiber (48% of your Daily Value), potassium (7% of your Daily Value), and calcium (43% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "240g plain almond milk",
                "36g dried chia seeds",
                "14g honey",
                "2g Tropical Sweetened Matcha Green Tea Mix (Trader Joe's)",
                "0.4g table salt (1 dash)",
              ],
              directions: [
                "Mix together almond milk, honey, matcha, and salt until well combined.",
                "In a bowl, pour in chia seeds, then the matcha almond milk mixture.",
                "Stir to coat chia seeds with the liquid.",
                "Refrigerate for 10 minutes, then stir again well.",
                "Keep in refrigerator for at least 2 hours or overnight.",
                "Dust the top with a little matcha before serving.",
              ],
            },
          ],
          dinner: [
            {
              name: "Smoked Salmon and Broccoli Soup",
              description:
                "1 serving of smoked salmon and broccoli soup contains 230 Calories. The macronutrient breakdown is 38% carbs, 34% fat, and 28% protein. This is a good source of protein (31% of your Daily Value), fiber (24% of your Daily Value), and potassium (20% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "7g unsalted butter",
                "45g raw leeks (½ leek)",
                "0.1g bay leaf (spices)",
                "227g raw broccoli",
                "256g plain, clean water",
                "1g dry chicken broth bouillon (¼ cube)",
                "31g lowfat cultured buttermilk",
                "50g smoked chinook salmon (lox)",
                "0.1g table salt (¼ dash)",
                "0.03g black pepper (¼ dash)",
                "3g raw chives",
              ],
              directions: [
                "Heat the butter in a large saucepan and add the leeks and bay leaf.",
                "Cook over low heat for 8-10 minutes or until the leek is really soft, then stir through the broccoli.",
                "Pour over the stock and buttermilk, and bring to a simmer.",
                "Gently bubble for 10-15 minutes until the broccoli is really tender.",
                "Add two-thirds of the smoked salmon, stir through, and season to taste with salt and pepper.",
                "Serve the soup in deep bowls with the remaining smoked salmon and chopped chives on top.",
              ],
            },
            {
              name: "Tuna Apple Salad",
              description:
                "1 serving of tuna apple salad contains 482 Calories. The macronutrient breakdown is 24% carbs, 24% fat, and 53% protein. This is a good source of protein (117% of your Daily Value), potassium (16% of your Daily Value), and iron (74% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "330g light tuna (2 cans, drained in water)",
                "111g large apple (with skin, ½ large)",
                "15g sweet pickle relish",
                "44g regular mayonnaise-like dressing (with salt)",
                "3g garlic powder (spices)",
              ],
              directions: [
                "Drain water from cans and place tuna in a bowl.",
                "Finely chop the apple and add it to the bowl.",
                "Stir in sweet relish, mayonnaise, and garlic powder.",
                "Use as desired and enjoy!",
              ],
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "110",
          fat: "49",
          protein: "110",
          breakfast: [
            {
              name: "Apple Sandwiches with Almond Butter & Granola",
              description:
                "1 serving of apple sandwiches with almond butter & granola contains 424 Calories. The macronutrient breakdown is 44% carbs, 46% fat, and 9% protein. This is a good source of protein (19% of your Daily Value), fiber (33% of your Daily Value), and potassium (12% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "32g almond butter (plain, without salt added)",
                "182g medium apple (with skin, 1 medium)",
                "29g granola with almonds (ready-to-eat, Quaker)",
              ],
              directions: [
                "Core the apple and slice it into rings.",
                "Spread almond butter on the apple rings, sprinkle with granola, and top with another almond-buttered apple slice.",
                "Serve and enjoy!",
              ],
            },
            {
              name: "Nonfat Yogurt",
              description:
                "1 bowl of nonfat yogurt contains 142 Calories. The macronutrient breakdown is 25% carbs, 6% fat, and 69% protein. This is a good source of protein (44% of your Daily Value), potassium (7% of your Daily Value), and calcium (26% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "1 minute",
              ingredients: ["240g nonfat Greek yogurt (plain)"],
              directions: [
                "Scoop yogurt into a cup or bowl.",
                "Serve and enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Very Green Veggie Protein Smoothie",
              description:
                "2 servings of very green veggie protein smoothie contains 294 Calories. The macronutrient breakdown is 26% carbs, 37% fat, and 37% protein. This is a good source of protein (52% of your Daily Value), potassium (23% of your Daily Value), and calcium (31% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "355g plain, clean water",
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "4g ginger root (raw, 2 slices)",
                "51g celery (raw)",
                "52g cucumber (with peel, raw)",
                "75g avocado (raw, all commercial varieties)",
                "67g kale (Scotch, raw)",
                "30g spinach (raw)",
              ],
              directions: [
                "Combine all ingredients in a blender.",
                "Pulse until smooth.",
                "Enjoy!",
              ],
            },
            {
              name: "Flour Tortilla with Peanut Butter, Blackberries, and Honey",
              description:
                "1 serving of flour tortilla with peanut butter, blackberries, and honey contains 360 Calories. The macronutrient breakdown is 42% carbs, 45% fat, and 13% protein. This is a good source of protein (21% of your Daily Value), fiber (20% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "32g chunk style peanut butter (without salt)",
                "1 medium flour tortilla (46g, ready-to-bake or -fry)",
                "36g raw blackberries",
                "7g honey",
              ],
              directions: [
                "Spread peanut butter on a tortilla.",
                "Top with blackberries and a drizzle of honey.",
                "Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Quick and Easy Low Carb Caprese Salad",
              description:
                "1 serving of quick and easy low carb caprese salad contains 303 Calories. The macronutrient breakdown is 7% carbs, 71% fat, and 22% protein. This is a good source of protein (30% of your Daily Value), calcium (53% of your Daily Value), and vitamin A (14% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "85g cherry tomatoes (red, ripe, raw)",
                "66g part skim mozzarella cheese",
                "14g olive oil (salad or cooking)",
                "3g fresh basil",
              ],
              directions: [
                "Slice tomatoes and mozzarella.",
                "Drizzle with olive oil.",
                "Chiffonade (thinly slice) basil and sprinkle on top.",
                "Serve immediately.",
              ],
            },
          ],
          dinner: [
            {
              name: "Spicy Shrimp Wrap",
              description:
                "1 wrap of spicy shrimp wrap contains 299 Calories. The macronutrient breakdown is 48% carbs, 24% fat, and 29% protein. This is a good source of protein (38% of your Daily Value), fiber (15% of your Daily Value), and potassium (14% of your Daily Value).",
              makes: "1 wrap",
              prepTime: "15 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "113g mixed species raw shrimp",
                "15g light mayonnaise",
                "1g Sriracha Sauce (Trader Joe's)",
                "1 medium flour tortilla (46g)",
                "100g peeled cucumber (raw)",
                "100g sweet red bell pepper (raw)",
                "15g raw spinach",
                "2g fresh cilantro (coriander leaves, raw)",
              ],
              directions: [
                "Bring 2 cups water to a boil in a small saucepan. Add shrimp and cook until pink, about 3 minutes. Drain and rinse under cold water.",
                "Combine mayonnaise and Sriracha. Spread on the lower hemisphere of the tortilla, leaving 1 inch along the edge.",
                "Spread the shrimp evenly over the mayo, top with cucumber, bell pepper, spinach, and cilantro.",
                "Fold in the sides and roll the wrap tightly from the bottom to the top. Cut in half.",
              ],
            },
            {
              name: "Green Bean Healthy Tuna Salad",
              description:
                "3 servings of green bean healthy tuna salad contains 370 Calories. The macronutrient breakdown is 32% carbs, 36% fat, and 32% protein. This is a good source of protein (57% of your Daily Value), fiber (37% of your Daily Value), and potassium (26% of your Daily Value).",
              makes: "3 servings",
              prepTime: "5 minutes",
              ingredients: [
                "298g raw green beans (snap beans)",
                "76g raw onions (2 large slices)",
                "128g light canned tuna in water (drained solids)",
                "99g red ripe cherry tomatoes (raw)",
                "14g olive oil (salad or cooking)",
              ],
              directions: [
                "Combine all ingredients together in a bowl and mix together well.",
                "Serve and enjoy!",
              ],
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "110",
          fat: "49",
          protein: "110",
          breakfast: [
            {
              name: "Tomatillo and Avocado Scramble",
              description:
                "1 serving of tomatillo and avocado scramble contains 337 Calories. The macronutrient breakdown is 9% carbs, 58% fat, and 33% protein. This is a good source of protein (49% of your Daily Value), fiber (14% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "50g whole fresh egg (1 large)",
                "182g raw fresh egg whites",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "9g olive oil (salad or cooking)",
                "50g raw avocado (¼ fruit)",
                "33g raw tomatillos",
              ],
              directions: [
                "Combine egg and egg whites in a bowl. Season with salt and pepper and whisk.",
                "Heat a skillet on medium-low heat. Once hot, add oil.",
                "Add the egg mixture, cook, stirring occasionally for 3 to 4 minutes or until the eggs are set.",
                "Top the eggs with avocado and tomatillos. Serve immediately and enjoy!",
              ],
            },
            {
              name: "Rice Cake with Peanut Butter, Pear, and Honey",
              description:
                "1 serving of rice cake with peanut butter, pear, and honey contains 216 Calories. The macronutrient breakdown is 59% carbs, 33% fat, and 9% protein. This is a good source of fiber (19% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "9g plain brown rice cake (1 cake)",
                "16g chunk style peanut butter (without salt)",
                "115g raw pear (½ large)",
                "7g honey",
              ],
              directions: [
                "Spread peanut butter on a rice cake.",
                "Top with sliced pears.",
                "Drizzle with honey.",
                "Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Spicy Tuna Salad",
              description:
                "1 serving of spicy tuna salad contains 453 Calories. The macronutrient breakdown is 18% carbs, 52% fat, and 31% protein. This is a good source of protein (67% of your Daily Value), fiber (45% of your Daily Value), and potassium (28% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "165g light canned tuna in water (1 can)",
                "27g green olives (10 olives)",
                "25g raw scallions (spring onions or scallions)",
                "7g raw jalapeno peppers (½ pepper)",
                "14g canned capers",
                "0.5g cayenne pepper",
                "49g raw lemon juice",
                "7g olive oil (salad or cooking)",
                "157g raw romaine or cos lettuce (¼ head)",
                "101g raw avocado (½ fruit)",
              ],
              directions: [
                "Chop olives, finely chop the jalapeno, and dice the lettuce.",
                "Combine all of the ingredients except for the lettuce and avocado.",
                "Serve over the lettuce.",
                "Slice avocado and serve on top.",
              ],
            },
            {
              name: "Banana",
              description:
                "1 banana contains 105 Calories. The macronutrient breakdown is 93% carbs, 3% fat, and 4% protein. This is a good source of potassium (9% of your Daily Value) and vitamin B6 (34% of your Daily Value).",
              makes: "1 serving",
              ingredients: ["1 medium raw banana (118g)"],
            },
          ],
          snack: [
            {
              name: "Pinto Bean Salad",
              description:
                "1 serving of pinto bean salad contains 335 Calories. The macronutrient breakdown is 46% carbs, 44% fat, and 9% protein. This is a good source of protein (15% of your Daily Value), fiber (52% of your Daily Value), and potassium (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "½ avocado (101g)",
                "6 cherry tomatoes (102g)",
                "Red bell pepper (46g)",
                "1 medium onion (110g)",
                "1 medium leaf of iceberg lettuce (8g)",
                "80g canned pinto beans",
                "30g reduced-fat Italian dressing",
              ],
              directions:
                "Chop avocado, tomatoes, bell pepper, and onion. Toss with chopped lettuce, pinto beans, and Italian dressing. Serve and enjoy!",
            },
          ],
          dinner: [
            {
              name: "High Protein Pita Pizza",
              description:
                "1 pizza of high protein pita pizza contains 722 Calories. The macronutrient breakdown is 24% carbs, 48% fat, and 28% protein. This is a good source of protein (91% of your Daily Value), fiber (21% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 pizza",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "1 large whole-wheat pita bread (64g)",
                "85g cheddar cheese",
                "3 ¹⁵⁄₁₆ slices Canadian bacon (94g)",
                "63g canned pizza sauce",
              ],
              directions:
                "Preheat oven to 400°F. Spread 1/4 cup pizza sauce on pita bread, top with cheese and Canadian bacon. Place in the oven on a pizza stone or baking sheet and cook for 5-10 minutes, watching until the cheese is melted. Take out of the oven and cut into 4-8 slices.",
            },
            {
              name: "Asparagus Herb Delight",
              description:
                "4 ounces of asparagus herb delight contains 26 Calories. The macronutrient breakdown is 65% carbs, 6% fat, and 29% protein. This is a good source of vitamin K (44% of your Daily Value).",
              makes: "16 ounces",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "113g raw asparagus",
                "¼ spray Pam cooking spray (about 1/3 second, 0.07g)",
                "2g table salt",
                "¼ dash black pepper (0.03g)",
                "1g fresh basil",
                "⅛ raw lemon without seeds (14g)",
              ],
              directions:
                "Preheat oven to 400°F. Clean asparagus by snapping the stems (bend the spear and allow it to break naturally, cutting off the lowest inch from the stalk). Place asparagus on a sheet tray and lightly coat with cooking spray. Roast in the oven until just tender, 4 to 8 minutes, depending on the thickness of the asparagus. Season with salt and pepper, top with herbs, and squeeze lemon over the asparagus. This dish tastes great warm, cold, or at room temperature.",
            },
          ],
        },
      ],
      2700: [
        {
          kalories: "2700",
          cardbs: "135",
          fat: "60",
          protein: "135",
          breakfast: [
            {
              name: "Strawberry Cottage Cheese Smoothie",
              description:
                "1 serving of strawberry cottage cheese smoothie contains 256 Calories. The macronutrient breakdown is 24% carbs, 10% fat, and 65% protein. This is a good source of protein (72% of your Daily Value), potassium (6% of your Daily Value), and calcium (26% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "113g lowfat cottage cheese (1% milkfat)",
                "72g raw strawberries",
                "30g whey protein powder (1 scoop, Gold Standard, any flavor, 100% whey protein)",
                "61g lowfat milk (1% milkfat, with added vitamin A and vitamin D)",
                "2g vanilla extract",
                "237g ice cubes",
              ],
              directions:
                "Combine all ingredients in a blender and pulse until smooth. Enjoy!",
            },
            {
              name: "Flour Tortilla with Peanut Butter, Blackberries, and Honey",
              description:
                "1 serving of flour tortilla with peanut butter, blackberries, and honey contains 360 Calories. The macronutrient breakdown is 42% carbs, 45% fat, and 13% protein. This is a good source of protein (21% of your Daily Value), fiber (20% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "32g chunk style peanut butter, without salt",
                "1 medium flour tortilla (46g)",
                "36g raw blackberries",
                "7g honey",
              ],
              directions:
                "Spread peanut butter on a tortilla, then top with blackberries and a drizzle of honey. Enjoy!",
            },
          ],
          lunch: [
            {
              name: "Red Eye Protein Parfait",
              description:
                "1 serving of red eye protein parfait contains 536 Calories. The macronutrient breakdown is 38% carbs, 21% fat, and 41% protein. This is a good source of protein (100% of your Daily Value), fiber (24% of your Daily Value), and potassium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "240g nonfat plain Greek yogurt",
                "30g whey protein powder (1 scoop)",
                "5g unsweetened cocoa powder",
                "3g instant coffee powder",
                "11g honey",
                "41g store-bought granola",
                "37g raw blueberries",
              ],
              directions:
                "Mix half of the yogurt with the protein powder, cocoa powder, instant coffee, and honey in a bowl. Mix until smooth. Place mixture in a glass jar or bowl, then top with the rest of the yogurt, granola, and blueberries. Enjoy; best served chilled.",
            },
            {
              name: "Tomato Basil Layered Salad",
              description:
                "1 serving of tomato basil layered salad contains 184 Calories. The macronutrient breakdown is 36% carbs, 57% fat, and 7% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin K (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "91g red ripe raw tomatoes (½ large whole)",
                "68g California avocados (½ fruit, without skin and seed)",
                "4g fresh basil (8 whole leaves)",
                "15g commercial hummus",
                "32g balsamic vinegar",
              ],
              directions: [
                "Slice tomato into ¼-inch thick slices.",
                "Slice avocado into ¼-inch thick slices.",
                "Tear off, rinse, and dry basil.",
                "Layer tomato, basil, and avocado to make a striped presentation.",
                "Add hummus and vinegar to a food processor and pulse until smooth.",
                "Pour over salad just before serving. Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Tuna & White Bean Salad",
              description:
                "1 serving of tuna & white bean salad contains 361 Calories. The macronutrient breakdown is 47% carbs, 20% fat, and 33% protein. This is a good source of protein (54% of your Daily Value), fiber (35% of your Daily Value), and potassium (23% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "197g canned white beans (mature seeds)",
                "83g light tuna (½ can, drained solids)",
                "12g young green onions (1 stalk, tops only)",
                "8g lemon juice (canned or bottled)",
                "7g olive oil (salad or cooking)",
                "2g table salt",
                "0.5g black pepper (spices)",
              ],
              directions: [
                "Pour beans into a colander and rinse with water. Allow the excess water to drain off.",
                "Drain the can of tuna.",
                "Combine the drained beans and tuna in a bowl.",
                "Thinly slice the green onions and add to the bowl as well.",
                "Add the olive oil and lemon juice to the bowl, along with salt and pepper. Stir to combine.",
                "Taste the mixture and add salt, pepper, or lemon juice to your liking. Serve!",
              ],
            },
          ],
          dinner: [
            {
              name: "Garlic Chicken",
              description:
                "1 serving of garlic chicken contains 353 Calories. The macronutrient breakdown is 12% carbs, 25% fat, and 63% protein. This is a good source of protein (95% of your Daily Value), potassium (17% of your Daily Value), and vitamin B6 (150% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "236g raw chicken breast (1 full breast, both fillets)",
                "3g raw garlic (1 clove, minced)",
                "9g brown sugar",
                "3g olive oil (salad or cooking)",
                "2g table salt",
                "0.5g black pepper (spices)",
              ],
              directions: [
                "Preheat oven to 450°F. Line a baking dish or cookie sheet with aluminum foil and lightly coat with cooking spray or lightly brush with oil.",
                "In a small sauté pan, sauté garlic with the oil until tender. Remove from heat and stir in brown sugar. Add additional herbs and spices as desired.",
                "Season chicken with salt and pepper.",
                "Place breasts in the prepared baking dish and cover with the garlic and brown sugar mixture.",
                "Bake uncovered for 15-30 minutes, or until juices run clear. Cooking time will depend on the size and thickness of your chicken.",
              ],
            },
            {
              name: "Pomegranate Salad",
              description:
                "1 serving of pomegranate salad contains 638 Calories. The macronutrient breakdown is 45% carbs, 50% fat, and 5% protein. This is a good source of protein (17% of your Daily Value), fiber (82% of your Daily Value), and potassium (39% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "36g green leaf lettuce, raw",
                "149g red cherry tomatoes, raw",
                "136g California avocado, without skin and seed (1 fruit)",
                "44g lime juice (1 lime yields)",
                "14g olive oil (salad or cooking)",
                "16g balsamic vinegar",
                "282g raw pomegranate (1 pomegranate)",
              ],
              directions: [
                "Wash the lettuce and tomatoes.",
                "Pit the avocado and chop it.",
                "Chop the tomatoes and shred the lettuce. Place all ingredients in a bowl.",
                "Sprinkle the ingredients with lime juice, olive oil, and balsamic vinegar.",
                "Peel the pomegranate and add the seeds to the bowl.",
                "Toss everything to combine.",
                "Enjoy!",
              ],
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "135",
          fat: "60",
          protein: "135",
          breakfast: [
            {
              name: "Sweet Potato Hash",
              description:
                "1 serving of sweet potato hash contains 304 Calories. The macronutrient breakdown is 42% carbs, 43% fat, and 15% protein. This is a good source of protein (20% of your Daily Value), fiber (23% of your Daily Value), and potassium (21% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "60g red bell pepper, raw (½ medium)",
                "60g raw spinach",
                '130g raw sweet potato (1 sweet potato, 5" long)',
                "56g whole fresh egg (1 extra large)",
                "9g avocado oil",
              ],
              directions: [
                "Cook chopped bell pepper, spinach, and shredded sweet potatoes in avocado oil for a few minutes, until spinach is just wilted and potatoes are tender, about 5 minutes.",
                "Cook the egg in a non-stick frying pan until the yolk has reached desired doneness.",
                "Serve the egg on top of the potatoes and spinach mixture. Enjoy!",
              ],
            },
            {
              name: "Cinnamon, Honey, Apple Cottage Cheese",
              description:
                "1 serving of cinnamon, honey, apple cottage cheese contains 323 Calories. The macronutrient breakdown is 59% carbs, 7% fat, and 34% protein. This is a good source of protein (51% of your Daily Value), fiber (17% of your Daily Value), and potassium (9% of your Daily Value).",
              makes: "1 serving",
              prepTime: "4 minutes",
              ingredients: [
                "0.6g ground cinnamon",
                "226g lowfat cottage cheese (1% milkfat)",
                "21g honey",
                "182g medium apple (with skin)",
              ],
              directions: [
                "Mix cottage cheese with cinnamon, diced apple, and honey.",
                "Enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Protein Yogurt and Blueberries",
              description:
                "1 serving of protein yogurt and blueberries contains 503 Calories. The macronutrient breakdown is 30% carbs, 28% fat, and 41% protein. This is a good source of protein (96% of your Daily Value), fiber (24% of your Daily Value), and potassium (14% of your Daily Value).",
              makes: "1 serving",
              prepTime: "8 minutes",
              ingredients: [
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "227g nonfat Greek yogurt (plain)",
                "148g raw blueberries",
                "28g dry roasted sunflower seed kernels (without salt)",
              ],
              directions: [
                "Mix protein powder in yogurt.",
                "Add blueberries and sunflower seeds.",
                "Enjoy!",
              ],
            },
            {
              name: "Chopped Salad",
              description:
                "1 serving of chopped salad contains 119 Calories. The macronutrient breakdown is 43% carbs, 49% fat, and 7% protein. This is a good source of potassium (13% of your Daily Value), vitamin A (12% of your Daily Value), and vitamin C (66% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "149g cherry tomatoes (red, ripe, raw)",
                "104g cucumber (with peel, raw)",
                "10g parsley (raw)",
                "60g lemon juice (raw)",
                "0.4g salt (table)",
                "7g olive oil (salad or cooking)",
                "0.1g pepper (spices, black)",
              ],
              directions: [
                "Finely chop the tomato, cucumber, and parsley.",
                "Add lemon juice, olive oil, pepper, and salt.",
                "Mix and serve.",
              ],
            },
          ],
          snack: [
            {
              name: "Chocolate Peanut Butter Oatmeal Protein Shake",
              description:
                "1 serving of chocolate peanut butter oatmeal protein shake contains 325 Calories. The macronutrient breakdown is 33% carbs, 31% fat, and 37% protein. This is a good source of protein (56% of your Daily Value), fiber (20% of your Daily Value), and potassium (7% of your Daily Value).",
              makes: "2 servings",
              prepTime: "7 minutes",
              ingredients: [
                "20g oatmeal (cereals, Quaker, dry rolled oats)",
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "5g cocoa (dry powder, unsweetened)",
                "119g ice cubes (frozen water)",
                "16g peanut butter (smooth style, with salt)",
                "120g almond milk (plain)",
              ],
              directions: [
                "Process the oatmeal, protein powder, and cocoa in your blender until powdery.",
                "Add the ice, peanut butter, and almond milk, and process until smooth.",
                "Enjoy!",
              ],
            },
          ],
          dinner: [
            {
              name: "Tilapia with Kale and Tomato",
              description:
                "2 servings of tilapia with kale and tomato contains 595 Calories. The macronutrient breakdown is 20% carbs, 28% fat, and 52% protein. This is a good source of protein (146% of your Daily Value), fiber (43% of your Daily Value), and vitamin D (70% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "340g tilapia (fish, raw)",
                "2g salt (table)",
                "0.2g pepper (spices, black)",
                "11g olive oil (salad or cooking)",
                "3g garlic (raw, minced)",
                "268g kale (raw)",
                "180g tomatoes (red, ripe, raw, year-round average)",
              ],
              directions: [
                "Season both sides of the tilapia with salt and pepper; set aside.",
                "Heat oil in a large skillet over medium heat.",
                "Add garlic and kale; cook, stirring frequently, for 1 minute.",
                "Add tomatoes (with juice); bring to a boil. Reduce heat to low.",
                "Place tilapia fillet on top of the kale mixture, cook, covered, for 1 minute.",
                "Gently turn the tilapia over and cook for 2 to 3 minutes, or until tilapia is cooked through and flakes easily when tested with a fork.",
                "Remove from heat and serve.",
              ],
            },
            {
              name: "Microwave Sour Cream Baked Potato",
              description:
                "1 serving of microwave sour cream baked potato contains 526 Calories. The macronutrient breakdown is 48% carbs, 47% fat, and 5% protein. This is a good source of fiber (23% of your Daily Value), potassium (35% of your Daily Value), and magnesium (20% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "299g white potato (flesh and skin, baked, large)",
                "14g olive oil (salad or cooking)",
                "14g unsalted butter",
                "12g sour cream (cultured)",
                "0.2g black pepper (spices)",
                "0.8g table salt",
              ],
              directions: [
                "Microwave the potato for 5 minutes.",
                "Flip the potato and microwave the other side for 5 minutes.",
                "Remove from microwave and cut in half.",
                "Pour olive oil into the cut potato and let soak in for 1-2 minutes.",
                "Spread butter and let sit for 1 minute, then cover with the sour cream.",
                "Season with salt and pepper, to taste. Enjoy!",
              ],
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "135",
          fat: "60",
          protein: "135",
          breakfast: [
            {
              name: "Edamame and Pear Crostini",
              description:
                "1 serving of edamame and pear crostini contains 416 Calories. The macronutrient breakdown is 56% carbs, 27% fat, and 17% protein. This is a good source of protein (31% of your Daily Value), fiber (21% of your Daily Value), and potassium (9% of your Daily Value).",
              makes: "6 servings",
              prepTime: "2 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "39g frozen edamame (unprepared)",
                "7g olive oil (salad or cooking)",
                "15g fresh spearmint",
                "7g shredded Parmesan cheese",
                "0.5g table salt",
                "0.09g black pepper (spices)",
                "30g raw pears (³⁄₁₆ medium)",
                "1 slice large sourdough bread (French or Vienna, 96g)",
              ],
              directions: [
                "Boil edamame in salted water for about 8 minutes.",
                "Remove beans with a slotted spoon and place in ice water for a couple of minutes to cool, then drain.",
                "Set aside 1/4 cup of the whole edamame and place the remaining edamame in a food processor or blender and process until coarsely chopped.",
                "In a separate bowl, combine the mashed edamame with the whole edamame, three tablespoons of olive oil, chopped mint, cheese, salt, and pepper.",
                "Toast the bread.",
                "Top each slice with some of the edamame mixture, garnish with chopped pears, and serve.",
              ],
            },
            {
              name: "Yogurt with Walnuts & Honey",
              description:
                "1 serving of yogurt with walnuts & honey contains 260 Calories. The macronutrient breakdown is 24% carbs, 34% fat, and 42% protein. This is a good source of protein (50% of your Daily Value), potassium (9% of your Daily Value), and calcium (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              ingredients: [
                "16g black walnuts (dried)",
                "240g nonfat plain Greek yogurt",
                "7g honey",
              ],
              directions: [
                "Rough-chop walnuts and mix into yogurt.",
                "Top with honey and enjoy!",
              ],
            },
          ],
          lunch: [
            {
              name: "Strawberry Almond Trim Smoothie",
              description:
                "1 serving of strawberry almond trim smoothie contains 322 Calories. The macronutrient breakdown is 43% carbs, 23% fat, and 34% protein. This is a good source of protein (49% of your Daily Value), fiber (17% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "7 minutes",
              ingredients: [
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "111g frozen unsweetened strawberries",
                "7g raw almonds (6 almonds)",
                "11g honey",
                "360g plain almond milk",
                "111g ice cubes (5 cubes)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
            },
            {
              name: "Flour Tortilla with Peanut Butter, Blackberries, and Honey",
              description:
                "1 serving of flour tortilla with peanut butter, blackberries, and honey contains 360 Calories. The macronutrient breakdown is 42% carbs, 45% fat, and 13% protein. This is a good source of protein (21% of your Daily Value), fiber (20% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "32g chunk style peanut butter (without salt)",
                "46g medium flour tortilla (ready-to-bake or -fry)",
                "36g raw blackberries",
                "7g honey",
              ],
              directions: [
                "Spread peanut butter on a tortilla.",
                "Top with blackberries and a drizzle of honey.",
                "Enjoy!",
              ],
            },
          ],
          snack: [
            {
              name: "Chicken and Avocado Salad",
              description:
                "1 serving of chicken and avocado salad contains 404 Calories. The macronutrient breakdown is 12% carbs, 55% fat, and 34% protein. This is a good source of protein (62% of your Daily Value), fiber (27% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "125g canned chicken (no broth)",
                "101g raw avocado (½ fruit)",
                "7g fresh cilantro (3 sprigs)",
                "9g lime juice (from ⅙ lime)",
                "0.4g salt (to taste)",
                "60g butterhead lettuce (4 large leaves)",
              ],
              directions: [
                "Chop the cilantro and cut up the avocado.",
                "Combine the chicken, avocado, cilantro, lime juice, and salt (use only a squeeze of lime juice, and salt to taste).",
                "Arrange the butterhead lettuce leaves and serve the chicken salad on top.",
              ],
            },
          ],
          dinner: [
            {
              name: "Enchilada-Stuffed Portobellos",
              description:
                "1 serving of enchilada-stuffed portobellos contains 453 Calories. The macronutrient breakdown is 29% carbs, 51% fat, and 19% protein. This is a good source of protein (41% of your Daily Value), fiber (35% of your Daily Value), and potassium (21% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "168g raw portabella mushrooms (2 whole)",
                "14g olive oil (salad or cooking)",
                "36g raw sweet corn (yellow)",
                "65g canned low sodium black beans (drained)",
                "126g red enchilada sauce (Kroger)",
                "0.8g salt (to taste)",
                "4g whole-grain wheat flour",
                "56g part skim milk mozzarella cheese",
                "3g raw chives",
              ],
              directions: [
                "Preheat the oven to 450°F.",
                "Remove the stems from the mushrooms. Using a small spoon, gently scoop out the gills of the mushrooms and discard them.",
                "Brush the exteriors of the mushrooms with olive oil and place them on a large plate.",
                "In a medium bowl, whisk together the enchilada sauce, salt, and flour until no lumps remain.",
                "Spoon around 2 tablespoons of corn and black beans into each mushroom. Drizzle with the sauce. Top with shredded cheese.",
                "Bake for 5-10 minutes, until the cheese is bubbly and the mushrooms are softened (but not mushy). Sprinkle with chives and serve.",
              ],
            },
            {
              name: "Tuna Apple Salad",
              description:
                "1 serving of tuna apple salad contains 482 Calories. The macronutrient breakdown is 24% carbs, 24% fat, and 53% protein. This is a good source of protein (117% of your Daily Value), potassium (16% of your Daily Value), and iron (74% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "330g light canned tuna in water (drained solids, 2 cans)",
                "111g raw apple with skin (½ large)",
                "15g sweet pickle relish",
                "44g regular mayonnaise-like dressing (with salt)",
                "3g garlic powder",
              ],
              directions: [
                "Drain the water from the cans and place the tuna in a bowl.",
                "Finely chop the apple and add it to the bowl.",
                "Stir in the sweet relish, mayonnaise, and garlic powder.",
                "Use as desired and enjoy!",
              ],
            },
          ],
        },
      ],
    },
    keto: {
      1200: [
        {
          kalories: "1200",
          cardbs: "27",
          fat: "80",
          protein: "45",
          breakfast: [
            {
              name: "Blueberry Vanilla Greek Yogurt",
              description:
                "1 serving of blueberry vanilla greek yogurt contains 244 Calories. The macronutrient breakdown is 16% carbs, 62% fat, and 23% protein. This is a good source of protein (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "2 minutes",
              ingredients: [
                "160g plain whole fat Greek yogurt",
                "2g vanilla extract",
                "1g stevia sweetener",
                "19g raw blueberries",
              ],
              directions: [
                "Mix vanilla and stevia into the yogurt.",
                "Top with blueberries and enjoy!",
              ],
            },
            {
              name: "Bacon",
              description:
                "2 strips of bacon contains 100 Calories. The macronutrient breakdown is 1% carbs, 87% fat, and 12% protein. This has a relatively high calorie density, with 417 Calories per 100g.",
              makes: "4 strips",
              cookTime: "10 minutes",
              ingredients: ["24g raw cured pork bacon (2 strips)"],
              directions: [
                "Cook bacon in a skillet over medium to medium-high heat until browned and crisp, turning to brown evenly.",
                "Bacon can also be cooked in an oven at 350°F for about 20 minutes, or microwave at about 50-60 seconds per strip.",
              ],
            },
          ],
          lunch: [
            {
              name: "Turkey, Goat Cheese, and Avocado Rolls",
              description:
                "1 roll of turkey, goat cheese, and avocado rolls contains 196 Calories. The macronutrient breakdown is 19% carbs, 67% fat, and 14% protein. This is a good source of fiber (19% of your Daily Value), potassium (10% of your Daily Value), and vitamin A (24% of your Daily Value).",
              makes: "4 rolls",
              prepTime: "10 minutes",
              ingredients: [
                "1 leaf outer romaine lettuce (28g)",
                "1 slice white rotisserie deli cut turkey (10g)",
                "15g soft goat cheese",
                "9g dry roasted mixed nuts (without added salt)",
                "¼ avocado (50g)",
                "¼ medium red bell pepper (30g)",
              ],
              directions: [
                "Top each lettuce leaf with a turkey slice.",
                "Spread each turkey slice with 1 tbsp of goat cheese.",
                "Sprinkle 1 tsp of nuts on each roll and top with 1 sliced avocado.",
                "Roll and garnish with chopped bell pepper, if desired. Enjoy!",
              ],
            },
            {
              name: "Cucumber Avocado Salad",
              description:
                "1 serving of cucumber avocado salad contains 94 Calories. The macronutrient breakdown is 27% carbs, 66% fat, and 6% protein. This is a good source of fiber (15% of your Daily Value), potassium (8% of your Daily Value), and vitamin K (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "½ medium cucumber, peeled (101g)",
                "¼ avocado (50g)",
                "1g fresh cilantro",
                "5g lemon juice",
                "2 dash table salt (0.8g)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Chop cucumber, avocado, and cilantro.",
                "Combine all ingredients and toss.",
                "Serve cold.",
              ],
            },
          ],
          snack: [
            {
              name: "Easy Spinach and Scallion Salad",
              description:
                "1 serving of easy spinach and scallion salad contains 173 Calories. The macronutrient breakdown is 21% carbs, 68% fat, and 11% protein. This is a good source of fiber (17% of your Daily Value), potassium (21% of your Daily Value), and calcium (19% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "150g raw spinach",
                "2 large scallions (50g)",
                "14g olive oil",
                "12g lemon juice (¼ lemon)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Wash spinach well, drain, and chop. Squeeze out excess water.",
                "Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions/green onions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and serve.",
              ],
            },
          ],
          dinner: [
            {
              name: "Peppered Steaks with Mushrooms",
              description:
                "1 serving of peppered steaks with mushrooms contains 326 Calories. The macronutrient breakdown is 3% carbs, 67% fat, and 29% protein. This is a good source of protein (43% of your Daily Value), potassium (13% of your Daily Value), and vitamin B6 (54% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "15 minutes",
              ingredients: [
                '113g beef tenderloin, trimmed to 1/8" fat',
                "0.5g black pepper",
                "3g olive oil",
                "15g beef broth or bouillon",
                "65g raw portabella mushrooms",
              ],
              directions: [
                "Trim fat from steaks. Rub both sides of steaks with pepper.",
                "In a large skillet, heat olive oil over medium-high heat.",
                "Add steaks; reduce heat to medium. Cook to desired temperature, 7 to 9 minutes for medium rare (145°F) to medium (160°F), turning once halfway through cooking time.",
                "Transfer steaks to serving platter; keep warm.",
                "Add beef broth to skillet. Cook and stir until bubbly to loosen any browned bits in the bottom of the skillet.",
                "Add mushrooms; simmer uncovered for 4 minutes.",
                "Spoon sauce over steaks to serve.",
              ],
            },
            {
              name: "Balsamic Arugula Salad",
              description:
                "1 serving of balsamic arugula salad contains 61 Calories. The macronutrient breakdown is 25% carbs, 68% fat, and 7% protein. This is a good source of vitamin K (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "62g red, ripe tomatoes, cubed",
                "5g balsamic vinegar",
                "20g raw arugula",
                "4g olive oil",
              ],
              directions: [
                "Cube tomatoes and chop arugula roughly.",
                "Combine all ingredients and toss to coat.",
              ],
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "27",
          fat: "80",
          protein: "45",
          breakfast: [
            {
              name: "Sweet Egg Pancake",
              description:
                "1 serving of sweet egg pancake contains 365 Calories. The macronutrient breakdown is 7% carbs, 74% fat, and 19% protein. This is a good source of protein (31% of your Daily Value), vitamin A (43% of your Daily Value), and vitamin B12 (48% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "6 minutes",
              ingredients: [
                "113g whole, fresh eggs (approximately 2 ¼ large)",
                "57g cream cheese",
                "3g ground cinnamon",
                "1g sucralose (1 packet)",
              ],
              directions: [
                "Warm cream cheese in microwave.",
                "Beat in eggs, cinnamon, and Splenda.",
                "Pour batter into a non-stick skillet and cook over medium heat until golden brown on each side.",
              ],
            },
          ],
          lunch: [
            {
              name: "Very Green Veggie Protein Smoothie",
              description:
                "1 serving of very green veggie protein smoothie contains 147 Calories. The macronutrient breakdown is 26% carbs, 37% fat, and 37% protein. This is a good source of protein (26% of your Daily Value), potassium (11% of your Daily Value), and calcium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "178g plain, clean water",
                "15g whey protein powder (½ scoop, any flavor)",
                "2g raw ginger root (1 slice)",
                "25g raw celery",
                "26g raw cucumber (with peel)",
                "38g raw avocado",
                "34g raw Scotch kale",
                "15g raw spinach",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
            },
            {
              name: "Pecans",
              description:
                "1 ounce of pecans contains 196 Calories. The macronutrient breakdown is 7% carbs, 88% fat, and 5% protein. This has a relatively high calorie density, with 691 Calories per 100g.",
              makes: "1 ounce",
              ingredients: ["28g pecans (nuts)"],
              nutrition: {
                calories: 196,
                fat: "88%",
                carbs: "7%",
                protein: "5%",
              },
            },
          ],
          snack: [
            {
              name: "Cucumber Cups with Tapenade",
              description:
                "1 serving of cucumber cups with tapenade contains 177 Calories. The macronutrient breakdown is 23% carbs, 73% fat, and 3% protein. This is a good source of vitamin K (26% of your Daily Value).",
              makes: "4 servings",
              prepTime: "15 minutes",
              ingredients: [
                "½ cucumber (151g), with peel, raw",
                "9g capers, canned",
                "43g Kalamata olives, pitted",
                "7g onions, raw",
                "½ clove garlic, minced (2g)",
                "⅛ jalapeño pepper (2g), raw",
                "2g lemon zest",
                "0.3g dried oregano",
                "0.4g dried tarragon",
                "3g olive oil, salad or cooking",
              ],
              directions: [
                "Slice cucumbers into 1/2-inch-thin rounds. Scoop out half the seeds of each round using a small teaspoon to create the cups, being careful not to cut all the way through. Set aside.",
                "Mince the capers and olives, and mix them with onion, garlic, jalapeño, lemon zest, oregano, tarragon, and oil in a small bowl to make the tapenade.",
                "Fill each cup with a heaping tablespoon of tapenade. Garnish each filled cucumber cup with any leftover herbs you may have and serve.",
              ],
              nutrition: {
                calories: 177,
                fat: "73%",
                carbs: "23%",
                protein: "3%",
              },
            },
          ],
          dinner: [
            {
              name: "Pan Seared Chicken Breast",
              description:
                "1 breast of pan seared chicken breast contains 261 Calories. The macronutrient breakdown is 0.1% carbs, 58% fat, and 42% protein. This is a good source of protein (47% of your Daily Value), potassium (8% of your Daily Value), and vitamin B6 (73% of your Daily Value).",
              makes: "1 breast",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "14g olive oil, salad or cooking",
                "1 chicken breast fillet (118g), raw",
                "1 dash salt, table (0.4g)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Heat olive oil in a skillet on medium heat.",
                "Rub chicken with salt and pepper on both sides and place it in the skillet.",
                "Cook uncovered on each side for 5 minutes.",
                "Cover the skillet and cook the chicken on low for an additional 10 minutes.",
                "Let cool slightly and serve. Enjoy!",
              ],
              nutrition: {
                calories: 261,
                fat: "58%",
                protein: "42%",
                carbs: "0.1%",
              },
            },
            {
              name: "Balsamic Asparagus",
              description:
                "1 serving of balsamic asparagus contains 58 Calories. The macronutrient breakdown is 34% carbs, 50% fat, and 17% protein. This is a good source of vitamin K (43% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "6 large asparagus spears (120g), raw",
                "3g olive oil, salad or cooking",
                "0.07g black pepper, spices",
                "4g balsamic vinegar",
              ],
              directions: [
                "Wash asparagus and snap off the tough ends.",
                "Heat olive oil in a frying pan.",
                "Add asparagus and stir until they change color (approximately 3-5 minutes).",
                "Add balsamic vinegar and sprinkle pepper over the asparagus.",
                "Remove from heat and cover for a few minutes to let flavors develop. Serve.",
              ],
              nutrition: {
                calories: 58,
                fat: "50%",
                protein: "17%",
                carbs: "34%",
              },
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "27",
          fat: "80",
          protein: "45",
          breakfast: [
            {
              name: "Zucchini Scramble",
              description:
                "1 serving of zucchini scramble contains 150 Calories. The macronutrient breakdown is 30% carbs, 51% fat, and 19% protein. This is a good source of potassium (13% of your Daily Value), vitamin A (16% of your Daily Value), and vitamin B6 (33% of your Daily Value).",
              makes: "3 servings",
              prepTime: "2 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "37g raw onions, ⅓ medium",
                "40g sweet red bell pepper, ⅓ medium, raw",
                "108g raw zucchini, ⅓ large, summer squash with skin",
                "41g no salt added tomato sauce",
                "5g Natreon canola vegetable oil, high stability, non-trans, high oleic",
                "37g whole fresh eggs, ⅔ extra large",
              ],
              directions: [
                "Cube zucchini, seed and chop the bell pepper, and chop the onion. Beat the eggs.",
                "Heat the oil in a large skillet over medium-high heat.",
                "Add zucchini and cook, stirring, until soft.",
                "Add the onion and bell pepper; cook until tender, about 5 minutes.",
                "Make a clearing in the center of the skillet and pour in the eggs. Cook and stir to scramble.",
                "When the eggs are cooked, stir in the tomato sauce until everything is coated. Serve warm.",
              ],
              nutrition: {
                calories: 150,
                fat: "51%",
                protein: "19%",
                carbs: "30%",
              },
            },
            {
              name: "Pecans",
              description:
                "1 ounce of pecans contains 196 Calories. The macronutrient breakdown is 7% carbs, 88% fat, and 5% protein. This has a relatively high calorie density, with 691 Calories per 100g.",
              makes: "1 ounce",
              ingredients: ["28g pecans, nuts"],
              nutrition: {
                calories: 196,
                fat: "88%",
                protein: "5%",
                carbs: "7%",
                calorieDensity: "691 Calories per 100g",
              },
            },
          ],
          lunch: [
            {
              name: "Keto Avocado Pepperoni Salad",
              description:
                "1 serving of keto avocado pepperoni salad contains 344 Calories. The macronutrient breakdown is 8% carbs, 74% fat, and 18% protein. This is a good source of protein (28% of your Daily Value), fiber (17% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "68g avocado, California, raw (½ fruit, without skin and seed)",
                "32g pepperoni (16 slices round)",
                "28g mozzarella cheese, part skim milk",
                "0.4g salt, table",
                "0.1g pepper, black",
              ],
              directions: "Cube avocado. Mix all ingredients and serve.",
              nutrition: {
                calories: 344,
                fat: "74%",
                protein: "18%",
                carbs: "8%",
              },
            },
          ],
          snack: [
            {
              name: "Simple Spinach Salad",
              description:
                "1 serving of simple spinach salad contains 161 Calories. The macronutrient breakdown is 20% carbs, 72% fat, and 8% protein. This is a good source of potassium (14% of your Daily Value), magnesium (20% of your Daily Value), and vitamin A (50% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "90g spinach, raw",
                "50g scallions, spring onions or scallions (includes tops and bulb), raw",
                "24g lemon juice, raw (½ lemon yields)",
                "14g olive oil, salad or cooking",
                "0.1g black pepper, spices",
              ],
              directions: [
                "Wash spinach well, drain, and chop.",
                "Squeeze out excess water.",
                "Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions/green onions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and serve.",
              ],
              nutrition: {
                calories: 161,
                fat: "72%",
                protein: "8%",
                carbs: "20%",
              },
            },
          ],
          dinner: [
            {
              name: "Basic Chicken Salad",
              description:
                "1 serving of basic chicken salad contains 294 Calories. The macronutrient breakdown is 9% carbs, 51% fat, and 41% protein. This is a good source of protein (53% of your Daily Value), potassium (11% of your Daily Value), and vitamin B6 (76% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "30g light mayonnaise, salad dressing, light",
                "0.1g black pepper, spices",
                "14g raw almonds, nuts",
                "10g celery, raw (¼ stalk, medium)",
                "4g lemon juice, raw",
                "118g chicken breast, broilers or fryers, meat only, raw (1 breast fillet)",
              ],
              directions: [
                "Cook chicken breasts to preference, either pan-fried (8 minutes a side on medium-high heat) or baked (400°F for 20 minutes) until there's no pink in the middle.",
                "Chop chicken breast into pieces.",
                "Optionally roast almonds in a frying pan.",
                "Chop celery.",
                "In a medium bowl, mix together mayonnaise, lemon juice, and pepper.",
                "Toss with chicken, almonds, and celery.",
              ],
              nutrition: {
                calories: 294,
                fat: "51%",
                protein: "41%",
                carbs: "9%",
              },
            },
            {
              name: "Balsamic Asparagus",
              description:
                "1 serving of balsamic asparagus contains 58 Calories. The macronutrient breakdown is 34% carbs, 50% fat, and 17% protein. This is a good source of vitamin K (43% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "6 large spears of raw asparagus (120g)",
                "3g olive oil, salad or cooking",
                "0.07g black pepper, spices",
                "4g balsamic vinegar",
              ],
              directions: [
                "Prepare asparagus by washing and snapping off the tough end.",
                "Heat oil in a frying pan.",
                "Add asparagus and keep moving it around in the pan until it changes color (approximately 3-5 minutes).",
                "Add balsamic vinegar and sprinkle pepper over all of the asparagus.",
                "Remove from heat and cover for a few minutes to let the flavors develop. Serve.",
              ],
              nutrition: {
                calories: 58,
                fat: "50%",
                protein: "17%",
                carbs: "34%",
              },
            },
          ],
        },
      ],
      1700: [
        {
          kalories: "1700",
          cardbs: "38",
          fat: "113",
          protein: "64",
          breakfast: [
            {
              name: "Avocado & Smoked Salmon Omelet",
              description:
                "1 serving of avocado & smoked salmon omelet contains 276 Calories. The macronutrient breakdown is 6% carbs, 67% fat, and 27% protein. This is a good source of protein (33% of your Daily Value), vitamin D (46% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "7 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "2 large whole eggs (100g)",
                "5g reduced fat milk, 2% milkfat",
                "0.4g salt, table",
                "5g olive oil, salad or cooking",
                "¼ California avocado, without skin and seed (34g)",
                "28g smoked Chinook salmon",
                "3g fresh basil",
              ],
              directions: [
                "Beat eggs with milk and salt in a small bowl.",
                "Heat 1 teaspoon of oil in a small nonstick skillet over medium heat. Add the egg mixture and cook until the bottom is set and the center is still a bit runny, about 1 to 2 minutes.",
                "Flip the omelet over and cook until set, about 30 seconds more.",
                "Transfer to a plate. Top with avocado, salmon, and basil.",
                "Drizzle with the remaining 1/2 teaspoon of oil.",
              ],
              nutrition: {
                calories: 276,
                fat: "67%",
                protein: "27%",
                carbs: "6%",
              },
            },
            {
              name: "Keto Protein Shake (Just the Whey)",
              description:
                "1 shake of keto protein shake (Just the whey) contains 120 Calories. The macronutrient breakdown is 10% carbs, 8% fat, and 82% protein. This is a good source of protein (43% of your Daily Value).",
              makes: "1 shake",
              prepTime: "2 minutes",
              ingredients: [
                "237g plain, clean water",
                "30g whey protein powder, Gold Standard, any flavor, 100% whey protein",
              ],
              directions: [
                "Stir the whey into the water and drink.",
                "Ideally use flavored whey, but if you only have unflavored, add in a tiny bit of sweetener and cocoa powder to make it more palatable (both have a negligible amount of carbs).",
              ],
              nutrition: {
                calories: 120,
                fat: "8%",
                protein: "82%",
                carbs: "10%",
              },
            },
          ],
          lunch: [
            {
              name: "Deli Roast Beef, Red Bell Pepper and Provolone Lettuce Wrap",
              description:
                "1 serving of deli roast beef, red bell pepper and provolone lettuce wrap contains 231 Calories. The macronutrient breakdown is 9% carbs, 63% fat, and 28% protein. This is a good source of protein (29% of your Daily Value), calcium (45% of your Daily Value), and vitamin A (34% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "3g roast beef, deli style, prepackaged, sliced (1 slice)",
                "57g provolone cheese",
                "30g red bell pepper, sweet, raw (¼ medium)",
                "15g Dijon mustard (Grey Poupon)",
                "28g romaine or cos lettuce, raw (1 outer leaf)",
              ],
              directions: [
                "Slice red bell pepper into 1-inch wide 'sticks.'",
                "Lay out 1 large outer lettuce leaf.",
                "Fold beef in half and lay on the lettuce leaf.",
                "Place provolone slice on top of the beef.",
                "Spread mustard all over the surface of the cheese.",
                "Place 2 red bell pepper 'sticks' in the center.",
                "Fold up the lettuce leaf to contain all the contents.",
              ],
              nutrition: {
                calories: 231,
                fat: "63%",
                protein: "28%",
                carbs: "9%",
              },
            },
            {
              name: "Peppers Cucumber & Avocado Salad",
              description:
                "1 serving of peppers cucumber & avocado salad contains 275 Calories. The macronutrient breakdown is 30% carbs, 64% fat, and 7% protein. This is a good source of fiber (43% of your Daily Value), potassium (26% of your Daily Value), and vitamin A (28% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "36g green leaf lettuce, raw",
                "136g cherry tomatoes, red, ripe, raw (about 8 cherry tomatoes)",
                "26g cucumber, with peel, raw",
                "136g California avocado, raw (1 fruit, without skin and seed)",
                "30g red bell pepper, sweet, raw (about 3 rings)",
                "23g lemon juice, raw",
              ],
              directions: [
                "Chop all ingredients and toss together well.",
                "Drizzle with lemon juice and enjoy!",
              ],
              nutrition: {
                calories: 275,
                fat: "64%",
                protein: "7%",
                carbs: "30%",
              },
            },
          ],
          snack: [
            {
              name: "Spinach & Avocado Soup",
              description:
                "1 serving of spinach & avocado soup contains 256 Calories. The macronutrient breakdown is 14% carbs, 80% fat, and 6% protein. This is a good source of fiber (22% of your Daily Value), potassium (15% of your Daily Value), and vitamin A (32% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "68g California avocado, raw (½ fruit, without skin and seed)",
                "60g spinach, raw",
                "90g low sodium vegetable broth (from bouillons)",
                "15g lemon juice, raw",
                "14g olive oil, salad or cooking",
                "0.05g black pepper, spices",
              ],
              directions: [
                "Remove the pit from the avocado and scoop it out into a blender.",
                "Add spinach, broth, lemon juice, and oil.",
                "Blend until smooth and creamy and serve. Can be optionally heated if desired.",
              ],
              nutrition: {
                calories: 256,
                fat: "80%",
                protein: "6%",
                carbs: "14%",
              },
            },
          ],
          dinner: [
            {
              name: "Seared Sesame-Crusted Tuna",
              description:
                "1 serving of seared sesame-crusted tuna contains 328 Calories. The macronutrient breakdown is 7% carbs, 56% fat, and 37% protein. This is a good source of protein (54% of your Daily Value), calcium (25% of your Daily Value), and iron (69% of your Daily Value).",
              makes: "4 servings",
              prepTime: "7 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "112g Ahi tuna steaks (Trader Joe's, 1 fillet)",
                "18g white sesame seeds (whole, dried)",
                "8g black sesame seeds (Yupik)",
                "0.1g table salt (¼ dash)",
                "0.03g black pepper (¼ dash)",
                "7g canola oil",
              ],
              directions: [
                "In a shallow dish, combine white sesame seeds and black sesame seeds and stir to mix.",
                "Season the tuna with salt and pepper, and dredge each fillet in the sesame seeds, ensuring each side is evenly coated.",
                "In a non-stick pan, heat the oil until smoking.",
                "Arrange the tuna in the pan without overcrowding. Cook for about one minute until the seeds start to turn golden underneath.",
                "Carefully turn the tuna over and cook for another minute.",
                "Transfer the tuna to a cutting board and cut into 1/4 inch thick slices. Serve immediately.",
              ],
              nutrition: {
                calories: 328,
                fat: "56%",
                protein: "37%",
                carbs: "7%",
              },
            },
            {
              name: "Raw Cauliflower Tabouli",
              description:
                "1 serving of raw cauliflower tabouli contains 219 Calories. The macronutrient breakdown is 22% carbs, 72% fat, and 6% protein. This is a good source of fiber (14% of your Daily Value), potassium (14% of your Daily Value), and vitamin A (14% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "74g raw cauliflower (⅛ head medium)",
                "62g red ripe tomatoes (½ medium whole)",
                "19g raw parsley",
                "2g fresh mint",
                "30g raw scallions (2 medium)",
                "75g raw cucumber (¼ cucumber with peel)",
                "0.03g black pepper (¼ dash)",
                "0.8g table salt",
                "11g raw lemon juice",
                "18g olive oil (salad or cooking)",
              ],
              directions: [
                "Separate cauliflower into florets. Dice tomatoes. Chop parsley, mint, and green onions. Finely dice cucumber.",
                "Place the raw cauliflower florets into your food processor and pulse a few times until the consistency of couscous is achieved.",
                "Transfer mixture to a large mixing bowl and combine well with the other ingredients. Adjust seasoning as necessary.",
              ],
              nutrition: {
                calories: 219,
                fat: "72%",
                protein: "6%",
                carbs: "22%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "38",
          fat: "113",
          protein: "64",
          breakfast: [
            {
              name: "Avocado & Kale Omelet",
              description:
                "1 serving of avocado & kale omelet contains 336 Calories. The macronutrient breakdown is 14% carbs, 67% fat, and 19% protein. This is a good source of protein (30% of your Daily Value), fiber (18% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "100g whole fresh eggs (2 large)",
                "5g reduced fat milk (2% milkfat, with added vitamin A and vitamin D)",
                "0.03g table salt (¹⁄₁₆ dash)",
                "9g olive oil (salad or cooking)",
                "67g raw kale",
                "15g raw lime juice",
                "1g fresh cilantro (coriander leaves, raw)",
                "3g dry roasted sunflower seed kernels (without salt)",
                "0.02g crushed red pepper flakes",
                "0.4g table salt (1 dash)",
                "34g California avocado (¼ fruit, without skin and seed)",
              ],
              directions: [
                "Beat eggs with milk and salt in a bowl.",
                "Heat 1 teaspoon of oil in a small nonstick skillet over medium heat.",
                "Add the egg mixture and cook until the bottom is set and the center is still a bit runny, about 1 to 2 minutes.",
                "Flip the omelet over and cook until set, about 30 seconds more. Transfer to a plate.",
                "Toss kale with the remaining 1 teaspoon of oil, lime juice, cilantro, sunflower seeds, crushed red pepper, and a pinch of salt.",
                "Top the omelet with the kale salad and avocado.",
              ],
              nutrition: {
                calories: 336,
                fat: "67%",
                protein: "19%",
                carbs: "14%",
              },
            },
          ],
          lunch: [
            {
              name: "Simple Lemon Pepper Tuna",
              description:
                "1 serving of simple lemon pepper tuna contains 148 Calories. The macronutrient breakdown is 5% carbs, 10% fat, and 86% protein. This is a good source of protein (58% of your Daily Value), potassium (7% of your Daily Value), and vitamin B6 (40% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "165g light tuna, canned in water, drained solids (1 can)",
                "1g black pepper",
                "15g raw lemon juice",
              ],
              directions: [
                "Combine all of the ingredients and then serve as desired.",
              ],
              nutrition: {
                calories: 148,
                fat: "10%",
                protein: "86%",
                carbs: "5%",
              },
            },
            {
              name: "Lemon Avocado Salad",
              description:
                "1 serving of lemon avocado salad contains 334 Calories. The macronutrient breakdown is 22% carbs, 73% fat, and 5% protein. This is a good source of fiber (51% of your Daily Value), potassium (24% of your Daily Value), and vitamin B6 (44% of your Daily Value).",
              makes: "5 servings",
              prepTime: "15 minutes",
              ingredients: [
                "6g raw onion (¹⁄₁₆ medium)",
                "49g red ripe tomato (⅜ medium whole)",
                "201g avocado (1 fruit)",
                "6g canned or bottled lemon juice",
              ],
              directions: [
                "Chop onion, tomato, and avocado.",
                "Toss together gently.",
                "Pour lemon juice over top and mix gently once more.",
                "Serve and enjoy!",
              ],
              nutrition: {
                calories: 334,
                fat: "73%",
                protein: "5%",
                carbs: "22%",
              },
            },
          ],
          snack: [
            {
              name: "Avocado Lettuce Wrap",
              description:
                "2 wraps of avocado lettuce wrap contains 194 Calories. The macronutrient breakdown is 29% carbs, 64% fat, and 7% protein. This is a good source of fiber (33% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (33% of your Daily Value).",
              makes: "8 wraps",
              prepTime: "10 minutes",
              ingredients: [
                "92g red ripe tomato (¾ medium whole)",
                "2g jalapeno pepper (⅛ pepper)",
                "5g raw onion",
                "2g raw garlic (¾ clove)",
                "1g fresh cilantro (coriander leaves)",
                "56g romaine or cos lettuce (2 outer leaves)",
                "101g avocado (½ fruit)",
                "3g raw lime juice",
              ],
              directions: [
                "Mince garlic, dice tomatoes and jalapeno, chop cilantro.",
                "In a medium-sized bowl, mash the avocados.",
                "Stir in all the ingredients other than the lettuce.",
                "Spread 2 to 3 tablespoons of this mixture onto lettuce leaves and wrap.",
              ],
              nutrition: {
                calories: 194,
                fat: "64%",
                protein: "7%",
                carbs: "29%",
              },
            },
          ],
          dinner: [
            {
              name: "Poached Salmon",
              description:
                "1 serving of poached salmon contains 277 Calories. The macronutrient breakdown is 4% carbs, 40% fat, and 56% protein. This is a good source of protein (61% of your Daily Value), potassium (19% of your Daily Value), and total omega 3 (3 g).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "0.1g table salt (¼ dash)",
                "0.1g fresh dill (¾ sprig)",
                "0.2g raw parsley",
                "30g plain clean water",
                "37g white wine",
                "8g raw shallots",
                "0.03g black pepper (¼ dash)",
                "170g wild Atlantic salmon (raw)",
              ],
              directions: [
                "Sprinkle the salmon fillets with a little salt.",
                "Put the wine, water, dill, parsley, and shallots in a sauté pan, and bring to a simmer on medium heat.",
                "Place salmon fillets skin-side down in the pan. Cover.",
                "Cook for 5 to 10 minutes, depending on the thickness of the fillet, or to desired doneness. Do not overcook.",
                "Serve sprinkled with freshly ground black pepper.",
              ],
              nutrition: {
                calories: 277,
                fat: "40%",
                protein: "56%",
                carbs: "4%",
              },
            },
            {
              name: "Spinach Salad with Blackberries",
              description:
                "1 serving of spinach salad with blackberries contains 305 Calories. The macronutrient breakdown is 18% carbs, 77% fat, and 5% protein. This is a good source of fiber (23% of your Daily Value), potassium (13% of your Daily Value), and vitamin A (35% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "60g raw spinach",
                "50g raw scallions (2 large)",
                "27g olive oil (salad or cooking)",
                "0.2g black pepper (2 dashes)",
                "24g lemon juice (juice from ½ lemon)",
                "72g raw blackberries",
              ],
              directions: [
                "Wash spinach well, drain, and chop. Squeeze out excess water.",
                "Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions/green onions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and top with blackberries. Enjoy!",
              ],
              nutrition: {
                calories: 305,
                fat: "77%",
                protein: "5%",
                carbs: "18%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "38",
          fat: "113",
          protein: "64",
          breakfast: [
            {
              name: "Chocolate Coconut Protein Shake",
              description:
                "1 shake of chocolate coconut protein shake contains 408 Calories. The macronutrient breakdown is 12% carbs, 63% fat, and 26% protein. This is a good source of protein (50% of your Daily Value), fiber (17% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 shake",
              prepTime: "5 minutes",
              ingredients: [
                "5g unsweetened cocoa powder",
                "30g whey protein powder (Gold Standard, any flavor)",
                "120g coconut milk",
              ],
              directions: [
                "Combine all ingredients together in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 408,
                fat: "63%",
                protein: "26%",
                carbs: "12%",
              },
            },
          ],
          lunch: [
            {
              name: "Keto Salmon-Filled Avocados",
              description:
                "1 serving of keto salmon-filled avocados contains 278 Calories. The macronutrient breakdown is 20% carbs, 57% fat, and 23% protein. This is a good source of protein (30% of your Daily Value), fiber (31% of your Daily Value), and vitamin D (48% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "152g Florida avocados, halved and pitted",
                "43g smoked Chinook salmon",
                "57g lowfat cottage cheese (2% milkfat)",
                "½ dash salt",
                "½ dash black pepper",
              ],
              directions: [
                "Cut avocados in half and remove the pit.",
                "Place cottage cheese in each hollow of the avocado and add smoked salmon on top.",
                "Season to taste with salt and pepper.",
                "Serve immediately.",
              ],
              nutrition: {
                calories: 278,
                fat: "57%",
                protein: "23%",
                carbs: "20%",
              },
            },
          ],
          snack: [
            {
              name: "Tuna Mex Tuna Salad",
              description:
                "1 serving of tuna mex tuna salad contains 199 Calories. The macronutrient breakdown is 13% carbs, 42% fat, and 45% protein. This is a good source of protein (42% of your Daily Value), potassium (12% of your Daily Value), and vitamin B6 (42% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "113g light tuna, canned in water, drained",
                "6g sour cream (cultured)",
                "32g ready-to-serve salsa",
                "50g raw avocado (¼ fruit)",
              ],
              directions: [
                "Drain tuna and place it into a bowl.",
                "Remove the pit from the avocado and scoop the fruit into the bowl with tuna.",
                "Add salsa and sour cream to the bowl.",
                "Mix everything together well and serve.",
              ],
              nutrition: {
                calories: 199,
                fat: "42%",
                protein: "45%",
                carbs: "13%",
              },
            },
          ],
          dinner: [
            {
              name: "Seared Steak",
              description:
                "2 servings of seared steak contains 492 Calories. The macronutrient breakdown is 0.1% carbs, 61% fat, and 39% protein. This is a good source of protein (84% of your Daily Value), vitamin B6 (118% of your Daily Value), and vitamin B12 (173% of your Daily Value).",
              makes: "4 servings",
              prepTime: "2 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "3g table salt",
                "0.3g black pepper",
                "2g olive oil (salad or cooking)",
                "227g porterhouse steak, raw",
              ],
              directions: [
                "Remove the steak from the refrigerator and let it come to room temperature, about 30 to 45 minutes.",
                "Season the steak on both sides with salt and pepper. Rub both sides with olive oil and set aside.",
                "Heat a medium heavy-bottomed frying pan (not nonstick!) over high heat until very hot but not smoking, about 3 to 4 minutes. If the pan gets too hot and starts to smoke, take it off the heat to cool a bit.",
                "Place the steak in the pan and let it cook undisturbed until a dark crust forms on the bottom, about 3 to 4 minutes.",
                "Flip the steak using tongs or a spatula and cook until it's medium rare, about 3 to 4 minutes more. To check for doneness, use your finger to press on the steak: it should be firm around the edges but still give in the center. You can also use an instant-read thermometer; it should read about 140-145 degrees F.",
                "Transfer the steak to a cutting board and let it rest for at least 5 minutes before serving.",
              ],
              nutrition: {
                calories: 492,
                fat: "61%",
                protein: "39%",
                carbs: "0.1%",
              },
            },
            {
              name: "Balsamic Sautéed Spinach",
              description:
                "1 serving of balsamic sautéed spinach contains 162 Calories. The macronutrient breakdown is 19% carbs, 76% fat, and 5% protein. This is a good source of potassium (8% of your Daily Value), vitamin A (31% of your Daily Value), and vitamin E (21% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "14g olive oil (salad or cooking)",
                "60g raw spinach",
                "32g balsamic vinegar",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
              ],
              directions: [
                "Heat olive oil in a pan over medium-high heat.",
                "Cook spinach in olive oil and add balsamic vinegar, salt, and pepper.",
                "Let spinach heat through and cook until slightly wilted. Remove from heat and enjoy!",
              ],
              nutrition: {
                calories: 162,
                fat: "76%",
                protein: "5%",
                carbs: "19%",
              },
            },
          ],
        },
      ],
      2200: [
        {
          kalories: "2200",
          cardbs: "50",
          fat: "147",
          protein: "83",
          breakfast: [
            {
              name: "Sour Cream Scrambled Eggs",
              description:
                "1 serving of sour cream scrambled eggs contains 327 Calories. The macronutrient breakdown is 9% carbs, 72% fat, and 19% protein. This is a good source of protein (28% of your Daily Value), potassium (11% of your Daily Value), and vitamin A (28% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "15g reduced fat sour cream",
                "100g whole, fresh eggs (2 large)",
                "0.4g table salt (1 dash)",
                "9g unsalted butter",
                "3g raw chives",
                "0.1g black pepper (1 dash)",
                "35g white mushrooms, raw",
                "50g raw avocado (¼ fruit)",
              ],
              directions: [
                "Slice avocado and mushrooms, chop chives.",
                "Sauté mushrooms over medium heat with half of the butter. Set aside.",
                "Mix together the sour cream and eggs in a small bowl. Sprinkle with salt and pepper and whisk until combined.",
                "Melt the rest of the butter in a nonstick pan over medium-low heat. Add the eggs and gently scramble.",
                "Remove from the heat, sprinkle with the chives, and serve with sliced avocado and fried mushrooms.",
              ],
              nutrition: {
                calories: 327,
                fat: "72%",
                protein: "19%",
                carbs: "9%",
              },
            },
            {
              name: "Guacamole on Tostada",
              description:
                "1 serving of guacamole on tostada contains 192 Calories. The macronutrient breakdown is 36% carbs, 59% fat, and 6% protein. This is a good source of fiber (23% of your Daily Value), potassium (12% of your Daily Value), and vitamin B6 (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "68g California avocado (½ fruit, without skin and seed)",
                "62g Roma tomato (1 tomato)",
                "15g raw lemon juice",
                "14g raw onion",
                "1g fresh cilantro (coriander leaves)",
                "12g corn tostada shell (1 piece)",
              ],
              directions: [
                "Mash the avocado and mix with the tomatoes, lemon juice, onion, and cilantro.",
                "Smear the mixture on the tostada. Enjoy!",
              ],
              nutrition: {
                calories: 192,
                fat: "59%",
                protein: "6%",
                carbs: "36%",
              },
            },
          ],
          lunch: [
            {
              name: "Chicken and Avocado Salad",
              description:
                "1 serving of chicken and avocado salad contains 404 Calories. The macronutrient breakdown is 12% carbs, 55% fat, and 34% protein. This is a good source of protein (62% of your Daily Value), fiber (27% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "125g canned chicken (no broth)",
                "101g California avocado (½ fruit)",
                "7g fresh cilantro (3 sprigs)",
                "9g raw lime juice (from ⅓ lime)",
                "0.4g salt (to taste)",
                "60g butterhead lettuce (4 large leaves)",
              ],
              directions: [
                "Chop the cilantro and cut up the avocado.",
                "Combine the chicken, avocado, cilantro, lime juice, and salt in a bowl.",
                "Arrange the butterhead lettuce leaves on a plate and serve the chicken salad on top.",
              ],
              nutrition: {
                calories: 404,
                fat: "55%",
                protein: "34%",
                carbs: "12%",
              },
            },
            {
              name: "Avocado Lettuce Wrap",
              description:
                "2 wraps of avocado lettuce wrap contains 194 Calories. The macronutrient breakdown is 29% carbs, 64% fat, and 7% protein. This is a good source of fiber (33% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (33% of your Daily Value).",
              makes: "8 wraps",
              prepTime: "10 minutes",
              ingredients: [
                "92g tomatoes (¾ medium, red, ripe, raw)",
                "2g jalapeno peppers (⅛ pepper, raw)",
                "5g onions (raw)",
                "2g garlic (¾ clove, raw)",
                "1g fresh cilantro (coriander leaves, raw)",
                "56g lettuce (romaine or cos, raw, 2 outer leaves)",
                "101g avocado (½ fruit, raw, all commercial varieties)",
                "3g lime juice (raw)",
              ],
              directions: [
                "Mince garlic, dice tomatoes and jalapeno, and chop cilantro.",
                "In a medium-sized bowl, mash the avocados. Stir in all the ingredients except the lettuce.",
                "Spread 2 to 3 tablespoons of the avocado mixture onto lettuce leaves and wrap.",
              ],
              nutrition: {
                calories: 194,
                fat: "64%",
                protein: "7%",
                carbs: "29%",
              },
            },
          ],
          snack: [
            {
              name: "Chopped Radish and Avocado Salad",
              description:
                "1 serving of chopped radish and avocado salad contains 272 Calories. The macronutrient breakdown is 20% carbs, 76% fat, and 4% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin B6 (27% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "68g avocado (½ fruit, California, raw)",
                "11g radishes (2 ½ medium, raw)",
                "18g onions (¼ small, raw)",
                "140g cucumber (½ large, peeled, raw)",
                "24g lemon juice (½ lemon, raw)",
                "14g olive oil (salad or cooking)",
                "2g garlic (½ clove, minced, raw)",
                "5g shallots (raw)",
                "0.2g salt (½ dash, table)",
                "0.05g pepper (½ dash, black)",
                "8g parsley (raw)",
              ],
              directions: [
                "Chop the avocado, radishes, onion, and cucumber. Set aside.",
                "Make the dressing by combining the lemon juice, olive oil, minced garlic, minced shallots, salt, and pepper in a large bowl. Mix thoroughly.",
                "Add the chopped avocado, radish, onion, and cucumber to the bowl. Toss with the dressing until everything is evenly coated. Add more salt and pepper if desired, garnish with parsley, and then serve. Enjoy!",
              ],
              nutrition: {
                calories: 272,
                fat: "76%",
                protein: "4%",
                carbs: "20%",
              },
            },
          ],
          dinner: [
            {
              name: "Keto Cheesy Burger Stuffed Portobellos",
              description:
                "1 serving of keto cheesy burger stuffed portobellos contains 744 Calories. The macronutrient breakdown is 3% carbs, 77% fat, and 20% protein. This is a good source of protein (67% of your Daily Value), potassium (16% of your Daily Value), and calcium (48% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "84g portabella mushrooms (1 whole mushroom, raw)",
                "142g ground beef (70% lean meat / 30% fat, raw)",
                "57g cheddar cheese",
                "15g spinach (raw)",
                "5g grated Parmesan cheese",
                "0.2g salt (½ dash, table)",
                "0.05g pepper (½ dash, black)",
              ],
              directions: [
                "Preheat the oven to 375°F (190°C).",
                "Remove the stems from the portobellos. Chop the stems finely and add them to the ground beef. With a spoon, scrape out the gills on the underside of the mushrooms and discard.",
                "Finely chop the spinach and grate the cheese. Set aside.",
                "Season the mushrooms with a sprinkling of salt and pepper. Mix the cheddar cheese and spinach into the ground beef. Season with a dash of salt and pepper. Form two patties and press them onto the portobello mushrooms.",
                "Place the stuffed mushrooms on a small sheet pan or baking dish and cook for 20 minutes or until cooked through. Add the Parmesan cheese to the top of each and pop back into the oven to melt the cheese or under the broiler to brown. Serve hot and enjoy!",
              ],
              nutrition: {
                calories: 744,
                fat: "77%",
                protein: "20%",
                carbs: "3%",
              },
            },
            {
              name: "Balsamic Arugula Salad",
              description:
                "1 serving of balsamic arugula salad contains 61 Calories. The macronutrient breakdown is 25% carbs, 68% fat, and 7% protein. This is a good source of vitamin K (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "62g tomatoes (½ medium whole, red, ripe, raw)",
                "5g balsamic vinegar",
                "20g arugula (raw)",
                "4g olive oil (salad or cooking)",
              ],
              directions: [
                "Cube the tomatoes and chop the arugula roughly.",
                "Combine all ingredients and toss to coat.",
              ],
              nutrition: {
                calories: 61,
                fat: "68%",
                protein: "7%",
                carbs: "25%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "50",
          fat: "147",
          protein: "83",
          breakfast: [
            {
              name: "Fried Eggs with Bacon and Radish",
              description:
                "1 serving of fried eggs with bacon and radish contains 442 Calories. The macronutrient breakdown is 7% carbs, 69% fat, and 24% protein. This is a good source of protein (47% of your Daily Value), potassium (15% of your Daily Value), and vitamin A (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "48g bacon (raw, cured pork, 4 strips)",
                "150g eggs (whole, fresh, 3 large)",
                "174g radishes (raw)",
              ],
              directions: [
                "Place bacon strips in a cold non-stick frying pan and turn the heat onto medium. Cook until bacon has reached desired doneness. Remove from pan.",
                "Add eggs to the pan and cook in the bacon fat to desired doneness (feel free to scramble eggs if desired).",
                "Wash and clean radishes. Slice and serve alongside the bacon and eggs. Enjoy!",
              ],
              nutrition: {
                calories: 442,
                fat: "69%",
                protein: "24%",
                carbs: "7%",
              },
            },
            {
              name: "Turkish Style Zucchini Fritters",
              description:
                "1 serving of Turkish style zucchini fritters contains 129 Calories. The macronutrient breakdown is 19% carbs, 67% fat, and 14% protein. This is a good source of vitamin A (25% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "28g onions (raw, ¼ medium)",
                "31g zucchini (summer squash, includes skin, raw)",
                "0.1g salt (table, ¼ dash)",
                "0.03g pepper (black, ¼ dash)",
                "28g egg (whole, fresh, ½ extra large)",
                "0.8g dill weed (dried)",
                "21g carrots (raw)",
                "7g vegetable oil (Natreon canola, high stability, non-trans, high oleic)",
              ],
              directions: [
                "Chop the onion and shred the zucchini, and then mix everything up in a bowl.",
                "Heat a pan on medium heat, and lightly spray with a cooking spray or small amount of oil.",
                "Spoon/pour the mixture into the pan. Cook for about 5 minutes, then flip. Cook another 5 minutes. Enjoy!",
              ],
              nutrition: {
                calories: 129,
                fat: "67%",
                protein: "14%",
                carbs: "19%",
              },
            },
          ],
          lunch: [
            {
              name: "LCHF Salmon Rolls with Horseradish Cream",
              description:
                "1 serving of LCHF salmon rolls with horseradish cream contains 470 Calories. The macronutrient breakdown is 15% carbs, 65% fat, and 20% protein. This is a good source of protein (43% of your Daily Value), fiber (30% of your Daily Value), and vitamin D (115% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "99g chinook salmon (smoked)",
                "101g avocados (raw, ½ fruit)",
                "151g cucumber (with peel, raw, ½ cucumber)",
                "44g cream cheese",
                "15g horseradish (prepared)",
                "3g sesame seeds (whole, roasted and toasted)",
              ],
              directions: [
                "Place the slices of smoked salmon on a board (or a sushi mat). Let the slices cover about half of the previous slice, creating a length of salmon which will be the outside of your roll.",
                "Cut the avocado and cucumber into thin slices.",
                "In a small bowl, combine cream cheese and horseradish and mix well together. Spread cream cheese on salmon slices and place avocado and cucumber on top.",
                "Carefully roll the salmon so it closes in the bottom. Cut into sushi slices with a sharp knife and sprinkle sesame seeds on top. Enjoy!",
              ],
              nutrition: {
                calories: 470,
                fat: "65%",
                protein: "20%",
                carbs: "15%",
              },
            },
            {
              name: "Simple Spinach Salad",
              description:
                "1 serving of simple spinach salad contains 161 Calories. The macronutrient breakdown is 20% carbs, 72% fat, and 8% protein. This is a good source of potassium (14% of your Daily Value), magnesium (20% of your Daily Value), and vitamin A (50% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "90g raw spinach",
                "50g scallions (spring onions or scallions, raw, 2 large)",
                "24g lemon juice (from ½ lemon)",
                "14g olive oil (salad or cooking)",
                "1 dash black pepper",
              ],
              directions: [
                "Wash spinach well, drain, and chop. Squeeze out excess water. Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions/green onions, olive oil, black pepper, and the juice from 1 squeezed lemon. Toss and serve.",
              ],
              nutrition: {
                calories: 161,
                fat: "72%",
                protein: "8%",
                carbs: "20%",
              },
            },
          ],
          snack: [
            {
              name: "Avocado",
              description:
                "1 avocado contains 322 Calories. The macronutrient breakdown is 20% carbs, 76% fat, and 5% protein. This is a good source of fiber (48% of your Daily Value), potassium (21% of your Daily Value), and vitamin B6 (40% of your Daily Value).",
              makes: "1 avocado",
              prepTime: "1 minute",
              ingredients: [
                "1 fruit (201g) raw avocado (all commercial varieties)",
              ],
              directions: [
                "Cut the avocado in half and remove the pit.",
                "Optional topping ideas: Sea salt, black pepper, balsamic vinegar, lemon/lime juice, or paprika.",
              ],
              nutrition: {
                calories: 322,
                fat: "76%",
                protein: "5%",
                carbs: "20%",
              },
            },
          ],
          dinner: [
            {
              name: "Peppered Steaks with Mushrooms",
              description:
                "1 serving contains 326 Calories. The macronutrient breakdown is 3% carbs, 67% fat, and 29% protein. This is a good source of protein (43% of your Daily Value), potassium (13% of your Daily Value), and vitamin B6 (54% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "15 minutes",
              ingredients: [
                '113g beef tenderloin, separable lean and fat, trimmed to 1/8" fat, all grades, raw',
                "0.5g black pepper",
                "3g olive oil (salad or cooking)",
                "15g beef broth or bouillon (canned soup, ready-to-serve)",
                "65g portabella mushrooms, raw",
              ],
              directions: [
                "Trim fat from steaks. Rub both sides of the steaks with pepper.",
                "In a large skillet, heat olive oil over medium-high heat.",
                "Add steaks; reduce heat to medium. Cook to desired temperature, 7 to 9 minutes for medium rare (145°F) to medium (160°F), turning once halfway through cooking time.",
                "Transfer steaks to a serving platter; keep warm.",
                "Add beef broth to skillet. Cook and stir until bubbly to loosen any browned bits in the bottom of the skillet.",
                "Add mushrooms; simmer, uncovered for 4 minutes. Spoon sauce over steaks to serve.",
              ],
              nutrition: {
                calories: 326,
                fat: "67%",
                protein: "29%",
                carbs: "3%",
              },
            },
            {
              name: "Avocado Tomato Salad",
              description:
                "1 bowl contains 344 Calories. The macronutrient breakdown is 24% carbs, 71% fat, and 5% protein. This is a good source of fiber (54% of your Daily Value), potassium (25% of your Daily Value), and vitamin B6 (47% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "5 minutes",
              ingredients: [
                "201g avocado, raw, all commercial varieties",
                "0.1g black pepper",
                "0.4g table salt",
                "29g lemon, raw, without peel (½ small fruit)",
                "75g cherry tomatoes, red, ripe, raw, year-round average",
              ],
              directions: [
                "Dice avocado. Squeeze half of the lemon over the top.",
                "Add ground salt and pepper.",
                "Dice tomatoes into halves and add.",
              ],
              nutrition: {
                calories: 344,
                fat: "71%",
                protein: "5%",
                carbs: "24%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "50",
          fat: "147",
          protein: "83",
          breakfast: [
            {
              name: "Eggs Baked in Avocado",
              description:
                "1 serving contains 476 Calories. The macronutrient breakdown is 17% carbs, 70% fat, and 13% protein. This is a good source of protein (30% of your Daily Value), fiber (52% of your Daily Value), and potassium (25% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "12 minutes",
              ingredients: [
                "201g avocado, raw, all commercial varieties",
                "100g whole, fresh eggs (2 large eggs)",
                "34g lime, raw (½ fruit)",
                "0.1g table salt (¼ dash)",
                "0.03g black pepper (¼ dash)",
                "0.3g fresh cilantro, coriander leaves, raw",
                "4g scallions, raw (¼ medium)",
              ],
              directions: [
                "Adjust oven rack to middle position and preheat to 450°F.",
                "Cut each avocado in half and remove the pit.",
                "Using a spoon, scrape out the center of each halved avocado to fit an egg (about 1 1/2 tablespoons).",
                "Squeeze lime juice over the avocados and season with salt. Place on a baking sheet.",
                "Break an egg into the center of each avocado. Ensure yolk remains intact.",
                "Bake in the oven until whites are set and yolk is runny, about 10 to 12 minutes.",
                "Remove from oven, garnish with cilantro and scallions, and serve.",
              ],
              nutrition: {
                calories: 476,
                fat: "70%",
                protein: "13%",
                carbs: "17%",
              },
            },
            {
              name: "Cantaloupe",
              description:
                "2 slices contain 47 Calories. The macronutrient breakdown is 87% carbs, 5% fat, and 9% protein. This is a good source of potassium (8% of your Daily Value), vitamin A (26% of your Daily Value), and vitamin C (56% of your Daily Value).",
              makes: "8 slices",
              prepTime: "5 minutes",
              ingredients: ["138g cantaloupe, raw (2 wedges, medium)"],
              directions: [
                "Cut cantaloupe into cubes or wedge slices.",
                "1 cup of cubes is about 2 wedges of a medium melon (each wedge = 1/8 of a melon).",
              ],
              nutrition: {
                calories: 47,
                fat: "5%",
                protein: "9%",
                carbs: "87%",
              },
            },
          ],
          lunch: [
            {
              name: "Chicken and Avocado Salad",
              description:
                "1 serving contains 404 Calories. The macronutrient breakdown is 12% carbs, 55% fat, and 34% protein. This is a good source of protein (62% of your Daily Value), fiber (27% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "125g canned chicken, no broth",
                "½ avocado, raw (101g)",
                "3 sprigs fresh cilantro (7g)",
                "9g lime juice (approx. ³⁄₁₆ lime)",
                "1 dash salt (0.4g)",
                "4 large leaves of butterhead lettuce (60g)",
              ],
              directions: [
                "Chop the cilantro and cut up the avocado.",
                "Combine the chicken, avocado, cilantro, lime juice, and salt (use only a squeeze of lime juice and salt to taste).",
                "Arrange the butterhead lettuce leaves, and serve the chicken salad on top.",
              ],
              nutrition: {
                calories: 404,
                fat: "55%",
                protein: "34%",
                carbs: "12%",
              },
            },
            {
              name: "Cauliflower and Tahini",
              description:
                "1 serving contains 205 Calories. The macronutrient breakdown is 21% carbs, 66% fat, and 13% protein. This is a good source of fiber (18% of your Daily Value), potassium (9% of your Daily Value), and calcium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              ingredients: [
                "107g raw cauliflower",
                "30g tahini (sesame butter from roasted and toasted kernels)",
              ],
              directions: [
                "Chop the cauliflower into 1/2-inch pieces, retaining some florets.",
                "Serve cauliflower pieces with tahini for dipping. Enjoy!",
              ],
              nutrition: {
                calories: 205,
                fat: "66%",
                protein: "13%",
                carbs: "21%",
              },
            },
          ],
          snack: [
            {
              name: "Arugula Avocado Salad",
              description:
                "1 serving contains 285 Calories. The macronutrient breakdown is 12% carbs, 82% fat, and 6% protein. This is a good source of fiber (15% of your Daily Value), potassium (11% of your Daily Value), and vitamin E (27% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "14g olive oil",
                "8g lemon juice",
                "0.1g salt (¼ dash)",
                "0.03g black pepper (¼ dash)",
                "45g arugula, raw",
                "34g avocado (¼ fruit), California, raw",
                "31g tomato (¼ medium)",
                "17g hearts of palm, canned (½ piece)",
                "11g pine nuts, dried",
                "3g Parmesan cheese, grated",
              ],
              directions: [
                "In a small bowl, whisk together olive oil and lemon juice to make the dressing. Add salt and pepper to taste. Set aside.",
                "In a large salad bowl, combine arugula, avocado, tomato, and hearts of palm. Toss with dressing.",
                "Top with pine nuts and Parmesan cheese. Serve and enjoy!",
              ],
              nutrition: {
                calories: 285,
                fat: "82%",
                protein: "6%",
                carbs: "12%",
              },
            },
          ],
          dinner: [
            {
              name: "Pan Seared Salmon with Avocado Remoulade",
              description:
                "1 serving contains 590 Calories. The macronutrient breakdown is 7% carbs, 69% fat, and 24% protein. This is a good source of protein (64% of your Daily Value), fiber (25% of your Daily Value), and potassium (29% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "1g Dijon mustard",
                "14g olive oil",
                "3g shallots, raw",
                "11g lime juice",
                "7g vegetable oil",
                "0.1g salt (¼ dash)",
                "0.9g parsley, raw",
                "101g avocado (½ fruit), raw",
                "170g Atlantic salmon, wild, raw",
                "0.03g black pepper (¼ dash)",
              ],
              directions: [
                "Put avocado pieces and lime juice into a food processor or blender and pulse until blended.",
                "Slowly add olive oil, pulsing, until reaching desired consistency of sauce.",
                "Add minced shallots and parsley, pulse just until combined. Remove to a bowl, add mustard, salt, and pepper to taste.",
                "Coat the bottom of a sauté pan with vegetable oil and heat on medium-high until almost smoking.",
                "Season both sides of the salmon fillets with salt and pepper, then lay the salmon in the pan, skin side down.",
                "Cook the salmon until about medium doneness, about 3-4 minutes per side.",
                "Serve salmon with avocado remoulade sauce.",
              ],
              nutrition: {
                calories: 590,
                fat: "69%",
                protein: "24%",
                carbs: "7%",
              },
            },
            {
              name: "Zucchini Spears",
              description:
                "1 serving contains 46 Calories. The macronutrient breakdown is 62% carbs, 14% fat, and 24% protein. This is a good source of potassium (15% of your Daily Value), vitamin B6 (33% of your Daily Value), and vitamin C (54% of your Daily Value).",
              makes: "6 servings",
              prepTime: "3 minutes",
              cookTime: "5 minutes",
              ingredients: ["0.5g salt", "269g zucchini (¹³⁄₁₆ large), raw"],
              directions: [
                "Cut zucchini lengthwise and into 1/4 inch wedges.",
                "Cook zucchini in boiling salted water until tender, about 3-5 minutes.",
                "Drain and sprinkle with salt before serving.",
              ],
              nutrition: {
                calories: 46,
                fat: "14%",
                protein: "24%",
                carbs: "62%",
              },
            },
          ],
        },
      ],
      2700: [
        {
          kalories: "2700",
          cardbs: "61",
          fat: "180",
          protein: "101",
          breakfast: [
            {
              name: "Spinach Scrambled Eggs",
              description:
                "2 servings contain 415 Calories. The macronutrient breakdown is 4% carbs, 67% fat, and 29% protein. This is a good source of protein (53% of your Daily Value), potassium (14% of your Daily Value), and vitamin A (71% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "9g olive oil",
                "60g spinach, raw",
                "4 extra large eggs (224g)",
                "2 dashes salt (0.8g)",
                "4 dashes pepper (0.4g)",
              ],
              directions: [
                "Heat oil in a pan over medium heat. Add spinach and sauté for 3-4 minutes, or until just wilted.",
                "Add scrambled eggs, salt, and pepper, then mix.",
                "Cook to desired firmness. Serve hot and enjoy!",
              ],
              nutrition: {
                calories: 415,
                fat: "67%",
                protein: "29%",
                carbs: "4%",
              },
            },
            {
              name: "Chocolate Coconut Protein Shake",
              description:
                "1 shake contains 408 Calories. The macronutrient breakdown is 12% carbs, 63% fat, and 26% protein. This is a good source of protein (50% of your Daily Value), fiber (17% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 shake",
              prepTime: "5 minutes",
              ingredients: [
                "5g cocoa powder, unsweetened",
                "1 scoop (30g) whey protein powder",
                "120g coconut milk, raw",
              ],
              directions: [
                "Combine all ingredients together in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 408,
                fat: "63%",
                protein: "26%",
                carbs: "12%",
              },
            },
          ],
          lunch: [
            {
              name: "Tuna Mex Tuna Salad",
              description:
                "1 serving contains 199 Calories. The macronutrient breakdown is 13% carbs, 42% fat, and 45% protein. This is a good source of protein (42% of your Daily Value), potassium (12% of your Daily Value), and vitamin B6 (42% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "113g tuna, canned in water, drained",
                "6g sour cream",
                "32g salsa",
                "1/4 avocado (50g)",
              ],
              directions: [
                "Drain tuna and place into a bowl.",
                "Remove pit from avocado and scoop fruit into bowl with tuna.",
                "Add salsa and sour cream to bowl. Mix everything together well and serve.",
              ],
              nutrition: {
                calories: 199,
                fat: "42%",
                protein: "45%",
                carbs: "13%",
              },
            },
            {
              name: "Vanilla Walnut Blueberry Yogurt",
              description:
                "6 oz contains 485 Calories. The macronutrient breakdown is 21% carbs, 62% fat, and 17% protein. This is a good source of protein (38% of your Daily Value).",
              makes: "6 oz",
              prepTime: "5 minutes",
              ingredients: [
                "170g plain whole fat Greek yogurt",
                "7g honey",
                "2g vanilla extract",
                "28g walnuts, dried",
                "74g raw blueberries",
              ],
              directions: ["Mix all ingredients together well.", "Enjoy!"],
              nutrition: {
                calories: 485,
                fat: "62%",
                protein: "17%",
                carbs: "21%",
              },
            },
          ],
          snack: [
            {
              name: "Paleo Avocado Chicken Salad",
              description:
                "1 serving contains 338 Calories. The macronutrient breakdown is 18% carbs, 64% fat, and 18% protein. This is a good source of protein (29% of your Daily Value), fiber (35% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 California avocado, without skin and seed (136g)",
                "24g lemon juice (½ lemon)",
                "¼ medium onion (28g), raw",
                "51g canned chicken, no broth",
                "1 dash salt",
                "1 dash black pepper",
              ],
              directions: [
                "Cut the avocado in half and scoop the middle of both avocado halves into a bowl, leaving a shell of avocado flesh about ¼-inch thick on each half.",
                "Add lemon juice and onion to the avocado in the bowl and mash together.",
                "Add drained chicken, salt, and pepper, and stir to combine. Taste and adjust if needed.",
                "Fill avocado shells with chicken salad and serve.",
              ],
              nutrition: {
                calories: 338,
                fat: "64%",
                protein: "18%",
                carbs: "18%",
              },
            },
          ],
          dinner: [
            {
              name: "Salmon with Capers and Caprese Salad",
              description:
                "1 serving contains 746 Calories. The macronutrient breakdown is 9% carbs, 60% fat, and 31% protein. This is a good source of protein (104% of your Daily Value), fiber (27% of your Daily Value), and potassium (38% of your Daily Value).",
              makes: "2 servings",
              prepTime: "12 minutes",
              cookTime: "6 minutes",
              ingredients: [
                "7g olive oil (salad or cooking)",
                "3g Dijon mustard (Grey Poupon)",
                "12g lemon juice (¼ lemon)",
                "9g capers (canned)",
                "½ California avocado, without skin and seed (68g)",
                "1 medium tomato (123g), red, ripe, raw",
                "56g mozzarella cheese (part skim milk, low moisture)",
                "½ leaf fresh basil (whole)",
                "14g walnuts (English)",
                "198g Atlantic salmon (wild, raw)",
              ],
              directions: [
                "Heat 1 tablespoon of the olive oil in a frying pan over medium-high heat.",
                "Add the salmon fillets and fry for 1–2 minutes on each side, until the fish is lightly colored.",
                "Using a spatula, break the fish into large chunks and fry for a further 2–3 minutes or until just cooked through.",
                "Remove the pan from the heat and transfer the salmon to a plate.",
                "Mix together the mustard, lemon juice, capers, and the remaining olive oil to make a dressing.",
                "Arrange the avocado, tomato, and mozzarella over two plates.",
                "Top with the salmon chunks, scatter with basil leaves and walnuts, and finally spoon over the dressing.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 746,
                fat: "60%",
                protein: "31%",
                carbs: "9%",
              },
            },
            {
              name: "Brussels Sprout Slaw",
              description:
                "1 serving contains 109 Calories. The macronutrient breakdown is 35% carbs, 53% fat, and 13% protein. This is a good source of fiber (15% of your Daily Value), potassium (9% of your Daily Value), and vitamin C (109% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "0.8g salt (table)",
                "7g olive oil (salad or cooking)",
                "5g lemon juice (raw)",
                "113g Brussels sprouts (raw)",
              ],
              directions: [
                "Keeping the stems intact, trim any loose or yellow leaves on the Brussels sprouts.",
                "If you have a mandoline or vegetable slicer: Holding each sprout by the stem end, finely shred them on the slicer. If not, chop the sprouts finely with a kitchen knife.",
                "Drizzle the slaw with olive oil, lemon juice, and salt, then toss well.",
              ],
              nutrition: {
                calories: 109,
                fat: "53%",
                protein: "13%",
                carbs: "35%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "61",
          fat: "180",
          protein: "101",
          breakfast: [
            {
              name: "Gordon Ramsay's Scrambled Eggs",
              description:
                "1 serving contains 445 Calories. The macronutrient breakdown is 1% carbs, 81% fat, and 17% protein. This is a good source of protein (35% of your Daily Value), vitamin A (48% of your Daily Value), and vitamin B12 (58% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "3 large eggs (whole, fresh)",
                "5g crème fraîche (½ tbsp)",
                "28g unsalted butter",
              ],
              directions: [
                "Crack your eggs into a heavy skillet/pan with the butter.",
                "With a spatula, mix the eggs in the pan over medium heat.",
                "Keep mixing the eggs in the pan to prevent them from overcooking. Take it on/off the heat to cook slower.",
                "Once the eggs have started to form, add the crème fraîche to cool down the eggs and prevent further cooking.",
                "Season with salt and pepper, then serve!",
              ],
              nutrition: {
                calories: 445,
                fat: "81%",
                protein: "17%",
                carbs: "1%",
              },
            },
          ],
          lunch: [
            {
              name: "Paleo Avocado Tuna Salad (Tuna in Water)",
              description:
                "1 serving contains 357 Calories. The macronutrient breakdown is 15% carbs, 53% fat, and 32% protein. This is a good source of protein (54% of your Daily Value), fiber (34% of your Daily Value), and potassium (21% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 avocado (California, raw, without skin and seed) - 136g",
                "15g lemon juice (raw)",
                "10g onions (raw)",
                "142g tuna (light, canned in water, drained solids)",
                "1 dash salt (table)",
                "1 dash pepper (black)",
              ],
              directions: [
                "Cut the avocado in half and scoop the middle of both avocado halves into a bowl, leaving a shell of avocado flesh about ¼-inch thick on each half.",
                "Add lemon juice and onion to the avocado in the bowl and mash together.",
                "Add drained tuna, salt, and pepper, and stir to combine. Taste and adjust if needed.",
                "Fill avocado shells with tuna salad and serve.",
              ],
              nutrition: {
                calories: 357,
                fat: "53%",
                protein: "32%",
                carbs: "15%",
              },
            },
          ],
          snack: [
            {
              name: "Chicken and Avocado Salad",
              description:
                "1 serving contains 404 Calories. The macronutrient breakdown is 12% carbs, 55% fat, and 34% protein. This is a good source of protein (62% of your Daily Value), fiber (27% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "125g canned chicken (no broth)",
                "½ avocado (raw, all commercial varieties) - 101g",
                "3 sprigs fresh cilantro (coriander leaves, raw) - 7g",
                "9g lime juice (raw)",
                "1 dash salt (table)",
                "4 large leaves butterhead lettuce (includes Boston and Bibb types) - 60g",
              ],
              directions: [
                "Chop the cilantro and cut up the avocado.",
                "Combine the chicken, avocado, cilantro, lime, and salt (use only a squeeze of lime juice and salt to taste).",
                "Arrange the bib leaves and serve the chicken salad on top.",
              ],
              nutrition: {
                calories: 404,
                fat: "55%",
                protein: "34%",
                carbs: "12%",
              },
            },
          ],
          dinner: [
            {
              name: "Pan Seared Salmon with Avocado Remoulade",
              description:
                "1 serving contains 590 Calories. The macronutrient breakdown is 7% carbs, 69% fat, and 24% protein. This is a good source of protein (64% of your Daily Value), fiber (25% of your Daily Value), and potassium (29% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "1g Dijon mustard (Grey Poupon)",
                "14g olive oil (salad or cooking)",
                "3g shallots (raw)",
                "11g lime juice (raw)",
                "7g vegetable oil (Natreon canola, high stability, non-trans, high oleic)",
                "¼ dash salt (table)",
                "0.9g parsley (raw)",
                "½ avocado (raw, all commercial varieties) - 101g",
                "170g Atlantic salmon (wild, raw)",
                "¼ dash pepper (black)",
              ],
              directions: [
                "Put avocado pieces and lime juice into a food processor or blender and pulse until blended.",
                "Slowly add olive oil, pulsing, until you reach the desired consistency of sauce.",
                "Add minced shallots (or green onions) and parsley, pulse just until combined.",
                "Remove to a bowl, add mustard, salt, and pepper to taste.",
                "Coat the bottom of a sauté pan with oil, heat on medium high until almost smoking.",
                "Season both sides of the salmon fillets with salt and pepper, carefully lay the salmon into the pan, skin side down.",
                "Cook the salmon until about medium doneness, about 3-4 minutes per side.",
                "Serve salmon with avocado remoulade sauce.",
              ],
              nutrition: {
                calories: 590,
                fat: "69%",
                protein: "24%",
                carbs: "7%",
              },
            },
            {
              name: "Avocado, Strawberry, and Spinach Salad",
              description:
                "1 serving contains 327 Calories. The macronutrient breakdown is 30% carbs, 61% fat, and 8% protein. This is a good source of fiber (50% of your Daily Value), potassium (31% of your Daily Value), and calcium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "166g strawberries (raw)",
                "½ avocado (raw, all commercial varieties) - 101g",
                "120g spinach (raw)",
                "12g pecans (nuts)",
              ],
              directions: [
                "Chop strawberries and avocado.",
                "Toss chopped strawberries, avocado, spinach, and pecans together in a bowl.",
                "Serve.",
              ],
              nutrition: {
                calories: 327,
                fat: "61%",
                protein: "8%",
                carbs: "30%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "61",
          fat: "180",
          protein: "101",
          breakfast: [
            {
              name: "Tuna Melt Omelet",
              description:
                "1 serving contains 649 Calories. The macronutrient breakdown is 4% carbs, 62% fat, and 35% protein. This is a good source of protein (100% of your Daily Value), vitamin D (36% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "142g tuna (light, canned in water, drained solids)",
                "30g light mayonnaise (salad dressing, light)",
                "10g onions (raw)",
                "20g celery (raw, ½ stalk, medium)",
                "1g parsley (raw)",
                "0.3g lemon juice (raw)",
                "3 extra large eggs (whole, fresh) - 168g",
                "0.8g salt (table)",
                "0.3g black pepper (spices)",
                "14g unsalted butter",
                "28g Monterey cheese (includes Pepper Jack)",
              ],
              directions: [
                "Combine tuna, mayonnaise, onion, celery, parsley, and lemon juice in a bowl and set aside.",
                "In a bowl, whisk together the eggs, salt, and pepper until there are no lumps.",
                "Heat an 8-inch non-stick frying pan over low heat. Add 1 tablespoon of butter and swirl the pan to coat.",
                "Pour in the egg mixture and give it a good swirl so that it spreads thinly across the entire pan. Let the eggs set.",
                "Sprinkle the cheese on the omelet. Run a spatula underneath the omelet and slide it out of the pan (flat) onto a plate.",
                "Immediately top the omelet with the tuna mixture. Fold over and eat.",
              ],
              nutrition: {
                calories: 649,
                fat: "62%",
                protein: "35%",
                carbs: "4%",
              },
            },
          ],
          lunch: [
            {
              name: "Salmon Wrapped Avocado",
              description:
                "1 serving contains 486 Calories. The macronutrient breakdown is 10% carbs, 73% fat, and 18% protein. This is a good source of protein (39% of your Daily Value), fiber (30% of your Daily Value), and vitamin D (113% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "28g regular mayonnaise (with salt)",
                "99g Chinook salmon (smoked)",
                "152g Florida avocado (½ fruit without skin and seeds)",
              ],
              directions: [
                "Slice salmon and avocado into pieces.",
                "Spread mayonnaise on the salmon and wrap around avocado slices. Serve.",
              ],
              nutrition: {
                calories: 486,
                fat: "73%",
                protein: "18%",
                carbs: "10%",
              },
            },
            {
              name: "Basic Protein Shake",
              description:
                "1 cup contains 242 Calories. The macronutrient breakdown is 25% carbs, 22% fat, and 54% protein. This is a good source of protein (57% of your Daily Value), potassium (7% of your Daily Value), and calcium (39% of your Daily Value).",
              makes: "1 cup",
              prepTime: "1 minute",
              ingredients: [
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "244g reduced fat milk (2% milkfat, with added vitamin A and vitamin D)",
              ],
              directions: [
                "Add whey to milk, stir until no chunks of whey are left in the milk.",
              ],
              nutrition: {
                calories: 242,
                fat: "22%",
                protein: "54%",
                carbs: "25%",
              },
            },
          ],
          snack: [
            {
              name: "Paleo Avocado Chicken Salad",
              description:
                "1 serving contains 338 Calories. The macronutrient breakdown is 18% carbs, 64% fat, and 18% protein. This is a good source of protein (29% of your Daily Value), fiber (35% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 California avocado, without skin and seed (136g)",
                "24g lemon juice (½ lemon yields)",
                "28g raw onion (¼ medium)",
                "51g canned chicken (no broth)",
                "1 dash of salt",
                "1 dash of black pepper",
              ],
              directions: [
                "Cut the avocado in half and scoop the middle of both avocado halves into a bowl, leaving a shell of avocado flesh about ¼-inch thick on each half.",
                "Add lemon juice and onion to the avocado in the bowl and mash together.",
                "Add drained chicken, salt, and pepper, and stir to combine. Taste and adjust if needed.",
                "Fill avocado shells with chicken salad and serve.",
              ],
              nutrition: {
                calories: 338,
                fat: "64%",
                protein: "18%",
                carbs: "18%",
              },
            },
          ],
          dinner: [
            {
              name: "Keto Pork Schnitzel",
              description:
                "2 servings contain 741 Calories. The macronutrient breakdown is 6% carbs, 62% fat, and 32% protein. This is a good source of protein (107% of your Daily Value), potassium (26% of your Daily Value), and magnesium (42% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "227g fresh pork tenderloin",
                "1 large egg (50g)",
                "61g reduced fat milk (2% milkfat)",
                "18g flaxseed (seeds)",
                "9g sesame seeds (whole, dried)",
                "14g olive oil (salad or cooking)",
                "14g unsalted butter",
                "1 dash of salt",
                "1 dash of black pepper",
              ],
              directions: [
                "Cut the loin into equal-sized chops. Place each chop in a plastic bag (or wrap) and pound them with a meat tenderizer until they are significantly thinner (under 0.5cm).",
                "Mix the ground flaxseed and ground sesame with some salt and pepper and spread the mixture onto a large dish.",
                "Whisk the egg and milk in a large soup cup or a small mixing bowl and add salt and pepper to taste.",
                "Soak each chop in the egg and milk mixture, then dredge them in the flaxseed and sesame mix, ensuring they get an even coating.",
                "Line a plate with baking paper and lay the coated pork chops to refrigerate for 10 minutes.",
                "In the meantime, heat the oil and butter in a non-stick pan over medium-high heat. Carefully lay the chops into the pan and fry until golden brown and crispy, about 3-4 minutes per side.",
                "Drain the chops onto paper towels and transfer to serving plates while still hot. Enjoy!",
              ],
              nutrition: {
                calories: 741,
                fat: "62%",
                protein: "32%",
                carbs: "6%",
              },
            },
            {
              name: "Spinach Tomato Salad",
              description:
                "1 serving contains 186 Calories. The macronutrient breakdown is 26% carbs, 63% fat, and 11% protein. This is a good source of fiber (20% of your Daily Value), potassium (24% of your Daily Value), and calcium (19% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "150g raw spinach",
                "50g scallions (spring onions or scallions, includes tops and bulb)",
                "62g red, ripe tomatoes (½ medium whole)",
                "14g olive oil (salad or cooking)",
                "1 dash of black pepper",
                "24g lemon juice (from ½ lemon)",
              ],
              directions: [
                "Wash the spinach well, drain, and chop. Squeeze out excess water.",
                "Chop the green onions and tomato.",
                "Put the spinach in a mixing bowl and add the tomato, scallions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and serve.",
              ],
              nutrition: {
                calories: 186,
                fat: "63%",
                protein: "11%",
                carbs: "26%",
              },
            },
          ],
        },
      ],
    },
    vegan: {
      1200: [
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Chocolate Avocado Toast",
              description:
                "1 serving contains 232 Calories. The macronutrient breakdown is 63% carbs, 28% fat, and 9% protein. This is a good source of fiber (24% of your Daily Value), potassium (9% of your Daily Value), and riboflavin (B2) (39% of your Daily Value).",
              makes: "5 servings",
              prepTime: "10 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "40g raw avocado (approximately ⅜ fruit)",
                "6g unsweetened cocoa powder",
                "32g maple syrup",
                "0.8g vanilla extract",
                "0.3g table salt",
                "26g multi-grain bread (1 slice regular)",
              ],
              directions: [
                "In a blender or food processor, combine chopped avocado, cocoa powder, maple syrup, vanilla extract, and salt. Blend until smooth, adding water 1 tablespoon at a time if needed to reach desired texture.",
                "Toast 1 slice of bread per serving.",
                "Spread the chocolate-avocado mixture onto the toasted bread. Enjoy!",
              ],
              nutrition: {
                calories: 232,
                fat: "28%",
                protein: "9%",
                carbs: "63%",
              },
            },
            {
              name: "PB & Dates Energy Bites",
              description:
                "1 serving contains 112 Calories. The macronutrient breakdown is 59% carbs, 33% fat, and 8% protein. This has a relatively high calorie density, with 385 Calories per 100g.",
              makes: "15 servings",
              prepTime: "15 minutes",
              ingredients: [
                "14g Medjool dates (approximately ⅝ date, pitted)",
                "4g dry rolled oats (Quaker)",
                "3g dark chocolate (70-85% cacao solids)",
                "5g dried chia seeds",
                "3g smooth peanut butter (without salt)",
              ],
              directions: [
                "Pulse dates in a food processor or blender until they’re in small pieces or it forms a ball.",
                "Add oats, chocolate, chia seeds, and peanut butter and pulse or mix until combined. Aim for consistently small pieces but do not over-process.",
                "Carefully roll the mixture into 1-inch balls, using the warmth of your hands to mold them together. This should yield 14-15 balls.",
                "To set, place in the refrigerator or freezer for 15 minutes. They can also be eaten immediately! They will keep fresh in an airtight bag or container for up to a week. Freeze for long-term storage.",
              ],
              nutrition: {
                calories: 112,
                fat: "33%",
                protein: "8%",
                carbs: "59%",
              },
            },
          ],
          lunch: [
            {
              name: "Post Workout Green Smoothie",
              description:
                "1 serving contains 154 Calories. The macronutrient breakdown is 83% carbs, 6% fat, and 11% protein. This is a good source of fiber (23% of your Daily Value), potassium (18% of your Daily Value), and calcium (15% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "67g raw kale",
                "120g coconut water (liquid from coconuts)",
                "7g orange marmalade",
                "123g canned pineapple (water pack, solids and liquids)",
                "74g raw blueberries",
              ],
              directions: [
                "Blend kale and coconut water until smooth.",
                "Add remaining ingredients and blend until smooth. Enjoy!",
              ],
              nutrition: {
                calories: 154,
                fat: "6%",
                protein: "11%",
                carbs: "83%",
              },
            },
            {
              name: "Chopped Salad",
              description:
                "1 serving contains 119 Calories. The macronutrient breakdown is 43% carbs, 49% fat, and 7% protein. This is a good source of potassium (13% of your Daily Value), vitamin A (12% of your Daily Value), and vitamin C (66% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "149g red, ripe cherry tomatoes, raw",
                "104g cucumber, with peel, raw",
                "10g raw parsley",
                "60g raw lemon juice",
                "1 dash of salt",
                "7g olive oil, salad or cooking",
                "1 dash of black pepper",
              ],
              directions: [
                "Finely chop the tomato, cucumber, and parsley.",
                "Add lemon juice, olive oil, pepper, and salt.",
                "Mix and serve.",
              ],
              nutrition: {
                calories: 119,
                fat: "49%",
                protein: "7%",
                carbs: "43%",
              },
            },
          ],
          snack: [
            {
              name: "Pecans",
              description:
                "1 ounce of pecans contains 196 Calories. The macronutrient breakdown is 7% carbs, 88% fat, and 5% protein. This has a relatively high calorie density, with 691 Calories per 100g.",
              makes: "1 ounce",
              ingredients: ["28g pecans (nuts)"],
              nutrition: {
                calories: 196,
                fat: "88%",
                carbs: "7%",
                protein: "5%",
              },
            },
          ],
          dinner: [
            {
              name: "Hummus and Grilled Vegetable Wrap",
              description:
                "1 serving contains 308 Calories. The macronutrient breakdown is 55% carbs, 33% fat, and 13% protein. This is a good source of protein (18% of your Daily Value), fiber (23% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "98g zucchini, summer squash, includes skin, raw",
                "62g home-prepared hummus",
                "1 medium tortilla, ready-to-bake or -fry, flour",
                "6g raw almonds",
                "15g raw spinach",
                "30g raw red bell pepper",
                "4g small raw red onion",
              ],
              directions: [
                "Thinly slice the bell pepper and red onion.",
                "Cut the zucchini lengthwise into 1/4 inch slices. Brush both sides of the zucchini slices with oil and sprinkle with salt and pepper. Grill until tender and slightly browned, about 4 minutes per side.",
                "Spread hummus over each tortilla. Add all toppings, roll up, and serve.",
              ],
              nutrition: {
                calories: 308,
                fat: "33%",
                protein: "13%",
                carbs: "55%",
              },
            },
            {
              name: "Green Kale Salad",
              description:
                "1 serving contains 76 Calories. The macronutrient breakdown is 55% carbs, 24% fat, and 21% protein. This is a good source of fiber (16% of your Daily Value), potassium (11% of your Daily Value), and vitamin A (29% of your Daily Value).",
              makes: "6 servings",
              prepTime: "15 minutes",
              ingredients: [
                "45g raw kale",
                "2g olive oil, salad or cooking",
                "0.5g garlic, minced (approximately ⅓ of a clove)",
                "0.8g table salt",
                "0.4g black pepper",
                "21g raw celery (⅓ stalk, large)",
                "27g raw sweet green bell pepper",
                "16g raw zucchini, summer squash, includes skin (approximately ⅙ medium)",
                "25g raw cucumber, with peel (approximately ⅙ cucumber)",
                "15g raw broccoli",
                "22g frozen, unprepared green peas",
                "6g raw alfalfa sprouts",
              ],
              directions: [
                "Microwave peas until just defrosted and set aside. Chop and prep other vegetables.",
                "Add kale, olive oil, garlic, salt, and black pepper to a large bowl. Mix well so that kale is fully coated.",
                "Add remaining ingredients to the bowl and mix. Serve immediately.",
              ],
              nutrition: {
                calories: 76,
                fat: "24%",
                protein: "21%",
                carbs: "55%",
              },
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Sweet Chocolate Oatmeal",
              description:
                "1 serving contains 109 Calories. The macronutrient breakdown is 83% carbs, 7% fat, and 10% protein. This has a relatively low calorie density, with 72 Calories per 100g.",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "118g plain, clean water",
                "20g plain, dry rolled oats",
                "4g dry, unsweetened cocoa",
                "9g brown sugar",
              ],
              directions: [
                "Mix water, oatmeal, and cocoa in a bowl.",
                "Put in the microwave for 1 minute. Check consistency and, if necessary, add more water and heat further.",
                "Stir in brown sugar and enjoy!",
              ],
              nutrition: {
                calories: 109,
                fat: "7%",
                protein: "10%",
                carbs: "83%",
              },
            },
            {
              name: "Peanut Butter and Peach Toast",
              description:
                "1 serving contains 194 Calories. The macronutrient breakdown is 44% carbs, 40% fat, and 16% protein. This is a good source of protein (14% of your Daily Value), fiber (15% of your Daily Value), and potassium (7% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "75g raw peaches (½ medium)",
                "1 slice whole-wheat bread (28g)",
                "16g chunk style peanut butter, without salt",
              ],
              directions: [
                "Toast the bread.",
                "While the bread is toasting, cut half of a peach into slices.",
                "Top the toast with peanut butter and sliced peaches. Enjoy!",
              ],
              nutrition: {
                calories: 194,
                fat: "40%",
                protein: "16%",
                carbs: "44%",
              },
            },
          ],
          lunch: [
            {
              name: "Peach Mango Orange Smoothie",
              description:
                "1 serving contains 197 Calories. The macronutrient breakdown is 90% carbs, 4% fat, and 6% protein. This is a good source of potassium (16% of your Daily Value) and vitamin C (122% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "83g raw mangos",
                "77g raw peaches",
                "249g orange juice",
                "237g plain, clean water",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 197,
                fat: "4%",
                protein: "6%",
                carbs: "90%",
              },
            },
            {
              name: "Strawberry and Walnut Spinach Salad",
              description:
                "1 serving contains 139 Calories. The macronutrient breakdown is 29% carbs, 58% fat, and 13% protein. This is a good source of potassium (8% of your Daily Value), vitamin A (16% of your Daily Value), and vitamin C (57% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "72g raw strawberries (4 large)",
                "16g black walnuts, dried",
                "30g raw spinach",
                "15g Newman's Own low-fat balsamic vinaigrette",
              ],
              directions: [
                "Slice strawberries and chop walnuts.",
                "Toss with spinach and dressing.",
              ],
              nutrition: {
                calories: 139,
                fat: "58%",
                protein: "13%",
                carbs: "29%",
              },
            },
          ],
          snack: [
            {
              name: "Veggie Nori Roll",
              description:
                "1 serving contains 152 Calories. The macronutrient breakdown is 35% carbs, 47% fat, and 17% protein. This is a good source of fiber (25% of your Daily Value), potassium (8% of your Daily Value), and vitamin A (30% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "30g commercial hummus",
                "8g raw alfalfa sprouts",
                "31g raw carrots",
                "26g raw cucumber (with peel)",
                "3g raw seaweed (1 sheet)",
                "37g raw avocado",
                "8g nutritional yeast (vegan)",
                "1 dash table salt (0.4g)",
              ],
              directions: [
                "Cut cucumbers, carrots, and avocado into thin slices.",
                "Place the seaweed sheet on a work surface. Spread the hummus in a thin layer over the sheet.",
                "Layer the sprouts, carrots, cucumber, and avocado on top of the bottom one-third of the sheet. Sprinkle with nutritional yeast and salt to taste.",
                "Gently but firmly, roll the edge closest to you toward the center of the wrap, carefully rolling a sushi-like roll (a sushi mat makes this easier).",
                "Slice the roll with a sharp knife and serve immediately.",
              ],
              nutrition: {
                calories: 152,
                fat: "47%",
                protein: "17%",
                carbs: "35%",
              },
            },
          ],
          dinner: [
            {
              name: "Cheesy Vegan Zoodles",
              description:
                "1 serving contains 247 Calories. The macronutrient breakdown is 56% carbs, 9% fat, and 35% protein. This is a good source of protein (41% of your Daily Value), fiber (50% of your Daily Value), and potassium (19% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "236g raw zucchini (2 small)",
                "40g raw onions",
                "75g raw red bell pepper",
                "23g low sodium vegetable broth (from bouillons)",
                "5g garlic powder",
                "45g nutritional yeast (vegan)",
                "½ dash black pepper (0.05g)",
                "½ dash table salt (0.2g)",
              ],
              directions: [
                "Spiralize the zucchini. Dice the onion and bell pepper.",
                "Add the zucchini, onion, and red pepper to a pan with the vegetable broth. Cook over medium heat for a few minutes.",
                "Add the garlic powder and nutritional yeast, and cook for a few minutes until everything is combined and creamy.",
                "Add salt and pepper to taste.",
              ],
              nutrition: {
                calories: 247,
                fat: "9%",
                protein: "35%",
                carbs: "56%",
              },
            },
            {
              name: "Balsamic Sautéed Spinach",
              description:
                "1 serving contains 162 Calories. The macronutrient breakdown is 19% carbs, 76% fat, and 5% protein. This is a good source of potassium (8% of your Daily Value), vitamin A (31% of your Daily Value), and vitamin E (21% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "14g olive oil (salad or cooking)",
                "60g raw spinach",
                "32g balsamic vinegar",
                "1 dash table salt (0.4g)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Heat olive oil in a pan over medium-high heat.",
                "Cook spinach in olive oil and add balsamic vinegar, salt, and pepper.",
                "Let spinach heat through and cook until slightly wilted. Remove from heat and enjoy!",
              ],
              nutrition: {
                calories: 162,
                fat: "76%",
                protein: "5%",
                carbs: "19%",
              },
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Blackberry Almond Butter Sandwich",
              description:
                "1 serving contains 286 Calories. The macronutrient breakdown is 46% carbs, 38% fat, and 16% protein. This is a good source of protein (21% of your Daily Value), fiber (38% of your Daily Value), and potassium (8% of your Daily Value).",
              makes: "1 serving",
              prepTime: "2 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "52g multi-grain bread (includes whole-grain), 2 slices",
                "4g dried chia seeds",
                "72g raw blackberries",
                "16g plain almond butter (without salt added)",
              ],
              directions: [
                "Mash blackberries in a bowl and stir in chia seeds.",
                "Toast the bread and make the sandwich with almond butter and blackberry mixture, topping with the other slice of bread.",
              ],
              nutrition: {
                calories: 286,
                fat: "38%",
                protein: "16%",
                carbs: "46%",
              },
            },
            {
              name: "Cinnamon Apple Bites",
              description:
                "1 serving contains 81 Calories. The macronutrient breakdown is 96% carbs, 2% fat, and 2% protein. This has a relatively low calorie density, with 50 Calories per 100g.",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "161g medium apple (without skin)",
                "1g ground cinnamon",
              ],
              directions: [
                "Cut up the apple (with or without skin - your choice) into bite-sized chunks.",
                "Put the chopped apple into a container with a lid (e.g., an empty margarine container).",
                "Sprinkle on the cinnamon, put the lid on the container, and gently shake so the cinnamon covers the apple.",
                "Eat and enjoy immediately.",
              ],
              nutrition: {
                calories: 81,
                fat: "2%",
                protein: "2%",
                carbs: "96%",
              },
            },
          ],
          lunch: [
            {
              name: "Peach and Strawberry Smoothie",
              description:
                "1 serving contains 118 Calories. The macronutrient breakdown is 87% carbs, 6% fat, and 7% protein. This is a good source of fiber (19% of your Daily Value), potassium (11% of your Daily Value), and vitamin C (111% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "152g raw strawberries",
                "154g raw peaches",
                "153g Diet Snapple Apple",
              ],
              directions: [
                "Blend strawberries, peaches, and Diet Snapple Apple together until smooth.",
                "Serve immediately.",
              ],
              nutrition: {
                calories: 118,
                fat: "6%",
                protein: "7%",
                carbs: "87%",
              },
            },
            {
              name: "Almonds",
              description:
                "1 ounce of almonds contains 164 Calories. The macronutrient breakdown is 14% carbs, 72% fat, and 14% protein. This is a good source of magnesium (18% of your Daily Value), vitamin E (48% of your Daily Value), and riboflavin (B2) (25% of your Daily Value).",
              ingredients: ["28g raw almonds"],
              directions: [
                "Enjoy almonds as a snack or add them to dishes for extra flavor and nutrition.",
              ],
            },
          ],
          snack: [
            {
              name: "Salt & Vinegar Popcorn",
              description:
                "1 serving contains 124 Calories. The macronutrient breakdown is 77% carbs, 10% fat, and 13% protein. This is a good source of fiber (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "32g air-popped popcorn",
                "4 tablespoons malt vinegar",
                "6g salt",
              ],
              directions: [
                "Put 1/4 cup of unpopped kernels in an air popper and pop them.",
                "Put malt vinegar into a clean spray bottle and spritz all over the popcorn.",
                "Finish with a dash of salt and serve!",
              ],
              nutrition: {
                calories: 124,
                fat: "10%",
                protein: "13%",
                carbs: "77%",
              },
            },
          ],
          dinner: [
            {
              name: "Bow Ties with Veggies",
              description:
                "1 serving contains 256 Calories. The macronutrient breakdown is 71% carbs, 15% fat, and 14% protein. This is a good source of protein (17% of your Daily Value), potassium (8% of your Daily Value), and magnesium (24% of your Daily Value).",
              makes: "4 servings",
              prepTime: "15 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "57g whole wheat pasta, dry",
                "¼ large onion, raw (38g)",
                "81g zucchini, raw (about 7/16 medium)",
                "3g olive oil, salad or cooking",
                "¼ dash black pepper (0.03g)",
                "¼ dash salt (0.1g)",
              ],
              directions: [
                "Chop onion and zucchini.",
                "Bring a large pot of lightly salted water to a boil. Add pasta and cook for 8 to 10 minutes or until al dente; drain.",
                "In a skillet over medium heat, sauté zucchini and onion in olive oil until tender.",
                "Toss farfalle pasta with vegetables and season with salt and pepper; serve.",
              ],
              nutrition: {
                calories: 256,
                fat: "15%",
                protein: "14%",
                carbs: "71%",
              },
            },
            {
              name: "Carrots with Hummus",
              description:
                "1 serving contains 175 Calories. The macronutrient breakdown is 48% carbs, 36% fat, and 15% protein. This is a good source of fiber (28% of your Daily Value), potassium (12% of your Daily Value), and vitamin A (113% of your Daily Value).",
              makes: "1 serving",
              prepTime: "1 minute",
              ingredients: ["75g commercial hummus", "122g raw carrots"],
              directions: ["Dip carrots into hummus and eat."],
              nutrition: {
                calories: 175,
                fat: "36%",
                protein: "15%",
                carbs: "48%",
              },
            },
          ],
        },
      ],
      1700: [
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Fast Oatmeal",
              description:
                "1 bowl contains 244 Calories. The macronutrient breakdown is 87% carbs, 4% fat, and 9% protein. This is a good source of fiber (22% of your Daily Value) and potassium (7% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "1 minute",
              ingredients: [
                "28g seedless raisins",
                "237g plain, clean water",
                "0.6g ground cinnamon",
                "6g brown sugar",
                "40g Quaker dry rolled oats",
              ],
              directions: [
                "Add the oats, water, and raisins together and put in the microwave for 45 seconds.",
                "Stir once and then microwave again for 45 seconds.",
                "Set it aside for a couple of minutes to absorb more liquid.",
                "Then sprinkle with cinnamon and brown sugar.",
              ],
              nutrition: {
                calories: 244,
                fat: "4%",
                protein: "9%",
                carbs: "87%",
              },
            },
            {
              name: "Guacamole on Tostada",
              description:
                "1 serving contains 192 Calories. The macronutrient breakdown is 36% carbs, 59% fat, and 6% protein. This is a good source of fiber (23% of your Daily Value), potassium (12% of your Daily Value), and vitamin B6 (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "68g California avocados (½ fruit, without skin and seed)",
                "62g Roma tomato (1 tomato)",
                "15g raw lemon juice",
                "14g raw onions",
                "1g fresh cilantro (coriander leaves)",
                "12g corn tostada shell (1 piece)",
              ],
              directions: [
                "Mash the avocado and mix with the tomatoes, lemon juice, onion, and cilantro.",
                "Smear on the tostada. Enjoy!",
              ],
              nutrition: {
                calories: 192,
                fat: "59%",
                protein: "6%",
                carbs: "36%",
              },
            },
          ],
          lunch: [
            {
              name: "Berry and Spinach Smoothie",
              description:
                "1 smoothie contains 307 Calories. The macronutrient breakdown is 54% carbs, 9% fat, and 37% protein. This is a good source of protein (49% of your Daily Value), fiber (38% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "1 smoothie",
              prepTime: "5 minutes",
              ingredients: [
                "240g plain almond milk",
                "30g organic plain rice protein",
                "50g dark sweet frozen cherries (½ serving)",
                "75g frozen unsweetened strawberries",
                "70g wild frozen blueberries",
                "45g raw spinach",
              ],
              directions: [
                "Add ingredients in blender and blend.",
                "If you prefer a sweeter smoothie, add a small amount of sugar to taste (1 tsp at a time).",
                "You can also add 2 tbsp of soy creamer for a richer smoothie.",
              ],
              nutrition: {
                calories: 307,
                fat: "9%",
                protein: "37%",
                carbs: "54%",
              },
            },
            {
              name: "Tomato Basil Layered Salad",
              description:
                "1 serving contains 184 Calories. The macronutrient breakdown is 36% carbs, 57% fat, and 7% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin K (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "91g red ripe tomato (½ large whole)",
                "68g California avocado (½ fruit, without skin and seed)",
                "4g fresh basil (8 whole leaves)",
                "15g commercial hummus",
                "32g balsamic vinegar",
              ],
              directions: [
                "Slice tomato into ¼-inch thick slices.",
                "Slice avocado into ¼-inch thick slices.",
                "Tear off, rinse, and dry basil.",
                "Layer tomato, basil, and avocado to create a striped presentation.",
                "Add hummus and vinegar to a food processor and pulse until smooth.",
                "Pour the dressing over the salad just before serving. Enjoy!",
              ],
              nutrition: {
                calories: 184,
                fat: "57%",
                protein: "7%",
                carbs: "36%",
              },
            },
          ],
          snack: [
            {
              name: "Vegan Cinnamon Date Caramel & Apples",
              description:
                "1 serving contains 217 Calories. The macronutrient breakdown is 96% carbs, 1% fat, and 2% protein. This is a good source of fiber (22% of your Daily Value) and potassium (11% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              ingredients: [
                "60g Medjool dates (2 ½ dates, pitted)",
                "15g plain almond milk",
                "¼ dash table salt",
                "91g apple (½ medium, with skin)",
              ],
              directions: [
                "If your dates aren’t fresh (plump and squishy), soak them in warm water for an hour or two. When they feel soft and look more hydrated, drain and rinse them.",
                "In a food processor or high-powered blender, process the pitted dates, almond milk, and salt until smooth and creamy, resembling a caramel-like consistency.",
                "Serve with apple slices and enjoy!",
              ],
              nutrition: {
                calories: 217,
                fat: "1%",
                protein: "2%",
                carbs: "96%",
              },
            },
          ],
          dinner: [
            {
              name: "Hummus and Grilled Vegetable Wrap",
              description:
                "1 serving contains 308 Calories. The macronutrient breakdown is 55% carbs, 33% fat, and 13% protein. This is a good source of protein (18% of your Daily Value), fiber (23% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "98g zucchini, summer squash, includes skin, raw",
                "62g home-prepared hummus",
                "1 medium tortilla, ready-to-bake or -fry, flour",
                "6g raw almonds",
                "15g raw spinach",
                "30g raw red bell pepper",
                "4g small raw red onion",
              ],
              directions: [
                "Thinly slice the bell pepper and red onion.",
                "Cut the zucchini lengthwise into 1/4 inch slices. Brush both sides of the zucchini slices with oil and sprinkle with salt and pepper. Grill until tender and slightly browned, about 4 minutes per side.",
                "Spread hummus over each tortilla. Add all toppings, roll up, and serve.",
              ],
              nutrition: {
                calories: 308,
                fat: "33%",
                protein: "13%",
                carbs: "55%",
              },
            },
            {
              name: "Spinach & Avocado Soup",
              description:
                "1 serving contains 256 Calories. The macronutrient breakdown is 14% carbs, 80% fat, and 6% protein. This is a good source of fiber (22% of your Daily Value), potassium (15% of your Daily Value), and vitamin A (32% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "68g California avocado (½ fruit, without skin and seed)",
                "60g raw spinach",
                "90g low sodium vegetable broth",
                "15g raw lemon juice",
                "14g olive oil (salad or cooking)",
                "½ dash black pepper",
              ],
              directions: [
                "Remove the pit from the avocado and scoop out the flesh into a blender.",
                "Add spinach, broth, lemon juice, and olive oil.",
                "Blend until smooth and creamy and serve. It can be optionally heated if desired.",
              ],
              nutrition: {
                calories: 256,
                fat: "80%",
                protein: "6%",
                carbs: "14%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Power Granola",
              description:
                "1 serving contains 393 Calories. The macronutrient breakdown is 56% carbs, 32% fat, and 12% protein. This is a good source of protein (23% of your Daily Value), fiber (37% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "40g plain dry rolled oats",
                "8g black walnuts (dried)",
                "16g pumpkin and squash seed kernels (dried)",
                "14g seedless raisins",
                "3g ground cinnamon",
                "74g raw blueberries",
                "120g plain almond milk",
              ],
              directions: [
                "Place oats in a medium serving bowl; top with walnuts, pumpkin seeds, raisins, and cinnamon.",
                "Toss together well.",
                "Top with blueberries and serve with almond milk.",
              ],
              nutrition: {
                calories: 393,
                fat: "32%",
                protein: "12%",
                carbs: "56%",
              },
            },
          ],
          lunch: [
            {
              name: "Hemp Protein Shake",
              description:
                "1 serving contains 354 Calories. The macronutrient breakdown is 66% carbs, 20% fat, and 14% protein. This is a good source of protein (24% of your Daily Value), fiber (72% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "30g Manitoba Hemp Protein Powder",
                "21g raw agave nectar",
                "8g ground cinnamon",
                "120g Living Harvest Hemp Milk",
                "1 medium raw banana (118g)",
                "118g plain clean water",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 354,
                fat: "20%",
                protein: "14%",
                carbs: "66%",
              },
            },
            {
              name: "Chopped Salad",
              description:
                "1 serving contains 119 Calories. The macronutrient breakdown is 43% carbs, 49% fat, and 7% protein. This is a good source of potassium (13% of your Daily Value), vitamin A (12% of your Daily Value), and vitamin C (66% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "149g cherry tomatoes, red, ripe, raw",
                "104g cucumber, with peel, raw",
                "10g parsley, raw",
                "60g lemon juice, raw",
                "0.4g salt, table",
                "7g olive oil, salad or cooking",
                "0.1g black pepper, spices",
              ],
              directions: [
                "Finely chop tomato, cucumber, and parsley.",
                "Add lemon juice, olive oil, pepper, and salt.",
                "Mix and serve.",
              ],
              nutrition: {
                calories: 119,
                fat: "49%",
                protein: "7%",
                carbs: "43%",
              },
            },
          ],
          snack: [
            {
              name: "Red Pepper & Tomato Salad",
              description:
                "1 serving contains 230 Calories. The macronutrient breakdown is 38% carbs, 55% fat, and 6% protein. This is a good source of fiber (20% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "119g red bell pepper, sweet, raw",
                "14g olive oil, salad or cooking",
                "32g balsamic vinegar",
                "246g tomatoes, red, ripe, raw, year-round average",
                "0.4g salt, table",
                "0.1g black pepper, spices",
                "0.3g oregano, dried",
              ],
              directions: [
                "Coarsely chop red pepper and tomatoes. Place into a bowl.",
                "Pour in olive oil and balsamic vinegar. Season with salt, pepper, and oregano. Toss gently to coat.",
                "Refrigerate until ready to serve. Enjoy!",
              ],
              nutrition: {
                calories: 230,
                fat: "55%",
                protein: "6%",
                carbs: "38%",
              },
            },
          ],
          dinner: [
            {
              name: "Burrito Bowl",
              description:
                "1 serving contains 174 Calories. The macronutrient breakdown is 62% carbs, 21% fat, and 18% protein. This is a good source of protein (15% of your Daily Value), fiber (35% of your Daily Value), and potassium (14% of your Daily Value).",
              makes: "6 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "7g wild rice, raw",
                "24g lettuce, romaine or cos, raw",
                "21g roma tomatoes, red, ripe, raw",
                "23g avocados, California, raw",
                "18g corn, sweet, yellow, canned, whole kernel, drained solids",
                "87g canned black beans, low sodium, drained",
                "43g salsa, ready-to-serve",
              ],
              directions: [
                "Prepare rice as per package directions. Set aside.",
                "Chop lettuce, tomato, and avocado.",
                "Top rice with the remaining ingredients and enjoy!",
              ],
              nutrition: {
                calories: 174,
                fat: "21%",
                protein: "18%",
                carbs: "62%",
              },
            },
            {
              name: "Avocado, Strawberry, and Spinach Salad",
              description:
                "1 serving contains 327 Calories. The macronutrient breakdown is 30% carbs, 61% fat, and 8% protein. This is a good source of fiber (50% of your Daily Value), potassium (31% of your Daily Value), and calcium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "166g strawberries, raw",
                "101g avocados, raw, all commercial varieties",
                "120g spinach, raw",
                "12g pecans, nuts",
              ],
              directions: [
                "Chop strawberries and avocado.",
                "Toss chopped strawberries, avocado, spinach, and pecans together in a bowl. Serve.",
              ],
              nutrition: {
                calories: 327,
                fat: "61%",
                protein: "8%",
                carbs: "30%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Spicy Tempeh Hash",
              description:
                "1 serving contains 396 Calories. The macronutrient breakdown is 34% carbs, 42% fat, and 23% protein. This is a good source of protein (44% of your Daily Value), fiber (18% of your Daily Value), and potassium (23% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "113g tempeh",
                "7g vegetable oil (Natreon canola, high stability, non-trans, high oleic)",
                "80g onions, raw",
                "46g red bell pepper, sweet, raw",
                "75g potato, flesh and skin, raw",
                "2g paprika",
                "0.8g garlic powder",
                "0.6g onion powder",
                "0.2g cayenne pepper (½ pinch)",
              ],
              directions: [
                "Crumble tempeh and spread it in the bottom of a large nonstick skillet.",
                "Add 1/2 cup water, cover, and bring to a boil. Reduce heat to medium-low and simmer for 5 minutes. Drain in a colander and wipe out the skillet.",
                "Add oil to the skillet and heat over medium-high heat. Return tempeh to the pan along with onion, bell pepper, and potatoes. Sauté for 5 to 7 minutes, or until beginning to brown.",
                "Sprinkle with paprika, garlic powder, onion powder, and cayenne pepper. Sauté for an additional 7 to 8 minutes, or until potatoes are browned and tender.",
              ],
              nutrition: {
                calories: 396,
                fat: "42%",
                protein: "23%",
                carbs: "34%",
              },
            },
          ],
          lunch: [
            {
              name: "Dairy-Free Protein and Cocoa Shake",
              description:
                "1 shake contains 211 Calories. The macronutrient breakdown is 44% carbs, 8% fat, and 48% protein. This is a good source of protein (46% of your Daily Value), fiber (23% of your Daily Value), and iron (59% of your Daily Value).",
              makes: "1 shake",
              prepTime: "5 minutes",
              ingredients: [
                "5g cocoa (dry powder, unsweetened)",
                "13g white, granulated sugar",
                "30g Organic Plain Rice Protein (NutriBiotic)",
                "120g almond milk (plain)",
                "118g plain, clean water",
                "119g ice cubes (frozen water)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 211,
                fat: "8%",
                protein: "48%",
                carbs: "44%",
              },
            },
            {
              name: "Chopped Radish and Avocado Salad",
              description:
                "1 serving contains 272 Calories. The macronutrient breakdown is 20% carbs, 76% fat, and 4% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin B6 (27% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "68g California avocado (½ fruit, without skin and seed)",
                "11g raw radishes (2 ½ medium)",
                "18g raw onion (¼ small)",
                "140g peeled, raw cucumber (½ large)",
                "24g raw lemon juice (½ lemon yields)",
                "14g olive oil (salad or cooking)",
                "2g raw garlic (½ clove, minced)",
                "5g raw shallots",
                "0.2g salt (½ dash)",
                "0.05g black pepper (½ dash)",
                "8g raw parsley",
              ],
              directions: [
                "Chop the avocado, radishes, onion, and cucumber. Set aside.",
                "Make the dressing by combining the lemon juice, olive oil, minced garlic, minced shallots, salt, and pepper in a large bowl. Mix thoroughly.",
                "Add the chopped avocado, radish, onion, and cucumber to the bowl. Toss with the dressing until everything is evenly coated.",
                "Add more salt and pepper if desired, garnish with parsley, and then serve. Enjoy!",
              ],
              nutrition: {
                calories: 272,
                fat: "76%",
                protein: "4%",
                carbs: "20%",
              },
            },
          ],
          snack: [
            {
              name: "Avocado Lettuce Wrap",
              description:
                "2 wraps contain 194 Calories. The macronutrient breakdown is 29% carbs, 64% fat, and 7% protein. This is a good source of fiber (33% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (33% of your Daily Value).",
              makes: "8 wraps",
              prepTime: "10 minutes",
              ingredients: [
                "92g red, ripe tomatoes (¾ medium whole)",
                "2g raw jalapeno pepper (⅛ pepper)",
                "5g raw onion",
                "2g raw garlic (¾ clove)",
                "1g fresh cilantro (coriander leaves, raw)",
                "56g romaine or cos lettuce (2 outer leaves)",
                "101g raw avocado (½ fruit)",
                "3g raw lime juice",
              ],
              directions: [
                "Mince garlic, dice tomatoes and jalapeno, and chop cilantro.",
                "In a medium-sized bowl, mash the avocados. Stir in all the ingredients other than the lettuce.",
                "Spread 2 to 3 tablespoons of this mixture onto lettuce leaves and wrap.",
              ],
              nutrition: {
                calories: 194,
                fat: "64%",
                protein: "7%",
                carbs: "29%",
              },
            },
          ],
          dinner: [
            {
              name: "Coconut Green Curry and Soba",
              description:
                "1 serving contains 488 Calories. The macronutrient breakdown is 40% carbs, 48% fat, and 12% protein. This is a good source of protein (27% of your Daily Value), fiber (17% of your Daily Value), and potassium (12% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "57g dry soba noodles",
                "24g plain almond butter (without salt added)",
                "60g raw coconut milk (liquid expressed from grated meat and water)",
                "11g curry paste",
              ],
              directions: [
                "Bring water to a boil in a large saucepot and briefly boil the noodles.",
                "Drain the noodles into a colander, then return the pot to the burner.",
                "Stir or whisk together almond butter, curry paste, coconut milk (and any herbs like mint, basil, tamari, etc.).",
                "Once an even consistency is reached, pour over noodles and serve immediately.",
              ],
              nutrition: {
                calories: 488,
                fat: "48%",
                protein: "12%",
                carbs: "40%",
              },
            },
            {
              name: "Strawberry and Walnut Spinach Salad",
              description:
                "1 serving contains 139 Calories. The macronutrient breakdown is 29% carbs, 58% fat, and 13% protein. This is a good source of potassium (8% of your Daily Value), vitamin A (16% of your Daily Value), and vitamin C (57% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "72g raw strawberries (4 large)",
                "16g black walnuts (dried)",
                "30g raw spinach",
                "15g Newman's Own low-fat balsamic vinaigrette",
              ],
              directions: [
                "Slice the strawberries and chop the walnuts.",
                "Toss with spinach and dressing.",
              ],
              nutrition: {
                calories: 139,
                fat: "58%",
                protein: "13%",
                carbs: "29%",
              },
            },
          ],
        },
      ],
      2200: [
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Vegan Breakfast Burritos",
              description:
                "1 serving contains 340 Calories. The macronutrient breakdown is 55% carbs, 26% fat, and 20% protein. This is a good source of protein (31% of your Daily Value), fiber (38% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "4 servings",
              prepTime: "20 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "57g extra firm tofu (⅛ block)",
                "3g olive oil (salad or cooking)",
                "0.8g salt (table)",
                "0.4g garlic powder",
                "0.3g cumin (spices, cumin seed)",
                "0.2g chili powder",
                "0.1g turmeric (ground)",
                "46g medium flour tortilla (1 tortilla)",
                "106g low sodium canned black beans (¼ can, drained)",
                "65g ready-to-serve salsa",
                "6g raw jalapeno peppers",
                "15g raw spinach",
              ],
              directions: [
                "Pat tofu dry and roll in a clean, absorbent towel with something heavy on top, such as a cast iron skillet, for 15 minutes.",
                "Unwrap tofu and use a fork to crumble into bite-sized pieces.",
                "Add olive oil to pan and warm. Add tofu to pan and sauté with the spices for 5-7 minutes until tofu is slightly browned. Set aside.",
                "Steam tortillas by pouring 1/2 inch water into the bottom of the steamer, then line the steaming basket with a clean, heavy kitchen towel. Lay the tortillas in the basket in stacks (a small vegetable steamer will accommodate only one stack; a large Asian steamer will hold three or four stacks).",
                "Drain and rinse black beans.",
                "Steam spinach for 3-4 minutes or until just wilted.",
                "Top tortillas with tofu mixture, beans, salsa, jalapeno, and spinach. Enjoy!",
              ],
              nutrition: {
                calories: 340,
                fat: "26%",
                protein: "20%",
                carbs: "55%",
              },
            },
            {
              name: "Grapes",
              description:
                "1 cup of grapes contains 104 Calories. The macronutrient breakdown is 94% carbs, 2% fat, and 4% protein. This is a good source of vitamin K (18% of your Daily Value).",
              makes: "1 cup",
              prepTime: "1 minute",
              ingredients: [
                "151g red or green grapes (raw, European type, such as Thompson seedless)",
              ],
              directions: ["Wash and enjoy."],
              nutrition: {
                calories: 104,
                fat: "2%",
                protein: "4%",
                carbs: "94%",
              },
            },
          ],
          lunch: [
            {
              name: "Seedy Avocado Toast",
              description:
                "1 serving of seedy avocado toast contains 278 Calories. The macronutrient breakdown is 30% carbs, 58% fat, and 12% protein. This is a good source of protein (16% of your Daily Value), fiber (34% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "26g multi-grain bread (includes whole-grain)",
                "68g avocado (California, raw)",
                "6g sunflower seed kernels (dried)",
                "5g organic hemp seeds (shelled)",
                "6g chia seeds (dried)",
                "2g salt",
                "0.5g black pepper",
                "6g lemon juice (raw, from 1 wedge)",
              ],
              directions: [
                "Mash avocado and mix with chia seeds.",
                "Toast bread and top with avocado mix, sunflower and hemp seeds, lemon juice, salt, and pepper.",
                "Top with more seeds if desired.",
              ],
              nutrition: {
                calories: 278,
                fat: "58%",
                protein: "12%",
                carbs: "30%",
              },
            },
            {
              name: "Mixed Greens with Sliced Cucumber and Avocado",
              description:
                "1 serving of mixed greens with sliced cucumber and avocado contains 160 Calories. The macronutrient breakdown is 32% carbs, 61% fat, and 7% protein. This is a good source of fiber (26% of your Daily Value), potassium (10% of your Daily Value), and vitamin K (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "113g field greens salad mix (Dole)",
                "67g cucumber (peeled, raw)",
                "68g avocado (California, raw)",
                "15g reduced-fat Italian dressing",
                "0.4g salt",
                "0.1g black pepper",
              ],
              directions: [
                "Combine salad greens, cucumber, and avocado.",
                "Toss with low-carb salad dressing of your choice and season to taste with salt and freshly ground black pepper.",
              ],
              nutrition: {
                calories: 160,
                fat: "61%",
                protein: "7%",
                carbs: "32%",
              },
            },
          ],
          snack: [
            {
              name: "Almond Butter & Celery",
              description:
                "1 serving of almond butter & celery contains 217 Calories. The macronutrient breakdown is 17% carbs, 70% fat, and 13% protein. This is a good source of fiber (19% of your Daily Value), potassium (12% of your Daily Value), and calcium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "2 minutes",
              ingredients: [
                "32g almond butter (plain, without salt added)",
                "128g celery (raw, 2 large stalks, 11 inches long)",
              ],
              directions: ["Spread almond butter on celery and enjoy!"],
              nutrition: {
                calories: 217,
                fat: "70%",
                protein: "13%",
                carbs: "17%",
              },
            },
          ],
          dinner: [
            {
              name: "Garlic Green Beans with Tofu",
              description:
                "1 serving of garlic green beans with tofu contains 261 Calories. The macronutrient breakdown is 27% carbs, 56% fat, and 17% protein. This is a good source of protein (21% of your Daily Value), fiber (20% of your Daily Value), and potassium (14% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "14g olive oil (salad or cooking)",
                "6g garlic (raw, 2 cloves, minced)",
                "113g tofu (silken, firm, Mori-nu, soy)",
                "200g green beans (snap beans, raw)",
              ],
              directions: [
                "Rinse the green beans and snip off their ends.",
                "In a wok or nonstick skillet, warm the oil over medium heat. Add the green beans and garlic and sauté for 5 minutes, stirring continuously.",
                "Add the tofu and cook for 5 more minutes.",
              ],
              nutrition: {
                calories: 261,
                fat: "56%",
                protein: "17%",
                carbs: "27%",
              },
            },
            {
              name: "Avocado Tomato Salad",
              description:
                "1 bowl of avocado tomato salad contains 344 Calories. The macronutrient breakdown is 24% carbs, 71% fat, and 5% protein. This is a good source of fiber (54% of your Daily Value), potassium (25% of your Daily Value), and vitamin B6 (47% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "5 minutes",
              ingredients: [
                "201g avocado (raw, all commercial varieties, 1 fruit)",
                "0.1g black pepper (spices, 1 dash)",
                "0.4g salt (table, 1 dash)",
                "29g lemon (raw, without peel, ½ small fruit)",
                "75g cherry tomatoes (red, ripe, raw)",
              ],
              directions: [
                "Dice the avocado. Squeeze half of the lemon over the top. Add ground salt and pepper.",
                "Dice the tomatoes into halves and add to the salad.",
              ],
              nutrition: {
                calories: 344,
                fat: "71%",
                protein: "5%",
                carbs: "24%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Blueberry Almond Butter Oatmeal",
              description:
                "1 serving of blueberry almond butter oatmeal contains 304 Calories. The macronutrient breakdown is 53% carbs, 35% fat, and 12% protein. This is a good source of protein (18% of your Daily Value), fiber (31% of your Daily Value), and potassium (7% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "237g water (plain, clean)",
                "40g oatmeal (cereals, Quaker, dry rolled oats)",
                "21g almond butter (nuts, plain, without salt added)",
                "74g blueberries (raw)",
              ],
              directions: [
                "Bring water to a boil in a medium saucepan over medium heat.",
                "Add oats; cook, stirring frequently, for 3 to 4 minutes, or until most of the water is absorbed. Remove from heat.",
                "Combine oatmeal and almond butter in a medium serving bowl; top with blueberries and enjoy!",
              ],
              nutrition: {
                calories: 304,
                fat: "35%",
                protein: "12%",
                carbs: "53%",
              },
            },
          ],
          lunch: [
            {
              name: "Vegan Mexican Tortilla Roll Ups",
              description:
                "1 serving of vegan Mexican tortilla roll ups contains 405 Calories. The macronutrient breakdown is 64% carbs, 22% fat, and 14% protein. This is a good source of protein (26% of your Daily Value), fiber (42% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "5 servings",
              prepTime: "10 minutes",
              ingredients: [
                "1 tortilla, medium (ready-to-bake or -fry, flour)",
                "74g hummus (home prepared)",
                "85g canned black beans (low sodium, drained)",
                "24g red bell pepper (sweet, raw)",
                "51g corn (sweet, yellow, raw)",
                "33g onions (sweet, raw)",
                "0.8g fresh cilantro (coriander leaves, raw)",
              ],
              directions: [
                "Drain and rinse beans and corn. Chop veggies and cilantro.",
                "Slather the tortillas with hummus, getting it all the way to the edges.",
                "Distribute all toppings amongst tortillas and roll tortillas up tightly.",
                "Gently slice into bite-sized pieces and serve.",
              ],
              nutrition: {
                calories: 405,
                fat: "22%",
                protein: "14%",
                carbs: "64%",
              },
            },
            {
              name: "Carrots with Hummus",
              description:
                "1 serving contains 175 Calories. The macronutrient breakdown is 48% carbs, 36% fat, and 15% protein. This is a good source of fiber (28% of your Daily Value), potassium (12% of your Daily Value), and vitamin A (113% of your Daily Value).",
              makes: "1 serving",
              prepTime: "1 minute",
              ingredients: ["75g commercial hummus", "122g raw carrots"],
              directions: ["Dip carrots into hummus and eat."],
              nutrition: {
                calories: 175,
                fat: "36%",
                protein: "15%",
                carbs: "48%",
              },
            },
          ],
          snack: [
            {
              name: "Vegan Banana Oat Soy Shake",
              description:
                "1 serving of vegan banana oat soy shake contains 266 Calories. The macronutrient breakdown is 72% carbs, 13% fat, and 15% protein. This is a good source of protein (19% of your Daily Value), fiber (25% of your Daily Value), and potassium (11% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "50g raw banana",
                "162g soymilk (original and vanilla, unfortified)",
                "40g oatmeal (dry rolled oats)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 266,
                fat: "13%",
                protein: "15%",
                carbs: "72%",
              },
            },
          ],
          dinner: [
            {
              name: "Tempeh Strips with Almond Sauce",
              description:
                "1 serving of tempeh strips with almond sauce contains 292 Calories. The macronutrient breakdown is 17% carbs, 52% fat, and 31% protein. This is a good source of protein (43% of your Daily Value), potassium (12% of your Daily Value), and calcium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "8 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "10g almond butter (plain, without salt added)",
                "10g rice wine vinegar",
                "9g soy sauce (made from soy, tamari)",
                "113g tempeh",
                "1 spray Pam cooking spray (about 1/3 second, or 0.3g)",
              ],
              directions: [
                "Preheat oven or toaster to 350 degrees F. Cover baking pan with aluminum foil and lightly coat with non-stick spray. Set aside.",
                "Combine almond butter, vinegar, and soy sauce in a small bowl, set aside.",
                "Combine tempeh strips on a prepared baking pan. Bake for 8 minutes, turn and bake for 5 more minutes, or until golden.",
                "Serve with almond sauce and enjoy!",
              ],
              nutrition: {
                calories: 292,
                fat: "52%",
                protein: "31%",
                carbs: "17%",
              },
            },
            {
              name: "Simple Avocado and Cranberry Salad",
              description:
                "1 serving of simple avocado and cranberry salad contains 461 Calories. The macronutrient breakdown is 26% carbs, 68% fat, and 6% protein. This is a good source of fiber (62% of your Daily Value), potassium (28% of your Daily Value), and magnesium (25% of your Daily Value).",
              makes: "1 serving",
              prepTime: "8 minutes",
              ingredients: [
                "1 avocado (201g, raw, all commercial varieties)",
                "94g romaine or cos lettuce (raw)",
                "8g sweetened dried cranberries",
                "10 almonds (12g, raw)",
                "30g reduced-fat Italian dressing",
              ],
              directions: [
                "Slice avocado.",
                "Gently toss lettuce with avocado, dried cranberries, and almonds.",
                "Top with dressing and enjoy!",
              ],
              nutrition: {
                calories: 461,
                fat: "68%",
                protein: "6%",
                carbs: "26%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Tofu Scramble and Avocado Toast",
              description:
                "1 serving of tofu scramble and avocado toast contains 489 Calories. The macronutrient breakdown is 29% carbs, 51% fat, and 20% protein. This is a good source of protein (46% of your Daily Value), fiber (53% of your Daily Value), and potassium (33% of your Daily Value).",
              makes: "2 servings",
              prepTime: "15 minutes",
              cookTime: "12 minutes",
              ingredients: [
                "198g silken firm tofu (Mori-nu)",
                "9g red onion (⅛ small, raw)",
                "53g white mushrooms (raw)",
                "117g frozen chopped broccoli (unprepared)",
                "7g olive oil (salad or cooking)",
                "1g dried thyme (spices)",
                "2g garlic powder (spices)",
                "15g Dijon mustard (Grey Poupon)",
                "30g raw spinach",
                "26g multi-grain bread (includes whole-grain, 1 slice)",
                "101g avocado (½ fruit, raw, all commercial varieties)",
                "0.2g salt (½ dash)",
                "0.05g black pepper (½ dash, spices)",
              ],
              directions: [
                "Drain and slice tofu. Place slices between a few paper towels or a clean dish cloth. Press it between your hands or press tofu while it lies on a hard surface.",
                "Set tofu aside and start on prepping your vegetables by chopping onion, mushrooms, and broccoli.",
                "In a skillet or wok, heat oil over medium-high heat. Sauté onions, mushrooms, and broccoli for five minutes.",
                "Add tofu, crumbling it between your fingers. Add the spices and Dijon mustard, mixing to combine. Add spinach and continue to cook over medium-high heat, stirring occasionally until spinach starts to wilt, about 5-7 minutes. Using a lid/cover will help the spinach wilt faster.",
                "Remove skillet/wok from heat. Pair with toast topped with mashed avocado, salt, and pepper.",
              ],
              nutrition: {
                calories: 489,
                fat: "51%",
                protein: "20%",
                carbs: "29%",
              },
            },
          ],
          lunch: [
            {
              name: "Tomato and Hummus Pocket Sandwich",
              description:
                "1 pocket of tomato and hummus pocket sandwich contains 445 Calories. The macronutrient breakdown is 47% carbs, 39% fat, and 14% protein. This is a good source of protein (30% of your Daily Value), fiber (46% of your Daily Value), and potassium (11% of your Daily Value).",
              makes: "4 pockets",
              prepTime: "5 minutes",
              ingredients: [
                "51g red cherry tomatoes (3 cherry tomatoes, raw, ripe, year-round average)",
                "64g whole-wheat pita bread (1 large pita)",
                "123g commercial hummus",
                "8g alfalfa sprouts (sprouted seeds, raw)",
                "7g olive oil (salad or cooking)",
              ],
              directions: [
                "Rinse cherry tomatoes and cut them into halves.",
                "Slice an opening at the top of each pita and spread hummus on the inside of each.",
                "Stuff with alfalfa sprouts and 6 tomato halves.",
                "Drizzle olive oil over the sandwich filling and serve.",
              ],
              nutrition: {
                calories: 445,
                fat: "39%",
                protein: "14%",
                carbs: "47%",
              },
            },
            {
              name: "Mixed Greens with Sliced Cucumber and Avocado",
              description:
                "1 serving of mixed greens with sliced cucumber and avocado contains 160 Calories. The macronutrient breakdown is 32% carbs, 61% fat, and 7% protein. This is a good source of fiber (26% of your Daily Value), potassium (10% of your Daily Value), and vitamin K (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "113g field greens salad mix (Dole)",
                "67g peeled, raw cucumber",
                "68g California avocado (½ fruit, without skin and seed)",
                "15g reduced fat Italian dressing",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
              ],
              directions: [
                "Combine salad greens, cucumber, and avocado.",
                "Toss with low-carb salad dressing of your choice and season to taste with salt and freshly ground black pepper.",
              ],
              nutrition: {
                calories: 160,
                fat: "61%",
                protein: "7%",
                carbs: "32%",
              },
            },
          ],
          snack: [
            {
              name: "Kale Juice",
              description:
                "1 serving of kale juice (Requires juicer) contains 283 Calories. The macronutrient breakdown is 79% carbs, 7% fat, and 14% protein. This is a good source of protein (22% of your Daily Value), fiber (80% of your Daily Value), and potassium (46% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "134g raw kale",
                "1 lemon (without seeds, raw, with peel)",
                "117g raw fennel (½ bulb)",
                "46g fresh spearmint",
                "301g raw cucumber (with peel, 1 cucumber)",
                "182g medium apple (with skin, 1 apple)",
              ],
              directions: [
                "Juice all ingredients, stir to combine, and enjoy!",
              ],
              nutrition: {
                calories: 283,
                fat: "7%",
                protein: "14%",
                carbs: "79%",
              },
            },
          ],
          dinner: [
            {
              name: "Zucchini Peanut Noodles",
              description:
                "1 scoop of zucchini peanut noodles contains 504 Calories. The macronutrient breakdown is 46% carbs, 42% fat, and 11% protein. This is a good source of protein (26% of your Daily Value), fiber (17% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "2 scoops",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "57g dry rice noodles",
                "32g smooth peanut butter (without salt)",
                "7g sesame oil (salad or cooking)",
                "59g plain clean water",
                "162g raw zucchini (½ large)",
                "18g soy sauce (made from soy, tamari)",
                "0.9g cayenne pepper (red or cayenne)",
                "12g young green onions (tops only, 1 stalk)",
              ],
              directions: [
                "Boil water then cook 2 servings of rice noodles and drain.",
                "Chop the onion and cut the zucchini into thin ribbons.",
                "Mix peanut butter and water, then add pepper, soy sauce, and sesame oil. Ginger is also a good addition if you have some on hand.",
                "Toss the noodles with the zucchini and onion, and then pour the desired amount of sauce over and serve.",
              ],
              nutrition: {
                calories: 504,
                fat: "42%",
                protein: "11%",
                carbs: "46%",
              },
            },
            {
              name: "Raw Cauliflower Tabouli",
              description:
                "1 serving of raw cauliflower tabouli contains 219 Calories. The macronutrient breakdown is 22% carbs, 72% fat, and 6% protein. This is a good source of fiber (14% of your Daily Value), potassium (14% of your Daily Value), and vitamin A (14% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "74g raw cauliflower (⅛ head medium)",
                "62g raw tomatoes (½ medium whole)",
                "19g raw parsley",
                "2g fresh mint",
                "30g raw scallions (2 medium)",
                "75g raw cucumber (¼ cucumber, with peel)",
                "0.03g black pepper (¼ dash)",
                "0.8g table salt",
                "11g raw lemon juice",
                "18g olive oil (salad or cooking)",
              ],
              directions: [
                "Separate cauliflower into florets. Dice tomatoes. Chop parsley, mint, and green onions. Finely dice cucumber.",
                "Place the raw cauliflower florets into your food processor and pulse a few times until the consistency of couscous is achieved.",
                "Transfer mixture to a large mixing bowl and combine well with the other ingredients. Adjust seasoning as necessary.",
              ],
              nutrition: {
                calories: 219,
                fat: "72%",
                protein: "6%",
                carbs: "22%",
              },
            },
          ],
        },
      ],
      2700: [
        {
          kalories: "2700",
          cardbs: "68",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Oatmeal with Apple and Banana",
              description:
                "2 servings of oatmeal with apple and banana contains 450 Calories. The macronutrient breakdown is 85% carbs, 5% fat, and 10% protein. This is a good source of fiber (58% of your Daily Value), potassium (18% of your Daily Value), and vitamin B6 (57% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "473g plain, clean water",
                "80g dry rolled oats (Cereals, Quaker)",
                "149g small apple (with skin)",
                "118g medium raw banana",
              ],
              directions: [
                "Boil water. Slice apple and banana.",
                "Add the boiling water to the oatmeal, and let it rest until the oats have soaked up all the water.",
                "Add the apple and banana. Enjoy!",
              ],
              nutrition: {
                calories: 450,
                fat: "5%",
                protein: "10%",
                carbs: "85%",
              },
            },
            {
              name: "Rice Cake with Peanut Butter and Chocolate Chips",
              description:
                "1 serving of rice cake with peanut butter and chocolate chips contains 230 Calories. The macronutrient breakdown is 39% carbs, 52% fat, and 9% protein. This has a relatively high calorie density, with 500 Calories per 100g.",
              makes: "1 serving",
              prepTime: "8 minutes",
              ingredients: [
                "1 rice cake (brown rice, plain, 9g)",
                "16g chunk-style peanut butter (without salt)",
                "21g semisweet chocolate chips",
              ],
              directions: [
                "Spread rice cake with peanut butter.",
                "Top with chocolate chips and enjoy!",
              ],
              nutrition: {
                calories: 230,
                fat: "52%",
                protein: "9%",
                carbs: "39%",
              },
            },
          ],
          lunch: [
            {
              name: "Tomato and Hummus Pocket Sandwich",
              description:
                "1 pocket of tomato and hummus pocket sandwich contains 445 Calories. The macronutrient breakdown is 47% carbs, 39% fat, and 14% protein. This is a good source of protein (30% of your Daily Value), fiber (46% of your Daily Value), and potassium (11% of your Daily Value).",
              makes: "4 pockets",
              prepTime: "5 minutes",
              ingredients: [
                "3 cherry tomatoes (red, ripe, raw, 51g)",
                "1 large whole-wheat pita (64g)",
                "123g commercial hummus",
                "8g alfalfa sprouts (raw)",
                "7g olive oil (salad or cooking)",
              ],
              directions: [
                "Rinse cherry tomatoes and cut into halves.",
                "Slice an opening at the top of each pita and spread hummus on the inside of each.",
                "Stuff with alfalfa sprouts and 6 tomato halves.",
                "Drizzle olive oil over the sandwich filling and serve.",
              ],
              nutrition: {
                calories: 445,
                fat: "39%",
                protein: "14%",
                carbs: "47%",
              },
            },
            {
              name: "Carrots with Hummus",
              description:
                "1 serving contains 175 Calories. The macronutrient breakdown is 48% carbs, 36% fat, and 15% protein. This is a good source of fiber (28% of your Daily Value), potassium (12% of your Daily Value), and vitamin A (113% of your Daily Value).",
              makes: "1 serving",
              prepTime: "1 minute",
              ingredients: ["75g commercial hummus", "122g raw carrots"],
              directions: ["Dip carrots into hummus and eat."],
              nutrition: {
                calories: 175,
                fat: "36%",
                protein: "15%",
                carbs: "48%",
              },
            },
          ],
          snack: [
            {
              name: "White Bean & Artichoke Toast",
              description:
                "1 serving of white bean & artichoke toast contains 246 Calories. The macronutrient breakdown is 62% carbs, 17% fat, and 21% protein. This is a good source of protein (23% of your Daily Value), fiber (32% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              ingredients: [
                "¼ clove garlic (raw, minced, 0.8g)",
                "115g white beans (canned, mature seeds)",
                "3g fresh basil",
                "12g lemon juice (from ¼ lemon)",
                "3g olive oil (salad or cooking)",
                "¼ dash salt (table)",
                "¼ dash black pepper",
                "57g canned artichoke hearts (quarters, without liquid)",
                "15g raw spinach",
                "1 slice whole-wheat bread (commercially prepared, 28g)",
              ],
              directions: [
                "Pulse the garlic and beans and about half of the basil in a food processor until finely minced and crumbly.",
                "Cut the remaining basil into ribbons and chop the artichokes.",
                "Toss the bean mixture with the lemon, basil, olive oil, salt and pepper, and artichoke hearts.",
                "To serve, toast the bread and top with a quarter of the spinach, followed by a quarter of the bean mix. Enjoy!",
              ],
              nutrition: {
                calories: 246,
                fat: "17%",
                protein: "21%",
                carbs: "62%",
              },
            },
          ],
          dinner: [
            {
              name: "Quick Vegetarian Spaghetti",
              description:
                "2 servings of quick vegetarian spaghetti contain 524 Calories. The macronutrient breakdown is 80% carbs, 6% fat, and 14% protein. This is a good source of protein (34% of your Daily Value), fiber (31% of your Daily Value), and potassium (19% of your Daily Value).",
              makes: "8 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "113g dry spaghetti (unenriched)",
                "75g canned sweet corn (yellow, whole kernel, drained solids)",
                "23g raw broccoli",
                "107g canned tomato sauce",
                "32g raw carrots",
                "18g raw white mushrooms",
              ],
              directions: [
                "Drain the kernel corn. Cut the mushrooms and carrots into slices.",
                "Bring a large pot of salted water to boil, add spaghetti, and return water to a boil. Cook until spaghetti is al dente; drain well.",
                "Combine broccoli, corn, mushrooms, carrots, and tomato sauce in a large sauce pot. Cook on medium heat for 15 to 20 minutes or until vegetables are tender. Stir occasionally to keep sauce from sticking.",
                "Serve the sauce over the spaghetti.",
              ],
              nutrition: {
                calories: 524,
                fat: "6%",
                protein: "14%",
                carbs: "80%",
              },
            },
            {
              name: "Pomegranate Salad",
              description:
                "1 serving of pomegranate salad contains 638 Calories. The macronutrient breakdown is 45% carbs, 50% fat, and 5% protein. This is a good source of protein (17% of your Daily Value), fiber (82% of your Daily Value), and potassium (39% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "36g green leaf lettuce (raw)",
                "149g cherry tomatoes (red, ripe, raw)",
                "136g California avocado (1 fruit, without skin and seed)",
                "44g lime juice (raw, from 1 lime)",
                "14g olive oil (salad or cooking)",
                "16g balsamic vinegar",
                "282g raw pomegranate (1 pomegranate)",
              ],
              directions: [
                "Wash the lettuce and tomatoes.",
                "Pit the avocado and chop it.",
                "Chop the tomatoes and shred the lettuce. Place all ingredients in a bowl.",
                "Sprinkle the mixture with lime juice, olive oil, and balsamic vinegar.",
                "Peel the pomegranate and add the seeds to the bowl.",
                "Toss everything to combine.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 638,
                fat: "50%",
                protein: "5%",
                carbs: "45%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "68",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Knock-Oats",
              description:
                "1 bowl of knock-oats contains 451 Calories. The macronutrient breakdown is 52% carbs, 36% fat, and 12% protein. This is a good source of protein (25% of your Daily Value), fiber (35% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "5 minutes",
              ingredients: [
                "40g quick oats (Cereals, Quaker, dry)",
                "1 medium banana (raw, 118g)",
                "32g smooth peanut butter (without salt)",
                "3g ground cinnamon (spices)",
              ],
              directions: [
                "Prepare oatmeal according to package instructions.",
                "While hot, stir in thinly sliced banana, peanut butter, and cinnamon.",
              ],
              nutrition: {
                calories: 451,
                fat: "36%",
                protein: "12%",
                carbs: "52%",
              },
            },
            {
              name: "Spanish-style Toast with Tomato",
              description:
                "1 serving of Spanish-style toast with tomato contains 228 Calories. The macronutrient breakdown is 63% carbs, 24% fat, and 13% protein. This has a moderate calorie density, with 185 Calories per 100g.",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "3 cherry tomatoes (red, ripe, raw, year-round average, 51g)",
                "2 small slices sourdough bread (French or Vienna, 64g)",
                "1 clove garlic (raw, 3g)",
                "5g olive oil (salad or cooking)",
                "1 dash salt (table, 0.4g)",
              ],
              directions: [
                "Wash and halve the cherry tomatoes. Grate them on a box grater into a bowl, taking care to avoid injury.",
                "Toast the bread slices until golden using a toaster, grill, or oven. Rub the cut side of a garlic clove half over each slice.",
                "Spread the grated tomatoes on the toasted bread. Season with a pinch of salt and drizzle ½ teaspoon of olive oil on each slice.",
              ],
              nutrition: {
                calories: 228,
                fat: "24%",
                protein: "13%",
                carbs: "63%",
              },
            },
          ],
          lunch: [
            {
              name: "Blueberry Almond Butter Protein Smoothie",
              description:
                "2 smoothies of blueberry almond butter protein smoothie contain 541 Calories. The macronutrient breakdown is 47% carbs, 30% fat, and 23% protein. This is a good source of protein (60% of your Daily Value), fiber (60% of your Daily Value), and vitamin E (67% of your Daily Value).",
              makes: "1 smoothie",
              prepTime: "5 minutes",
              ingredients: [
                "710g plain, clean water",
                "30g Organic Plain Rice Protein (NutriBiotic)",
                "32g almond butter (nuts, plain, without salt added)",
                "12 ice cubes (frozen water, 266g)",
                "395g raw blueberries",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 541,
                fat: "30%",
                protein: "23%",
                carbs: "47%",
              },
            },
            {
              name: "Tomato Basil Layered Salad",
              description:
                "1 serving of tomato basil layered salad contains 184 Calories. The macronutrient breakdown is 36% carbs, 57% fat, and 7% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin K (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "91g red, ripe, raw tomatoes (½ large whole)",
                "68g California avocado (½ fruit, without skin and seed)",
                "4g fresh basil (8 leaves, whole)",
                "15g commercial hummus",
                "32g balsamic vinegar",
              ],
              directions: [
                "Slice tomato into ¼-inch thick slices.",
                "Slice avocado into ¼-inch thick slices.",
                "Tear off, rinse, and dry basil.",
                "Layer tomato, basil, and avocado to make a striped presentation.",
                "Add hummus and vinegar to a food processor and pulse until smooth.",
                "Pour over salad just before serving. Enjoy!",
              ],
              nutrition: {
                calories: 184,
                fat: "57%",
                protein: "7%",
                carbs: "36%",
              },
            },
          ],
          snack: [
            {
              name: "Waldorf-ish Salad",
              description:
                "1 serving of waldorf-ish salad contains 466 Calories. The macronutrient breakdown is 58% carbs, 35% fat, and 7% protein. This is a good source of protein (16% of your Daily Value), fiber (53% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "29g English walnuts",
                "115g raw pear (½ large)",
                "113g mixed baby greens (Kale, Chard, Spinach - Trader Joe's)",
                "18g Zante currants (dried)",
                "22g rice wine vinegar",
                "25g raw celery",
                "223g large apple (with skin)",
              ],
              directions: [
                "Crush 1/4 cup of walnuts with the back of a wooden spoon.",
                "Peel and grate the pear.",
                "Combine the pear with the baby greens, currants, vinegar, and diced celery (or arugula, etc.) in a bowl.",
                "Top with remaining walnut halves and the diced apples (or additional pears).",
                "Garnish salad with additional currants. Enjoy!",
              ],
              nutrition: {
                calories: 466,
                fat: "35%",
                protein: "7%",
                carbs: "58%",
              },
              inspiredBy: "Dr. Fuhrman 3 Step Recipes",
            },
          ],
          dinner: [
            {
              name: "Chinese Eggplants with Garlic Sauce",
              description:
                "2 servings of Chinese eggplants with garlic sauce contains 759 Calories. The macronutrient breakdown is 37% carbs, 57% fat, and 7% protein. This is a good source of fiber (123% of your Daily Value), potassium (59% of your Daily Value), and magnesium (40% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "1.1kg raw eggplant",
                "0.4g table salt (1 dash)",
                "49g canola oil",
                "12g minced raw garlic (4 cloves)",
                "74g sweet green bell pepper (1 small)",
                "18g soy sauce (made from hydrolyzed vegetable protein)",
                "2g white granulated sugar",
                "6g table salt",
              ],
              directions: [
                "Cut the eggplants into one bite-size pieces.",
                "In a large pot, bring water to a boil, add a pinch of salt, and cook the eggplants for 10 minutes. Transfer out and drain slightly.",
                "Meanwhile, heat oil in a wok or large skillet over medium heat. Add the eggplants, stirring occasionally, and cook until softened. Remove the eggplants and set aside.",
                "In the same pan, add garlic with the remaining oil and heat over low heat until fragrant.",
                "Add diced green pepper, return the eggplants, and add the remaining ingredients.",
                "Once the green pepper is softened, divide between plates and serve.",
              ],
              nutrition: {
                calories: 759,
                fat: "57%",
                protein: "7%",
                carbs: "37%",
              },
            },
            {
              name: "Fried Broccoli",
              description:
                "1 serving of fried broccoli contains 60 Calories. The macronutrient breakdown is 32% carbs, 49% fat, and 19% protein. This is a good source of vitamin C (71% of your Daily Value) and vitamin K (78% of your Daily Value).",
              makes: "4 servings",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "113g frozen chopped broccoli",
                "3g olive oil (salad or cooking)",
                "0.04g crushed red pepper flakes",
                "2g table salt",
              ],
              directions: [
                "Rinse and pat dry the broccoli.",
                "Heat the olive oil in a large skillet over medium heat, add the crushed red pepper, and heat for 1 minute.",
                "Add the broccoli to the skillet and cook, stirring until it begins to get crispy, about 5 to 7 minutes.",
                "Season with salt before serving.",
              ],
              nutrition: {
                calories: 60,
                fat: "49%",
                protein: "19%",
                carbs: "32%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "68",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Classic Avocado Toast",
              description:
                "2 slices of classic avocado toast contain 468 Calories. The macronutrient breakdown is 34% carbs, 57% fat, and 9% protein. This is a good source of fiber (60% of your Daily Value), potassium (24% of your Daily Value), and vitamin B6 (50% of your Daily Value).",
              makes: "2 slices",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "201g raw avocado (1 fruit)",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "5g ready-to-serve hot sauce or chili sauce",
                "0.3g crushed red pepper flakes",
                "56g whole-wheat bread (2 slices)",
              ],
              directions: [
                "Mash avocado, salt, and pepper together. Add hot sauce or chili flakes for extra heat.",
                "Toast the bread.",
                "Top the bread with the avocado mash and enjoy!",
              ],
              nutrition: {
                calories: 468,
                fat: "57%",
                protein: "9%",
                carbs: "34%",
              },
            },
            {
              name: "Pomegranate, Blueberry, and Spinach Smoothie",
              description:
                "1 serving of pomegranate, blueberry, and spinach smoothie contains 353 Calories. The macronutrient breakdown is 77% carbs, 16% fat, and 7% protein. This is a good source of fiber (47% of your Daily Value), potassium (27% of your Daily Value), and calcium (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "155g frozen unsweetened blueberries",
                "60g raw spinach",
                "120g plain almond milk",
                "125g bottled pomegranate juice",
                "12g dried chia seeds",
                "118g raw banana (1 medium)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 353,
                fat: "16%",
                protein: "7%",
                carbs: "77%",
              },
            },
          ],
          lunch: [
            {
              name: "Vegan Mexican Tortilla Roll Ups",
              description:
                "1 serving of vegan Mexican tortilla roll ups contains 405 Calories. The macronutrient breakdown is 64% carbs, 22% fat, and 14% protein. This is a good source of protein (26% of your Daily Value), fiber (42% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "5 servings",
              prepTime: "10 minutes",
              ingredients: [
                "1 medium flour tortilla (46g)",
                "74g home-prepared hummus",
                "85g low sodium canned black beans, drained",
                "24g raw red bell pepper (³⁄₁₆ medium)",
                "51g raw sweet yellow corn",
                "33g raw sweet onion (⅛ onion)",
                "0.8g fresh cilantro (coriander leaves, raw)",
              ],
              directions: [
                "Drain and rinse beans and corn. Chop veggies and cilantro.",
                "Slather the tortillas with hummus, getting it all the way to the edges.",
                "Distribute all toppings amongst tortillas and roll tortillas up tightly.",
                "Gently slice into bite-sized pieces and serve.",
              ],
              nutrition: {
                calories: 405,
                fat: "22%",
                protein: "14%",
                carbs: "64%",
              },
            },
            {
              name: "Basic Tossed Salad",
              description:
                "1 serving of basic tossed salad contains 213 Calories. The macronutrient breakdown is 34% carbs, 57% fat, and 9% protein. This is a good source of fiber (30% of your Daily Value), potassium (24% of your Daily Value), and iron (45% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "½ head romaine or cos lettuce (313g)",
                "½ medium red ripe tomato (62g)",
                "¼ cucumber with peel (75g)",
                "⅛ medium onion (14g)",
                "¼ medium carrot (15g)",
                "14g olive oil (salad or cooking)",
                "11g red wine vinegar",
                "1g white granulated sugar",
                "0.4g table salt",
                "0.1g black pepper",
              ],
              directions: [
                "Slice tomatoes, cucumber, onions, and carrots. Set aside.",
                "Place salad greens in a salad bowl and top with tomatoes, cucumbers, onion, and carrot.",
                "Place oil, vinegar, and sugar in a small plastic container. Put the lid on the container and shake the dressing until sugar dissolves, about 1 minute.",
                "Pour dressing over salad. Season salad with salt and pepper, to taste, and toss to combine.",
                "Serve immediately and enjoy!",
              ],
              nutrition: {
                calories: 213,
                fat: "57%",
                protein: "9%",
                carbs: "34%",
              },
            },
          ],
          snack: [
            {
              name: "Waldorf-ish Salad",
              description:
                "1 serving of waldorf-ish salad contains 466 Calories. The macronutrient breakdown is 58% carbs, 35% fat, and 7% protein. This is a good source of protein (16% of your Daily Value), fiber (53% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "29g English walnuts",
                "½ large raw pear (115g)",
                "113g mixed baby greens (Kale, Chard, Spinach - Trader Joe's)",
                "18g dried Zante currants",
                "22g rice wine vinegar",
                "25g raw celery",
                "1 large apple with skin (223g)",
              ],
              directions: [
                "Crush 1/4 cup of walnuts with the back of a wooden spoon.",
                "Peel and grate the pear.",
                "Combine these with the baby greens, currants, vinegar, and diced celery (or arugula, etc.) in a bowl.",
                "Top with remaining walnut halves and the diced apples (or additional pears).",
                "Garnish salad with additional currants. Enjoy!",
              ],
              nutrition: {
                calories: 466,
                fat: "35%",
                protein: "7%",
                carbs: "58%",
              },
            },
          ],
          dinner: [
            {
              name: "Rice and Beans",
              description:
                "1 bowl of rice and beans contains 485 Calories. The macronutrient breakdown is 58% carbs, 28% fat, and 14% protein. This is a good source of protein (31% of your Daily Value), fiber (54% of your Daily Value), and potassium (14% of your Daily Value).",
              makes: "1 bowl",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "46g long-grain brown rice (raw)",
                "130g canned black beans (low sodium, drained)",
                "80g green peas (frozen, cooked, boiled, drained, without salt)",
                "14g olive oil (salad or cooking)",
                "5g balsamic vinegar",
                "16g low sodium soy sauce (Shoyu)",
              ],
              directions: [
                "Cook rice according to package directions.",
                "Heat beans in a pan over medium heat. Add peas and cook until heated through.",
                "Season with oil, soy sauce, and vinegar. Add to the rice and mix well.",
              ],
              nutrition: {
                calories: 485,
                fat: "28%",
                protein: "14%",
                carbs: "58%",
              },
            },
            {
              name: "Green Pea & Almond Salad",
              description:
                "1 serving of green pea & almond salad contains 317 Calories. The macronutrient breakdown is 30% carbs, 54% fat, and 17% protein. This is a good source of protein (25% of your Daily Value), fiber (34% of your Daily Value), and potassium (12% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "134g frozen green peas (unprepared)",
                "1g lemon juice (raw)",
                "30g raw spinach",
                "28g blanched almonds",
                "5g olive oil (salad or cooking)",
              ],
              directions: [
                "Pop the kettle on to boil. Place peas in a heatproof bowl.",
                "Cover with boiling water and stand for 2-3 minutes or until peas are defrosted and bright green.",
                "Drain peas and pat dry with a paper towel. Drizzle with a little extra virgin olive oil. Squeeze over lemon. Season with salt and pepper if needed.",
                "Toss peas with spinach and almonds. Serve.",
              ],
              nutrition: {
                calories: 317,
                fat: "54%",
                protein: "17%",
                carbs: "30%",
              },
            },
          ],
        },
      ],
    },
    vegetarian: {
      1200: [
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Yogurt with Avocado & Basil",
              description:
                "1 serving of yogurt with avocado & basil contains 295 Calories. The macronutrient breakdown is 22% carbs, 46% fat, and 33% protein. This is a good source of protein (45% of your Daily Value), fiber (24% of your Daily Value), and potassium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              ingredients: [
                "227g nonfat plain Greek yogurt",
                "½ avocado (101g)",
                "5 fresh basil leaves (3g)",
              ],
              directions: ["Top yogurt with chopped basil and avocado. Enjoy!"],
              nutrition: {
                calories: 295,
                fat: "46%",
                protein: "33%",
                carbs: "22%",
              },
            },
          ],
          lunch: [
            {
              name: "Post-Workout Banana Protein Smoothie",
              description:
                "1 serving of post-workout banana protein smoothie contains 225 Calories. The macronutrient breakdown is 51% carbs, 5% fat, and 43% protein. This is a good source of protein (45% of your Daily Value), potassium (9% of your Daily Value), and vitamin B6 (33% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "473g water",
                "1 medium banana (118g)",
                "1 scoop (30g) whey protein powder",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth. Enjoy!",
              ],
              nutrition: {
                calories: 225,
                fat: "5%",
                protein: "43%",
                carbs: "51%",
              },
            },
            {
              name: "Green Kale Salad",
              description:
                "1 serving of green kale salad contains 76 Calories. The macronutrient breakdown is 55% carbs, 24% fat, and 21% protein. This is a good source of fiber (16% of your Daily Value), potassium (11% of your Daily Value), and vitamin A (29% of your Daily Value).",
              makes: "6 servings",
              prepTime: "15 minutes",
              ingredients: [
                "45g kale",
                "2g olive oil",
                "0.5g garlic, minced (³⁄₁₆ cloves)",
                "0.8g salt",
                "0.4g black pepper",
                "21g celery (⅓ large stalk)",
                "27g green bell pepper",
                "16g zucchini (¹⁄₁₆ medium)",
                "25g cucumber (¹⁄₁₆ cucumber)",
                "15g broccoli",
                "22g peas (green, frozen)",
                "6g alfalfa sprouts",
              ],
              directions: [
                "Microwave peas until just defrosted and set aside. Chop and prep other vegetables.",
                "Add kale, olive oil, garlic, salt, and black pepper to a large bowl. Mix well so that kale is fully coated.",
                "Add remaining ingredients to the bowl and mix. Serve immediately.",
              ],
              nutrition: {
                calories: 76,
                fat: "24%",
                protein: "21%",
                carbs: "55%",
              },
            },
          ],
          snack: [
            {
              name: "Quick & Easy Vegan Caesar Salad",
              description:
                "1 serving of quick & easy vegan caesar salad contains 145 Calories. The macronutrient breakdown is 25% carbs, 60% fat, and 15% protein. This is a good source of fiber (21% of your Daily Value), potassium (14% of your Daily Value), and magnesium (24% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "34g avocado, California, raw (¼ fruit, without skin and seed)",
                "11g lemon juice, raw",
                "7g water",
                "2g garlic, minced (¾ cloves)",
                "2g capers, canned",
                "3g Dijon mustard (Grey Poupon)",
                "0.1g salt (¼ dash)",
                "10g hemp seeds, hulled",
                "141g romaine lettuce",
              ],
              directions: [
                "Add avocado, lemon juice, water, garlic, capers, mustard, salt, and pepper to a food processor or blender. Blend until smooth. Add a bit more water if it needs to be thinned out.",
                "Spoon dressing into a bowl and stir in hemp seeds.",
                "Place chopped romaine in a large salad bowl, add dressing, and stir until combined.",
              ],
              nutrition: {
                calories: 145,
                fat: "60%",
                protein: "15%",
                carbs: "25%",
              },
            },
          ],
          dinner: [
            {
              name: "Seared Scallions with Poached Eggs",
              description:
                "1 serving of seared scallions with poached eggs contains 267 Calories. The macronutrient breakdown is 6% carbs, 83% fat, and 11% protein. This is a good source of vitamin A (12% of your Daily Value), vitamin E (25% of your Daily Value), and vitamin K (97% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "20g olive oil, salad or cooking",
                "1 large egg (50g)",
                "0.05g black pepper (½ dash)",
                "50g scallions, raw",
                "3g lemon juice, raw",
                "0.2g salt (½ dash)",
              ],
              directions: [
                "Mince 1 whole scallion, transfer to a bowl, and whisk in 2 tablespoons oil and lemon juice. Season to taste with salt and pepper and set scallion sauce aside.",
                "Put the remaining scallions on a plate, drizzle with remaining oil, and toss to coat. Season to taste with salt and pepper.",
                "Heat a large cast-iron grill pan or skillet over medium-high heat. Cook scallions, turning occasionally, until tender and slightly charred, about 5 minutes. Divide scallions between plates.",
                "Pour 1/2 cup water into each of two 8-ounce microwave-safe coffee cups. Crack 1 egg into each cup, making sure it's completely submerged. To lower risk of the yolk exploding, gently prick it with a wooden toothpick or tip of a knife.",
                "Cover each mug with a saucer. Microwave 1 egg on high until white is set but yolk is runny, about 1 minute (cooking time will vary depending on microwave). Uncover; using a slotted spoon, transfer egg to top of 1 serving of scallions. Dress with half of the scallion sauce. Repeat with remaining egg and sauce; serve.",
              ],
              nutrition: {
                calories: 267,
                fat: "83%",
                protein: "11%",
                carbs: "6%",
              },
            },
            {
              name: "Heirloom Tomato Basil Mozzarella Salad",
              description:
                "1 serving of heirloom tomato basil mozzarella salad contains 126 Calories. The macronutrient breakdown is 15% carbs, 71% fat, and 13% protein. This is a good source of vitamin K (50% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "8g balsamic vinegar",
                "7g olive oil, salad or cooking",
                "14g mozzarella cheese, whole milk (½ slice)",
                "75g tomatoes, red, ripe, raw (3 ¾ medium slices)",
                "0.05g black pepper (½ dash)",
                "12g fresh basil",
                "0.2g salt (½ dash)",
              ],
              directions: [
                "Assemble the salad with slices of tomatoes, basil leaves, and mozzarella slices.",
                "Sprinkle extra virgin olive oil over the salad.",
                "Add a dash of vinegar and a very light sprinkling of salt and pepper.",
                "For a different flavor, you can substitute the mozzarella with thin slices of Parmesan.",
              ],
              nutrition: {
                calories: 126,
                fat: "71%",
                protein: "13%",
                carbs: "15%",
              },
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Scrambled Eggs and Broccoli",
              description:
                "1 serving of scrambled eggs and broccoli contains 88 Calories. The macronutrient breakdown is 15% carbs, 51% fat, and 34% protein. This is a good source of vitamin C (45% of your Daily Value) and vitamin K (39% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "1 large egg (50g)",
                "46g broccoli, raw",
                "0.1g Pam cooking spray, oil, original (½ spray, about 1/3 second)",
              ],
              directions: [
                "Beat eggs and stir in broccoli.",
                "Heat a non-stick pan over medium heat and coat with cooking spray.",
                "Pour in broccoli/egg mixture and scramble until eggs have reached desired doneness and broccoli is tender.",
                "Serve immediately and enjoy!",
              ],
              nutrition: {
                calories: 88,
                fat: "51%",
                protein: "34%",
                carbs: "15%",
              },
            },
            {
              name: "Plum and Greek Yogurt Snack",
              description:
                "1 serving of plum and Greek yogurt snack contains 185 Calories. The macronutrient breakdown is 26% carbs, 32% fat, and 42% protein. This is a good source of protein (36% of your Daily Value), potassium (8% of your Daily Value), and calcium (22% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "½ plum, raw (33g)",
                "170g nonfat Greek yogurt, plain",
                "10 almonds, raw (12g)",
              ],
              directions: [
                "Pit plum and dice into bite-size pieces.",
                "Layer fruit and Greek yogurt in a bowl or mug.",
                "Sprinkle with sliced almonds and enjoy!",
              ],
              nutrition: {
                calories: 185,
                fat: "32%",
                protein: "42%",
                carbs: "26%",
              },
            },
          ],
          lunch: [
            {
              name: "Vanilla Banana Protein Shake",
              description:
                "1 serving of vanilla banana protein shake contains 245 Calories. The macronutrient breakdown is 43% carbs, 14% fat, and 44% protein. This is a good source of protein (46% of your Daily Value), potassium (8% of your Daily Value), and calcium (30% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "½ medium banana, raw (59g)",
                "240g almond milk, plain",
                "4g vanilla extract",
                "1 scoop (30g) whey protein powder",
                "119g ice cubes",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 245,
                fat: "14%",
                protein: "44%",
                carbs: "43%",
              },
            },
          ],
          snack: [
            {
              name: "Tomato Basil Layered Salad",
              description:
                "1 serving of tomato basil layered salad contains 184 Calories. The macronutrient breakdown is 36% carbs, 57% fat, and 7% protein. This is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin K (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "½ large whole tomato, red, ripe (91g)",
                "½ avocado, without skin and seed (68g)",
                "8 fresh basil leaves (4g)",
                "15g hummus",
                "32g balsamic vinegar",
              ],
              directions: [
                "Slice tomato into ¼-inch thick slices.",
                "Slice avocado into ¼-inch thick slices.",
                "Tear off, rinse, and dry basil.",
                "Layer tomato, basil, and avocado for a striped presentation.",
                "Add hummus and vinegar to a food processor and pulse until smooth.",
                "Pour dressing over salad just before serving. Enjoy!",
              ],
              nutrition: {
                calories: 184,
                fat: "57%",
                carbs: "36%",
                protein: "7%",
              },
            },
          ],
          dinner: [
            {
              name: "Acini di Pepe Pasta with Garlic and Olives",
              description:
                "1 serving of acini di pepe pasta with garlic and olives contains 179 Calories. The macronutrient breakdown is 49% carbs, 41% fat, and 10% protein. This has a relatively high calorie density, with 308 Calories per 100g.",
              makes: "6 servings",
              prepTime: "10 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "5g unsalted butter",
                "11g ripe, canned olives (small-extra large)",
                "38g fresh-refrigerated plain pasta",
                "0.01g crushed red pepper flakes",
                "2g olive oil (salad or cooking)",
                "⅔ clove raw garlic, minced (2g)",
              ],
              directions: [
                "Mince garlic. Pit and quarter olives.",
                "Cook acini di pepe in a pasta pot of boiling salted water (3 Tbsp salt for 6 qt water), stirring occasionally, until al dente.",
                "Drain well in a sieve. Transfer to a bowl.",
                "Meanwhile, heat oil and butter in a 10-inch heavy skillet over medium-high heat until foam subsides.",
                "Sauté garlic with red-pepper flakes until golden, about 2 minutes. Stir in olives.",
                "Toss with pasta. Season with salt and pepper.",
              ],
              nutrition: {
                calories: 179,
                fat: "41%",
                carbs: "49%",
                protein: "10%",
              },
            },
            {
              name: "Peppers Cucumber & Avocado Salad",
              description:
                "1 serving of peppers cucumber & avocado salad contains 275 Calories. The macronutrient breakdown is 30% carbs, 64% fat, and 7% protein. This is a good source of fiber (43% of your Daily Value), potassium (26% of your Daily Value), and vitamin A (28% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "36g green leaf lettuce, raw",
                "8 cherry tomatoes, red, ripe, raw (about 136g)",
                "26g cucumber, with peel, raw",
                "136g California avocado, without skin and seed (1 fruit)",
                "30g red bell pepper, sweet, raw (about 3 rings)",
                "23g raw lemon juice",
              ],
              directions: [
                "Chop all ingredients and toss together well.",
                "Drizzle with lemon juice and enjoy!",
              ],
              nutrition: {
                calories: 275,
                fat: "64%",
                carbs: "30%",
                protein: "7%",
              },
            },
          ],
        },
        {
          kalories: "1200",
          cardbs: "30",
          fat: "27",
          protein: "30",
          breakfast: [
            {
              name: "Low Carb Pancakes",
              description:
                "1 serving of low carb pancakes contains 182 Calories. The macronutrient breakdown is 4% carbs, 53% fat, and 43% protein. This is a good source of protein (34% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "6 minutes",
              ingredients: [
                "15g whey protein powder, Gold Standard, any flavor, 100% whey protein (½ scoop)",
                "0.4g table salt (1 dash)",
                "56g whole, fresh extra large egg (1)",
                "5g olive oil, salad or cooking",
                "30g plain, clean water",
                "0.3g Pam cooking spray, original (1 spray, about 1/3 second)",
              ],
              directions: [
                "Whisk all of the ingredients, except for the water and cooking spray, in a small bowl until smooth.",
                "Meanwhile, lightly grease a small nonstick skillet with oil or cooking spray and heat on medium.",
                "Add up to 1 tablespoon of water if you'd like a thinner batter.",
                "Pour in the batter and tilt the pan to spread the batter evenly over the bottom of the skillet.",
                "When bubbles appear all over the pancake and the batter on top looks like it's starting to set up, carefully work a spatula around the edges and then under the pancake to flip it over.",
                "Cook until the second side is lightly browned. Don't let it get too dark or it will taste a little bitter.",
              ],
              nutrition: {
                calories: 182,
                fat: "53%",
                carbs: "4%",
                protein: "43%",
              },
            },
          ],
          lunch: [
            {
              name: "Red Pepper & Tomato Salad",
              description:
                "1 serving of red pepper & tomato salad contains 230 Calories. The macronutrient breakdown is 38% carbs, 55% fat, and 6% protein. This is a good source of fiber (20% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "119g sweet, raw red bell pepper (1 medium)",
                "14g olive oil, salad or cooking",
                "32g balsamic vinegar",
                "246g red, ripe, raw tomatoes (2 medium whole)",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "0.3g dried oregano",
              ],
              directions: [
                "Coarsely chop red pepper and tomatoes. Place into a bowl.",
                "Pour in olive oil and balsamic vinegar. Season with salt, pepper, and oregano.",
                "Toss gently to coat.",
                "Refrigerate until ready to serve. Enjoy!",
              ],
              nutrition: {
                calories: 230,
                fat: "55%",
                carbs: "38%",
                protein: "6%",
              },
            },
            {
              name: "Bell Pepper and Hummus Snack",
              description:
                "1 serving of bell pepper and hummus snack contains 101 Calories. The macronutrient breakdown is 55% carbs, 29% fat, and 16% protein. This is a good source of fiber (19% of your Daily Value), potassium (9% of your Daily Value), and vitamin A (29% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                '164g sweet, raw red bell pepper (1 large, approx 3-3/4" long)',
                "30g commercial hummus",
              ],
              directions: [
                "Slice the bell pepper.",
                "Serve with 2 tablespoons of hummus.",
              ],
              nutrition: {
                calories: 101,
                fat: "29%",
                carbs: "55%",
                protein: "16%",
              },
            },
          ],
          snack: [
            {
              name: "Quick & Easy Vegan Caesar Salad",
              description:
                "1 serving of quick & easy vegan caesar salad contains 145 Calories. The macronutrient breakdown is 25% carbs, 60% fat, and 15% protein. This is a good source of fiber (21% of your Daily Value), potassium (14% of your Daily Value), and magnesium (24% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              ingredients: [
                "34g California avocado (¼ fruit, without skin and seed)",
                "11g raw lemon juice",
                "7g plain, clean water",
                "2g raw garlic (¾ clove, minced)",
                "2g canned capers",
                "3g Grey Poupon Dijon mustard",
                "0.1g table salt (¼ dash)",
                "10g hulled hemp seeds",
                "141g raw romaine or cos lettuce",
              ],
              directions: [
                "Add avocado, lemon juice, water, garlic, capers, mustard, salt, and pepper to a food processor or blender. Blend until smooth. Add a bit more water if it needs to be thinned out.",
                "Spoon dressing into a bowl and stir in hemp seeds.",
                "Place chopped romaine in a large salad bowl, and add dressing. Stir until combined.",
              ],
              nutrition: {
                calories: 145,
                fat: "60%",
                carbs: "25%",
                protein: "15%",
              },
            },
          ],
          dinner: [
            {
              name: "Refried Bean Burrito",
              description:
                "1 serving of refried bean burrito contains 194 Calories. The macronutrient breakdown is 60% carbs, 29% fat, and 12% protein. This is a good source of fiber (27% of your Daily Value), potassium (12% of your Daily Value), and vitamin A (27% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "61g canned vegetarian refried beans",
                "1 small corn tortilla (26g), ready-to-bake or -fry, without added salt",
                "56g raw romaine or cos lettuce (2 outer leaves)",
                "34g California avocado (¼ fruit, without skin and seed)",
                "64g Pace pico de gallo",
              ],
              directions: [
                "Stir refried beans in a saucepan over medium-low heat until hot, about 2 to 4 minutes.",
                "Warm the tortilla in a skillet over low heat until softened, about 1 to 2 minutes. Transfer the tortilla to a plate.",
                "Layer lettuce onto the tortilla. Spread warmed refried beans, avocado slices, and pico de gallo on top of the lettuce.",
                "Roll the tortilla around the fillings into a burrito shape. Enjoy!",
              ],
              nutrition: {
                calories: 194,
                fat: "29%",
                carbs: "60%",
                protein: "12%",
              },
            },
            {
              name: "Tofu Salad",
              description:
                "1 serving of tofu salad contains 207 Calories. The macronutrient breakdown is 18% carbs, 68% fat, and 14% protein. This is a good source of potassium (12% of your Daily Value), vitamin A (49% of your Daily Value), and vitamin K (92% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "94g raw romaine or cos lettuce",
                "75g red ripe cherry tomatoes",
                "84g silken firm tofu (1 slice, Mori-nu soy)",
                "14g olive oil (salad or cooking)",
                "17g raw red onion (¼ small)",
              ],
              directions: ["Gently mix the ingredients in a bowl.", "Enjoy!"],
              nutrition: {
                calories: 207,
                fat: "68%",
                carbs: "18%",
                protein: "14%",
              },
            },
          ],
        },
      ],
      1700: [
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Avocado & Kale Omelet",
              description:
                "High-protein omelet with avocado and fiber-rich kale. 1 serving contains 336 Calories. The macronutrient breakdown is 14% carbs, 67% fat, and 19% protein. This is a good source of protein (30% of your Daily Value), fiber (18% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "2 large whole fresh eggs (100g)",
                "5g reduced fat 2% milk",
                "0.03g table salt (¹⁄₁₆ dash)",
                "9g olive oil (salad or cooking)",
                "67g raw kale",
                "15g raw lime juice",
                "1g fresh cilantro (coriander leaves)",
                "3g dry roasted sunflower seed kernels (without salt)",
                "0.02g crushed red pepper flakes",
                "0.4g table salt (1 dash)",
                "34g California avocado (¼ fruit, without skin and seed)",
              ],
              directions: [
                "Beat eggs with milk and salt in a bowl. Heat 1 teaspoon oil in a small nonstick skillet over medium heat.",
                "Add the egg mixture and cook until the bottom is set and the center is still a bit runny, 1 to 2 minutes.",
                "Flip the omelet over and cook until set, about 30 seconds more. Transfer to a plate.",
                "Toss kale with the remaining 1 teaspoon oil, lime juice, cilantro, sunflower seeds, crushed red pepper, and a pinch of salt. Top the omelet with the kale salad and avocado.",
              ],
              nutrition: {
                calories: 336,
                fat: "67%",
                carbs: "14%",
                protein: "19%",
              },
            },
            {
              name: "Cinnamon Toast",
              description:
                "A delicious sweet treat, cinnamon toast contains 130 Calories per slice. The macronutrient breakdown is 57% carbs, 33% fat, and 11% protein. This has a relatively high calorie density, with 329 Calories per 100g.",
              makes: "2 slices",
              prepTime: "5 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "1 slice whole-wheat bread (28g)",
                "5g unsalted butter",
                "6g white granulated sugar",
                "0.6g ground cinnamon",
              ],
              directions: [
                "Use a toaster to toast the bread to desired darkness.",
                "Spread butter or margarine onto one side of each slice.",
                "In a cup or small bowl, stir together the sugar and cinnamon; sprinkle generously over hot buttered toast.",
              ],
              nutrition: {
                caloriesPerSlice: 130,
                fat: "33%",
                carbs: "57%",
                protein: "11%",
              },
            },
          ],
          lunch: [
            {
              name: "Quick Nori Roll with Cucumber and Avocado",
              description:
                "This nori roll contains 232 Calories per serving, with a macronutrient breakdown of 21% carbs, 54% fat, and 26% protein. It is a good source of protein (28% of your Daily Value), fiber (17% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "1 sheet nori (3g)",
                "52g cucumber (with peel, raw)",
                "¼ avocado (50g)",
                "2 slices silken firm tofu (168g)",
                "8g alfalfa sprouts (raw)",
                "16g soy sauce (regular sodium, Shoyu)",
                "5g sesame seeds (whole, roasted and toasted)",
              ],
              directions: [
                "Thinly slice cucumber into slices or matchsticks, avocado into thin wedges, and tofu into thin strips.",
                "Place a sheet of nori on a clean and dry cutting board, shiny side down and longest edge facing you.",
                "Starting from the left edge, arrange the cucumber slices in overlapping rows on the nori, leaving a 1-inch margin of uncovered nori at the right edge. Sprinkle with sesame seeds.",
                "Arrange avocado, tofu, and sprouts in an even, vertical pattern, 2 inches from the left edge.",
                "Rotate the cutting board by a quarter of a turn counter-clockwise so the uncovered strip of nori is furthest from you.",
                "Using both hands, start rolling the sheet of nori from the edge closest to you, folding it up and over the filling, then rolling it tightly away from you.",
                "Just as you're about to reach the uncovered strip of nori at the end, dip your fingertips in water and dab the nori lightly so it will stick and create a seal.",
                "Slice into halves or thick slices using a sharp chef's knife. Serve with soy sauce for dipping.",
              ],
              nutrition: {
                caloriesPerServing: 232,
                fat: "54%",
                carbs: "21%",
                protein: "26%",
              },
            },
            {
              name: "Yogurt & Applesauce",
              description:
                "A simple and nutritious mix containing 185 Calories per serving, with a macronutrient breakdown of 46% carbs, 5% fat, and 49% protein. This dish is a good source of protein (42% of your Daily Value), potassium (9% of your Daily Value), and calcium (25% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              ingredients: [
                "122g unsweetened applesauce (without added ascorbic acid, canned)",
                "227g nonfat plain Greek yogurt",
              ],
              directions: ["Mix together and enjoy!"],
              nutrition: {
                caloriesPerServing: 185,
                fat: "5%",
                carbs: "46%",
                protein: "49%",
              },
            },
          ],
          snack: [
            {
              name: "Creamy Chocolate Protein Smoothie",
              description:
                "A delicious and nutritious smoothie containing 208 Calories per serving, with a macronutrient breakdown of 29% carbs, 20% fat, and 50% protein. This smoothie is a good source of protein (46% of your Daily Value), calcium (23% of your Daily Value), and iron (56% of your Daily Value).",
              makes: "1 serving",
              prepTime: "8 minutes",
              ingredients: [
                "60g plain almond milk",
                "113g lowfat cottage cheese (2% milkfat)",
                "10g unsweetened cocoa powder (Hershey's European style)",
                "15g (½ scoop) whey protein powder (Gold Standard, any flavor)",
                "50g (½ teaspoon) no-calorie sweetener (Splenda)",
                "237g ice cubes (frozen water)",
              ],
              directions: [
                "Add the almond milk, cottage cheese, and cocoa powder to the blender first and pulse.",
                "Once well blended, add the rest of the ingredients to your blender.",
                "Taste and adjust sweetener if needed. Enjoy immediately!",
              ],
              nutrition: {
                caloriesPerServing: 208,
                fat: "20%",
                carbs: "29%",
                protein: "50%",
              },
            },
          ],
          dinner: [
            {
              name: "Avocado Quesadillas",
              description:
                "Delicious quesadillas made with creamy avocado and fresh vegetables, containing 332 Calories per serving. The macronutrient breakdown is 44% carbs, 46% fat, and 10% protein. This recipe is a good source of protein (15% of your Daily Value), fiber (36% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "101g raw avocado (½ fruit)",
                "62g Italian tomato (1 tomato)",
                "12g young green onion (1 stalk)",
                "5g nutritional yeast (Vegan - Whole Foods Market)",
                "0.4g salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "16g salsa (ready-to-serve)",
                "46g medium flour tortilla (1 tortilla)",
              ],
              directions: [
                "Smash avocado and spread it out on one half of the tortilla.",
                "Sprinkle with nutritional yeast and any seasonings.",
                "Add chopped tomato, green onions, and salsa.",
                "Fold over the top half of the tortilla and place on a skillet over medium heat.",
                "Heat for a couple of minutes on each side until warmed through. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 332,
                fat: "46%",
                carbs: "44%",
                protein: "10%",
              },
            },
            {
              name: "Chopped Radish and Avocado Salad",
              description:
                "A refreshing salad made with radishes and creamy avocado, containing 272 Calories per serving. The macronutrient breakdown is 20% carbs, 76% fat, and 4% protein. This recipe is a good source of fiber (24% of your Daily Value), potassium (14% of your Daily Value), and vitamin B6 (27% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              ingredients: [
                "68g California avocado (½ fruit)",
                "11g radishes (2 ½ medium)",
                "18g raw onion (¼ small)",
                "140g peeled cucumber (½ large)",
                "24g lemon juice (½ lemon yields)",
                "14g olive oil (salad or cooking)",
                "2g minced garlic (½ clove)",
                "5g raw shallots",
                "0.2g salt (½ dash)",
                "0.05g black pepper (½ dash)",
                "8g fresh parsley",
              ],
              directions: [
                "Chop the avocado, radishes, onion, and cucumber. Set aside.",
                "Make the dressing by combining the lemon juice, olive oil, minced garlic, minced shallots, salt, and pepper in a large bowl. Mix thoroughly.",
                "Add the chopped avocado, radish, onion, and cucumber to the bowl. Toss with the dressing until everything is evenly coated.",
                "Add more salt and pepper if desired, garnish with parsley, and then serve. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 272,
                fat: "76%",
                carbs: "20%",
                protein: "4%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Multi-grain Toast with Almond Butter and Honey",
              description:
                "A delicious and nutritious toast topped with almond butter and honey, providing 219 Calories per serving. The macronutrient breakdown is 34% carbs, 54% fat, and 12% protein. This recipe is a good source of vitamin E (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "28g whole-wheat bread (1 slice)",
                "5g salted butter",
                "16g almond butter (plain, without salt added)",
                "5g honey",
              ],
              directions: [
                "Toast the bread.",
                "Top the bread with butter, almond butter, and then honey. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 219,
                fat: "54%",
                carbs: "34%",
                protein: "12%",
              },
            },
            {
              name: "Peach and Peanut Butter Snack",
              description:
                "A simple and satisfying snack combining fresh peaches with creamy peanut butter, providing 247 Calories per serving. The macronutrient breakdown is 32% carbs, 55% fat, and 14% protein. This recipe is a good source of protein (16% of your Daily Value), fiber (17% of your Daily Value), and potassium (11% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 medium raw peach (150g)",
                "32g chunk style peanut butter (without salt)",
              ],
              directions: [
                "Pit and slice the peach.",
                "Dip peach slices into peanut butter and enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 247,
                fat: "55%",
                carbs: "32%",
                protein: "14%",
              },
            },
          ],
          lunch: [
            {
              name: "Banana Yogurt Protein Shake",
              description:
                "A creamy and nutritious shake combining banana, yogurt, and protein powder, providing 277 Calories per serving. The macronutrient breakdown is 37% carbs, 20% fat, and 42% protein. This shake is a good source of protein (52% of your Daily Value), potassium (10% of your Daily Value), and calcium (35% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "30g whey protein powder (Gold Standard, any flavor)",
                "120g plain almond milk",
                "½ medium raw banana (59g)",
                "123g plain yogurt (whole milk, 8 grams protein per 8 ounces)",
                "119g ice cubes",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 277,
                fat: "20%",
                carbs: "37%",
                protein: "42%",
              },
            },
            {
              name: "Simple Spinach Salad",
              description:
                "A quick and healthy salad featuring fresh spinach and scallions, dressed with olive oil and lemon juice. One serving contains 161 Calories with a macronutrient breakdown of 20% carbs, 72% fat, and 8% protein. It is a good source of potassium (14% of your Daily Value), magnesium (20% of your Daily Value), and vitamin A (50% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "90g raw spinach",
                "50g scallions (spring onions or scallions, includes tops and bulb)",
                "24g raw lemon juice (from ½ lemon)",
                "14g olive oil (salad or cooking)",
                "1 dash black pepper",
              ],
              directions: [
                "Wash spinach well, drain, and chop. Squeeze out excess water. Chop green onions.",
                "Put spinach in a mixing bowl and add the scallions, olive oil, pepper, and the juice from the squeezed lemon.",
                "Toss and serve.",
              ],
              nutrition: {
                caloriesPerServing: 161,
                fat: "72%",
                carbs: "20%",
                protein: "8%",
              },
            },
          ],
          snack: [
            {
              name: "Avocado Lettuce Wrap",
              description:
                "A fresh and nutritious wrap made with creamy avocado and fresh vegetables, perfect for a light meal or snack. Two wraps contain 194 Calories with a macronutrient breakdown of 29% carbs, 64% fat, and 7% protein. This recipe is a good source of fiber (33% of your Daily Value), potassium (19% of your Daily Value), and vitamin A (33% of your Daily Value).",
              makes: "8 wraps",
              prepTime: "10 minutes",
              ingredients: [
                "92g red, ripe tomatoes (¾ medium whole)",
                "2g raw jalapeno peppers (⅛ pepper)",
                "5g raw onions",
                "2g raw garlic (¾ clove)",
                "1g fresh cilantro (coriander leaves)",
                "56g romaine or cos lettuce (2 outer leaves)",
                "101g raw avocado (½ fruit)",
                "3g raw lime juice",
              ],
              directions: [
                "Mince garlic, dice tomatoes and jalapeno, and chop cilantro.",
                "In a medium-sized bowl, mash the avocados. Stir in all the ingredients except for the lettuce.",
                "Spread 2 to 3 tablespoons of this mixture onto lettuce leaves and wrap.",
              ],
              nutrition: {
                caloriesPerServing: 194,
                fat: "64%",
                carbs: "29%",
                protein: "7%",
              },
            },
          ],
          dinner: [
            {
              name: "Bean Sprouts with Tofu",
              description:
                "A healthy and satisfying dish featuring sprouted mung beans and silken tofu, packed with protein and flavor. Two servings contain 279 Calories with a macronutrient breakdown of 19% carbs, 57% fat, and 24% protein. This recipe is a good source of protein (31% of your Daily Value), potassium (13% of your Daily Value), and vitamin K (79% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "104g mature sprouted mung beans (raw)",
                "14g olive oil (salad or cooking)",
                "170g silken firm tofu (Mori-nu, soy)",
                "3g raw garlic (1 clove)",
                "18g soy sauce (tamari)",
                "25g raw scallions (1 large)",
              ],
              directions: [
                "Rinse the sprouted mung beans with cold running water, drain, and set aside. Remove the roots if desired.",
                "Heat up a wok and add some cooking oil for pan-frying the tofu. When the oil is fully heated, pan-fry the tofu until they turn light brown on the surface. Transfer them to a dish lined with paper towels.",
                "Leave about 1 tablespoon of oil in the same wok, stir-fry the garlic until aromatic, then add the tofu back into the wok for a few quick stirs before adding the mung beans. Add soy sauce, scallions, and do a few more quick stirs. Plate and serve immediately.",
              ],
              nutrition: {
                caloriesPerServing: 279,
                fat: "57%",
                carbs: "19%",
                protein: "24%",
              },
            },
            {
              name: "Bean Sprout and Spinach Salad",
              description:
                "A refreshing salad combining crisp spinach and sprouted mung beans, dressed with a tangy vinaigrette. One serving contains 320 Calories with a macronutrient breakdown of 19% carbs, 75% fat, and 7% protein. This salad is a good source of fiber (15% of your Daily Value), potassium (14% of your Daily Value), and magnesium (19% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "60g raw spinach",
                "104g mature sprouted mung beans (raw)",
                "83g sweet raw onion (¼ onion)",
                "30g cider vinegar",
                "27g olive oil (salad or cooking)",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "1g paprika",
              ],
              directions: [
                "In a mixing bowl, toss the spinach together with the bean sprouts and chopped onion.",
                "Next, make the dressing. In a small bowl, mix together equal parts of apple cider vinegar and olive oil. Add some salt and pepper to taste.",
                "Pour the dressing onto the salad and mix well. Garnish with a little paprika, then serve.",
              ],
              nutrition: {
                caloriesPerServing: 320,
                fat: "75%",
                carbs: "19%",
                protein: "7%",
              },
            },
          ],
        },
        {
          kalories: "1700",
          cardbs: "43",
          fat: "38",
          protein: "43",
          breakfast: [
            {
              name: "Guacamole Pizza",
              description:
                "A quick and delicious guacamole pizza made with a whole wheat pita, topped with mashed avocado and a perfectly fried egg. One serving contains 263 Calories with a macronutrient breakdown of 33% carbs, 52% fat, and 15% protein. This dish is a good source of protein (19% of your Daily Value), fiber (24% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "1 small whole-wheat pita (28g)",
                "1 large whole egg (50g)",
                "½ California avocado (68g, without skin and seed)",
                "0.4g table salt (1 dash)",
                "0.1g black pepper (1 dash)",
                "1g garlic powder",
              ],
              directions: [
                "Warm up a whole wheat pita in the microwave for 15 to 20 seconds. Mash ¼ of an avocado and spread onto pita.",
                "Heat a non-stick skillet over medium heat. Fry the egg until the yolk reaches desired doneness.",
                "Place the egg on top of the pita and avocado. Season with salt, pepper, and garlic powder. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 263,
                fat: "52%",
                carbs: "33%",
                protein: "15%",
              },
            },
            {
              name: "Guacamole on Tostada",
              description:
                "A simple and flavorful guacamole spread on a crispy tostada shell. One serving contains 192 Calories with a macronutrient breakdown of 36% carbs, 59% fat, and 6% protein. This dish is a good source of fiber (23% of your Daily Value), potassium (12% of your Daily Value), and vitamin B6 (24% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "½ California avocado (68g, without skin and seed)",
                "1 Roma tomato (62g, red, ripe, raw)",
                "15g lemon juice (raw)",
                "14g raw onion",
                "1g fresh cilantro (coriander leaves, raw)",
                "1 piece corn tostada shell (12g)",
              ],
              directions: [
                "Mash the avocado and mix it with the chopped tomatoes, lemon juice, onion, and cilantro.",
                "Spread the guacamole mixture on the tostada shell. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 192,
                fat: "59%",
                carbs: "36%",
                protein: "6%",
              },
            },
          ],
          lunch: [
            {
              name: "Greek Yogurt with Blueberries, Walnuts & Honey",
              description:
                "A delicious and nutritious treat featuring Greek yogurt topped with fresh blueberries, walnuts, and honey. One serving contains 405 Calories with a macronutrient breakdown of 18% carbs, 66% fat, and 17% protein. This dish is a good source of protein (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "1 minute",
              ingredients: [
                "37g raw blueberries",
                "7g honey",
                "31g black walnuts (dried)",
                "120g plain, whole fat Greek yogurt",
              ],
              directions: ["Mix the ingredients together in a bowl.", "Enjoy!"],
              nutrition: {
                caloriesPerServing: 405,
                fat: "66%",
                carbs: "18%",
                protein: "17%",
              },
            },
            {
              name: "Bell Pepper and Hummus Snack",
              description:
                "1 serving of bell pepper and hummus snack contains 101 Calories. The macronutrient breakdown is 55% carbs, 29% fat, and 16% protein. This is a good source of fiber (19% of your Daily Value), potassium (9% of your Daily Value), and vitamin A (29% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                '164g sweet, raw red bell pepper (1 large, approx 3-3/4" long)',
                "30g commercial hummus",
              ],
              directions: [
                "Slice the bell pepper.",
                "Serve with 2 tablespoons of hummus.",
              ],
              nutrition: {
                calories: 101,
                fat: "29%",
                carbs: "55%",
                protein: "16%",
              },
            },
          ],
          snack: [
            {
              name: "Peach and Peanut Butter Snack",
              description:
                "A simple and satisfying snack combining fresh peaches with creamy peanut butter, providing 247 Calories per serving. The macronutrient breakdown is 32% carbs, 55% fat, and 14% protein. This recipe is a good source of protein (16% of your Daily Value), fiber (17% of your Daily Value), and potassium (11% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "1 medium raw peach (150g)",
                "32g chunk style peanut butter (without salt)",
              ],
              directions: [
                "Pit and slice the peach.",
                "Dip peach slices into peanut butter and enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 247,
                fat: "55%",
                carbs: "32%",
                protein: "14%",
              },
            },
          ],
          dinner: [
            {
              name: "Low Fat Bean and Cheese Burrito",
              description:
                "A hearty and healthy burrito featuring refried beans and cheddar cheese, perfect for a quick meal. One serving contains 376 Calories with a macronutrient breakdown of 49% carbs, 31% fat, and 20% protein. This dish is a good source of protein (33% of your Daily Value), fiber (32% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "1 serving",
              prepTime: "3 minutes",
              cookTime: "1 minute",
              ingredients: [
                "154g canned, fat-free refried beans",
                "16g Pace Picante Sauce",
                "1 medium flour tortilla (46g)",
                "28g cheddar cheese",
              ],
              directions: [
                "Mix refried beans and picante sauce together.",
                "Spread the bean mixture on the tortilla.",
                "Top with cheddar cheese.",
                "Heat in the microwave for 45 seconds.",
                "Roll into a burrito and serve immediately.",
              ],
              nutrition: {
                caloriesPerServing: 376,
                fat: "31%",
                carbs: "49%",
                protein: "20%",
              },
            },
          ],
        },
      ],
      2200: [
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Multi-grain Toast with Almond Butter and Honey",
              description:
                "A delicious and nutritious toast topped with almond butter and honey, providing 219 Calories per serving. The macronutrient breakdown is 34% carbs, 54% fat, and 12% protein. This recipe is a good source of vitamin E (32% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "3 minutes",
              ingredients: [
                "28g whole-wheat bread (1 slice)",
                "5g salted butter",
                "16g almond butter (plain, without salt added)",
                "5g honey",
              ],
              directions: [
                "Toast the bread.",
                "Top the bread with butter, almond butter, and then honey. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 219,
                fat: "54%",
                carbs: "34%",
                protein: "12%",
              },
            },
            {
              name: "Cinnamon Banana Mug Cake",
              description:
                "A quick and delicious mug cake that combines the flavors of cinnamon and banana. One serving contains 305 Calories with a macronutrient breakdown of 25% carbs, 57% fat, and 18% protein. This dessert is a good source of protein (25% of your Daily Value) and fiber (18% of your Daily Value).",
              makes: "1 serving",
              prepTime: "2 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "1 large egg (50g)",
                "A dash of salt (0.4g)",
                "1g ground cinnamon",
                "30g almond flour",
                "56g raw banana",
              ],
              directions: [
                "Spray a mug with non-stick spray.",
                "Combine all ingredients together in a bowl.",
                "Add the mixture to the mug.",
                "Microwave for about 2 minutes or until a toothpick comes out clean. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 305,
                fat: "57%",
                carbs: "25%",
                protein: "18%",
              },
            },
          ],
          lunch: [
            {
              name: "Vegan Chocolate Peanut Protein Shake",
              description:
                "A delicious and nutritious shake that combines chocolate and peanut flavors. One shake contains 489 Calories with a macronutrient breakdown of 38% carbs, 35% fat, and 27% protein. This shake is a good source of protein (61% of your Daily Value), fiber (42% of your Daily Value), and potassium (18% of your Daily Value).",
              makes: "1 shake",
              prepTime: "2 minutes",
              ingredients: [
                "240g plain almond milk",
                "30g organic plain rice protein (NutriBiotic)",
                "32g smooth peanut butter (without salt)",
                "5g unsweetened cocoa powder",
                "1 medium raw banana (118g)",
                "1 cup ice cubes (for a better shake, optional)",
              ],
              directions: [
                "Add all ingredients to a blender.",
                "Blend well until smooth.",
              ],
              nutrition: {
                caloriesPerServing: 489,
                fat: "35%",
                carbs: "38%",
                protein: "27%",
              },
            },
          ],
          snack: [
            {
              name: "Yogurt with Mango & Avocado",
              description:
                "A refreshing and nutritious yogurt dish featuring the creamy flavors of avocado and mango, with a hint of chili powder. One serving contains 280 calories, with a macronutrient breakdown of 34% carbs, 47% fat, and 19% protein. This dish is a good source of protein (26% of your Daily Value), fiber (30% of your Daily Value), and potassium (17% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "113g nonfat plain Greek yogurt",
                "83g raw mango",
                "½ avocado (101g)",
                "0.9g chili powder",
              ],
              directions: [
                "Chop the mango and avocado.",
                "Mix the chopped mango and avocado with chili powder and yogurt.",
                "Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 280,
                fat: "47%",
                carbs: "34%",
                protein: "19%",
              },
            },
          ],
          dinner: [
            {
              name: "Lazy Pad Thai",
              description:
                "A quick and easy version of Pad Thai, featuring rice noodles tossed in a creamy peanut sauce with a hint of spice. One serving contains 424 calories, with a macronutrient breakdown of 49% carbs, 43% fat, and 8% protein. This dish is a good source of protein (15% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "57g dry rice noodles",
                "16g smooth peanut butter (with salt)",
                "57g canned coconut milk",
                "0.2g crushed red pepper flakes",
                "0.6g pepper or hot sauce",
                "0.9g ground ginger",
                "1 clove (3g) raw garlic",
                "0.5g fresh parsley (½ sprig)",
              ],
              directions: [
                "Bring water to a boil in a medium saucepan. Add the rice noodles and cook for 5 minutes. Drain and set aside.",
                "Blend the peanut butter, coconut milk, red pepper flakes, hot sauce, ginger, garlic, and parsley until smooth.",
                "Add the sauce to the noodles and stir until evenly distributed.",
              ],
              nutrition: {
                caloriesPerServing: 424,
                fat: "43%",
                carbs: "49%",
                protein: "8%",
              },
            },
            {
              name: "Goat Cheese and Apple Salad",
              description:
                "A fresh and vibrant salad featuring spinach, romaine lettuce, and crisp apple slices, topped with creamy goat cheese and drizzled with a balsamic vinaigrette. One serving contains 203 calories, with a macronutrient breakdown of 44% carbs, 40% fat, and 16% protein. This salad is a good source of protein (17% of your Daily Value), fiber (17% of your Daily Value), and potassium (15% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "93g raw spinach",
                "24g raw romaine lettuce",
                "82g apple (with skin)",
                "45g Newman's Own low fat balsamic vinaigrette",
                "0.1g black pepper",
                "21g hard goat cheese",
              ],
              directions: [
                "Tear spinach and lettuce into smaller pieces and place in a bowl.",
                "Cut the apple into slices and layer on top of the greens.",
                "Drizzle balsamic vinaigrette on top and season with pepper to taste.",
                "Sprinkle goat cheese on top and lightly toss all ingredients together. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 203,
                fat: "40%",
                carbs: "44%",
                protein: "16%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Coconut Banana Avocado Smoothie",
              description:
                "A creamy and refreshing smoothie made with coconut milk, banana, blueberries, strawberries, and avocado. One serving contains 396 calories, with a macronutrient breakdown of 33% carbs, 63% fat, and 4% protein. This smoothie is a good source of fiber (25% of your Daily Value), potassium (18% of your Daily Value), and iron (57% of your Daily Value).",
              makes: "2 servings",
              prepTime: "8 minutes",
              ingredients: [
                "113g canned coconut milk",
                "68g raw banana (½ large)",
                "56g raw blueberries",
                "76g raw strawberries",
                "34g California avocado (¼ fruit, without skin and seed)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 396,
                fat: "63%",
                carbs: "33%",
                protein: "4%",
              },
            },
            {
              name: "Light Raspberry Yogurt",
              description:
                "A delicious and nutritious light raspberry yogurt made with nonfat Greek yogurt, fresh raspberries, and a touch of honey. One cup contains 141 calories, with a macronutrient breakdown of 45% carbs, 6% fat, and 49% protein. This yogurt is a good source of protein (32% of your Daily Value), calcium (20% of your Daily Value), and vitamin B12 (53% of your Daily Value).",
              makes: "1 cup",
              prepTime: "10 minutes",
              ingredients: [
                "170g nonfat plain Greek yogurt",
                "37g raw raspberries",
                "7g honey",
              ],
              directions: [
                "In a cup, mix raspberries with nonfat Greek yogurt (a fork is suggested to achieve smoothness) and honey.",
                "Let rest in the freezer for an extra 10 minutes for a 'froyo' texture, then beat with a fork again. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 141,
                fat: "6%",
                carbs: "45%",
                protein: "49%",
              },
            },
          ],
          lunch: [
            {
              name: "Chia Cottage Cheese with Blueberries",
              description:
                "A nutritious and easy-to-make chia cottage cheese dish topped with fresh blueberries. Each serving contains 380 calories, with a macronutrient breakdown of 29% carbs, 40% fat, and 31% protein. This dish is a good source of protein (53% of your Daily Value), fiber (36% of your Daily Value), and calcium (34% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "226g creamed cottage cheese (large or small curd)",
                "74g raw blueberries",
                "24g dried chia seeds",
              ],
              directions: [
                "Put the cottage cheese in a bowl and add the blueberries. Stir well.",
                "Sprinkle the chia seeds on top and stir together once more. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 190,
                fat: "40%",
                carbs: "29%",
                protein: "31%",
              },
            },
            {
              name: "Avocado, Strawberry, and Spinach Salad",
              description:
                "A refreshing and nutritious salad featuring fresh strawberries, creamy avocado, and spinach. Each serving contains 327 calories, with a macronutrient breakdown of 30% carbs, 61% fat, and 8% protein. This salad is a good source of fiber (50% of your Daily Value), potassium (31% of your Daily Value), and calcium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "166g raw strawberries",
                "101g raw avocado (½ fruit)",
                "120g raw spinach",
                "12g pecans",
              ],
              directions: [
                "Chop the strawberries and avocado.",
                "Toss the chopped strawberries, avocado, spinach, and pecans together in a bowl. Serve.",
              ],
              nutrition: {
                caloriesPerServing: 327,
                fat: "61%",
                carbs: "30%",
                protein: "8%",
              },
            },
          ],
          snack: [
            {
              name: "Matcha Green Tea Chia Pudding",
              description:
                "A creamy and nutritious chia pudding infused with matcha green tea. Each serving contains 288 calories, with a macronutrient breakdown of 49% carbs, 42% fat, and 9% protein. This pudding is a good source of fiber (48% of your Daily Value), potassium (7% of your Daily Value), and calcium (43% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "240g plain almond milk",
                "36g dried chia seeds",
                "14g honey",
                "2g matcha green tea mix (Tropical Sweetened Powder - Trader Joe's)",
                "1 dash table salt (0.4g)",
              ],
              directions: [
                "Mix together almond milk, honey, matcha, and salt until well combined.",
                "In a bowl, pour in chia seeds then add the matcha almond milk mixture. Stir to coat the chia seeds with the liquid.",
                "Refrigerate for 10 minutes, then stir again well.",
                "Keep in the refrigerator for at least 2 hours or overnight. Dust the top with a little matcha before serving.",
              ],
              nutrition: {
                caloriesPerServing: 288,
                fat: "42%",
                carbs: "49%",
                protein: "9%",
              },
            },
          ],
          dinner: [
            {
              name: "Hummus and Grilled Vegetable Wrap",
              description:
                "1 serving contains 308 Calories. The macronutrient breakdown is 55% carbs, 33% fat, and 13% protein. This is a good source of protein (18% of your Daily Value), fiber (23% of your Daily Value), and potassium (13% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "98g zucchini, summer squash, includes skin, raw",
                "62g home-prepared hummus",
                "1 medium tortilla, ready-to-bake or -fry, flour",
                "6g raw almonds",
                "15g raw spinach",
                "30g raw red bell pepper",
                "4g small raw red onion",
              ],
              directions: [
                "Thinly slice the bell pepper and red onion.",
                "Cut the zucchini lengthwise into 1/4 inch slices. Brush both sides of the zucchini slices with oil and sprinkle with salt and pepper. Grill until tender and slightly browned, about 4 minutes per side.",
                "Spread hummus over each tortilla. Add all toppings, roll up, and serve.",
              ],
              nutrition: {
                calories: 308,
                fat: "33%",
                protein: "13%",
                carbs: "55%",
              },
            },
            {
              name: "Summer Pepper and Tomato Salad",
              description:
                "A refreshing salad featuring ripe tomatoes and sweet red bell peppers, dressed in olive oil and balsamic vinegar. Each serving contains 370 calories, with a macronutrient breakdown of 28% carbs, 67% fat, and 5% protein. This salad is a good source of fiber (25% of your Daily Value), potassium (24% of your Daily Value), and vitamin A (38% of your Daily Value).",
              makes: "1 serving",
              prepTime: "15 minutes",
              ingredients: [
                "364g red, ripe tomatoes (about 2 large)",
                "119g medium red bell pepper",
                "27g olive oil (salad or cooking)",
                "32g balsamic vinegar",
                "1 dash table salt (0.4g)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Coarsely chop the pepper and tomatoes.",
                "Place the chopped pepper and tomatoes in a bowl. Pour in the olive oil and balsamic vinegar. Season with salt and pepper.",
                "Toss gently to coat the ingredients.",
                "Refrigerate until ready to serve. Enjoy!",
              ],
              nutrition: {
                caloriesPerServing: 370,
                fat: "67%",
                carbs: "28%",
                protein: "5%",
              },
            },
          ],
        },
        {
          kalories: "2200",
          cardbs: "55",
          fat: "49",
          protein: "55",
          breakfast: [
            {
              name: "Scrambled Eggs on Toast with Vegemite and Avocado",
              description:
                "A hearty breakfast of scrambled eggs on toast topped with Vegemite and mashed avocado. Each serving contains 400 calories, with a macronutrient breakdown of 25% carbs, 55% fat, and 20% protein. This dish is a good source of protein (36% of your Daily Value), fiber (19% of your Daily Value), and potassium (12% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "8 minutes",
              ingredients: [
                "32g rye bread (1 slice, regular)",
                "5g salted butter",
                "6g Vegemite (Kraft)",
                "50g raw avocado (¼ fruit)",
                "100g whole eggs (2 large)",
                "81g whole milk (3.25% milkfat, with added vitamin D)",
                "1 dash black pepper (0.1g)",
              ],
              directions: [
                "Toast the bread.",
                "Top the toasted bread with butter and Vegemite.",
                "Mash the avocado onto the bread.",
                "In a non-stick skillet over medium heat, scramble the eggs with the milk.",
                "Top the prepared toast with scrambled eggs and freshly cracked pepper.",
              ],
              nutrition: {
                caloriesPerServing: 400,
                fat: "55%",
                carbs: "25%",
                protein: "20%",
              },
            },
          ],
          lunch: [
            {
              name: "Spinach Tomato Salad",
              description:
                "1 serving contains 186 Calories. The macronutrient breakdown is 26% carbs, 63% fat, and 11% protein. This is a good source of fiber (20% of your Daily Value), potassium (24% of your Daily Value), and calcium (19% of your Daily Value).",
              makes: "2 servings",
              prepTime: "5 minutes",
              ingredients: [
                "150g raw spinach",
                "50g scallions (spring onions or scallions, includes tops and bulb)",
                "62g red, ripe tomatoes (½ medium whole)",
                "14g olive oil (salad or cooking)",
                "1 dash of black pepper",
                "24g lemon juice (from ½ lemon)",
              ],
              directions: [
                "Wash the spinach well, drain, and chop. Squeeze out excess water.",
                "Chop the green onions and tomato.",
                "Put the spinach in a mixing bowl and add the tomato, scallions, oil, pepper, and the juice from 1 squeezed lemon.",
                "Toss and serve.",
              ],
              nutrition: {
                calories: 186,
                fat: "63%",
                protein: "11%",
                carbs: "26%",
              },
            },
            {
              name: "Avocado Sandwich",
              description:
                "1 Sandwich contains 464 Calories. The macronutrient breakdown is 34% carbs, 57% fat, and 9% protein. This is a good source of protein (20% of your Daily Value), fiber (60% of your Daily Value), and potassium (24% of your Daily Value).",
              makes: "1 Sandwich",
              prepTime: "5 minutes",
              ingredients: [
                "201g raw avocado (1 fruit)",
                "56g whole-wheat bread (2 slices)",
                "5g raw lemon juice",
                "1 dash of table salt",
              ],
              directions: [
                "Slice or smash avocado.",
                "Layer between two slices of bread.",
                "Season with salt and a squeeze of lemon before bringing slices together. Enjoy!",
              ],
              nutrition: {
                calories: 464,
                fat: "57%",
                protein: "9%",
                carbs: "34%",
              },
            },
          ],
          snack: [
            {
              name: "Raspberries and Blackberries Protein Smoothie",
              description:
                "1 Shake contains 301 Calories. The macronutrient breakdown is 43% carbs, 19% fat, and 39% protein. This is a good source of protein (54% of your Daily Value), fiber (23% of your Daily Value), and potassium (17% of your Daily Value).",
              makes: "1 Shake",
              prepTime: "5 minutes",
              ingredients: [
                "245g nonfat milk (with added vitamin A and vitamin D)",
                "1g herbal extract powder from Stevia leaf (1 package)",
                "36g raw blackberries",
                "31g raw raspberries",
                "47g Muscle Milk Naturals protein powder (chocolate, 1 ⅓ scoop)",
              ],
              directions: [
                "Combine all ingredients in a blender and pulse until smooth.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 301,
                fat: "19%",
                protein: "39%",
                carbs: "43%",
              },
            },
          ],
          dinner: [
            {
              name: "Pasta la Checca",
              description:
                "1 serving contains 360 Calories. The macronutrient breakdown is 54% carbs, 36% fat, and 10% protein. This is a good source of protein (16% of your Daily Value), potassium (11% of your Daily Value), and vitamin C (24% of your Daily Value).",
              makes: "4 servings",
              prepTime: "10 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "2g raw garlic (½ clove, minced)",
                "149g red, ripe cherry tomatoes (raw)",
                "0.03g black pepper (¼ dash)",
                "57g dry spaghetti (unenriched)",
                "0.1g table salt (¼ dash)",
                "5g raw arugula",
                "14g olive oil (salad or cooking)",
              ],
              directions: [
                "Finely chop/mince garlic cloves.",
                "Cook the pasta until al dente.",
                "Meanwhile, in a large bowl, squeeze the tomatoes to break them up.",
                "Add the garlic, oil, arugula (if using), salt, and pepper; set aside.",
                "Drain the pasta (reserving 1/2 cup of the water) and toss it with the tomatoes. If the pasta is still a little dry, add some of the cooking water.",
                "Let sit until the heat from the pasta has cooked the tomatoes and garlic slightly and wilted the arugula, 1 to 2 minutes. Serve warm or at room temperature.",
              ],
              nutrition: {
                calories: 360,
                fat: "36%",
                protein: "10%",
                carbs: "54%",
              },
            },
          ],
        },
      ],
      2700: [
        {
          kalories: "2700",
          cardbs: "69",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Egg-Stuffed Avocado",
              description:
                "1 serving contains 551 Calories. The macronutrient breakdown is 14% carbs, 73% fat, and 13% protein. This is a good source of protein (32% of your Daily Value), fiber (35% of your Daily Value), and potassium (20% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "136g California avocados (raw, without skin and seed)",
                "112g whole extra large eggs",
                "60g light mayonnaise (salad dressing, light)",
                "8g reduced fat sour cream",
                "3g Dijon mustard (Grey Poupon)",
                "15g raw scallions (spring onions or scallions, includes tops and bulb)",
                "2g table salt",
              ],
              directions: [
                "Start by cooking the eggs. Fill a small saucepan 3/4 full of water. Add a good pinch of salt to prevent the eggs from cracking. Bring to a boil.",
                "Using a spoon, dip each egg in and out of the boiling water to prevent cracking. After doing this a few times, carefully set the eggs in the boiling water and cook for 10 minutes. When done, remove from heat and place in a bowl filled with cold water.",
                "Peel and dice the eggs. Finely slice the spring onion.",
                "In a bowl, mix the diced eggs, mayo, sour cream, Dijon mustard, and most of the spring onion, leaving some for garnish. Season with salt and pepper to taste.",
                "Scoop the middle of the avocado out, leaving ½ - 1 inch of the avocado flesh. Cut the scooped avocado into small pieces.",
                "Place the chopped avocado into the bowl with eggs and mix until well combined.",
                "Fill each avocado half with the egg mixture and top with spring onion.",
              ],
              nutrition: {
                calories: 551,
                fat: "73%",
                protein: "13%",
                carbs: "14%",
              },
            },
            {
              name: "Honey Grapefruit with Banana",
              description:
                "1 serving contains 116 Calories. The macronutrient breakdown is 93% carbs, 2% fat, and 5% protein. This is a good source of potassium (9% of your Daily Value) and vitamin C (64% of your Daily Value).",
              makes: "3 servings",
              prepTime: "5 minutes",
              ingredients: [
                "153g pink/red/white grapefruit (raw)",
                "50g raw banana",
                "2g fresh spearmint",
                "7g honey",
              ],
              directions: [
                "Drain grapefruit sections, reserving 1/4 cup juice.",
                "Combine grapefruit sections, juice, and remaining ingredients in a medium bowl. Toss gently to coat.",
                "Serve immediately or cover and chill. Enjoy!",
              ],
              nutrition: {
                calories: 116,
                fat: "2%",
                protein: "5%",
                carbs: "93%",
              },
            },
          ],
          lunch: [
            {
              name: "Oatmeal Banana Peanut Butter Chocolate Shake",
              description:
                "1 shake contains 670 Calories. The macronutrient breakdown is 40% carbs, 25% fat, and 35% protein. This is a good source of protein (109% of your Daily Value), fiber (34% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 shake",
              prepTime: "5 minutes",
              ingredients: [
                "60g whey protein powder (Gold Standard, any flavor)",
                "1 medium raw banana (118g)",
                "40g plain rolled oats (dry oatmeal)",
                "32g smooth peanut butter (with salt)",
                "473g plain clean water",
              ],
              directions: [
                "Put all ingredients into a blender.",
                "Blend until smooth.",
                "Use unsweetened almond milk (30 calories, 1g carbohydrate, 1g protein, 0g fat per cup) in place of water if you prefer a creamier shake.",
              ],
              nutrition: {
                calories: 670,
                fat: "25%",
                protein: "35%",
                carbs: "40%",
              },
            },
            {
              name: "Turkish Style Salad",
              description:
                "1 serving contains 31 Calories. The macronutrient breakdown is 21% carbs, 75% fat, and 4% protein. This has a relatively low calorie density, with 67 Calories per 100g.",
              makes: "8 servings",
              prepTime: "15 minutes",
              ingredients: [
                "11g red, ripe raw tomatoes",
                "3g olive oil (salad or cooking)",
                "17g peeled raw cucumber",
                "6g raw onions",
                "0.7g raw parsley",
                "3g sweet raw green bell pepper",
                "1g raw lemon juice (¼ wedge yields)",
                "4g red wine vinegar",
              ],
              directions: [
                "Chop up all ingredients and toss with oil, lemon juice, and vinegar.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 31,
                fat: "75%",
                protein: "4%",
                carbs: "21%",
              },
            },
          ],
          snack: [
            {
              name: "Vegan Waldorf Salad",
              description:
                "1 salad contains 387 Calories. The macronutrient breakdown is 43% carbs, 50% fat, and 7% protein. This is a good source of fiber (29% of your Daily Value), potassium (13% of your Daily Value), and vitamin B6 (24% of your Daily Value).",
              makes: "2 salads",
              prepTime: "3 minutes",
              ingredients: [
                "30g English walnuts",
                "42g raw lemon (½ fruit, without peel)",
                "28g Silk vanilla soy yogurt (⅛ container)",
                "18g seeded raisins",
                "15g imitation mayonnaise salad dressing (soybean)",
                "111g apples (with skin, ½ large)",
                "64g raw celery (1 large stalk, 11 inches long)",
              ],
              directions: [
                "Chop the apple, celery, and walnuts (if necessary).",
                "Mix chopped ingredients with yogurt and juice from a lemon half.",
                "Top with raisins and mayonnaise substitute, and mix lightly.",
              ],
              nutrition: {
                calories: 387,
                fat: "50%",
                protein: "7%",
                carbs: "43%",
              },
            },
          ],
          dinner: [
            {
              name: "Summer Pasta",
              description:
                "1 serving contains 631 Calories. The macronutrient breakdown is 38% carbs, 43% fat, and 19% protein. This is a good source of protein (54% of your Daily Value), potassium (8% of your Daily Value), and calcium (64% of your Daily Value).",
              makes: "6 servings",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "62g Roma tomatoes (red, ripe, raw, also called Italian or plum tomato)",
                "1g fresh basil",
                "18g olive oil (salad or cooking)",
                "76g part skim mozzarella cheese",
                "3g raw garlic (1 clove, minced)",
                "0.02g black pepper (³⁄₁₆ dash)",
                "76g dry whole wheat pasta",
                "0.3g garlic powder (spices)",
              ],
              directions: [
                "Chop the tomatoes and basil. Mince the garlic. Shred the mozzarella.",
                "Combine tomatoes, cheese, basil, garlic, olive oil, garlic powder, and black pepper in a medium bowl. Set aside.",
                "Meanwhile, cook pasta according to package directions.",
                "Drain pasta, and transfer to a serving bowl. Toss with the tomato mixture. Serve.",
              ],
              nutrition: {
                calories: 631,
                fat: "43%",
                protein: "19%",
                carbs: "38%",
              },
            },
            {
              name: "Baked Kale Chips",
              description:
                "1 serving contains 328 Calories. The macronutrient breakdown is 20% carbs, 73% fat, and 7% protein. This is a good source of potassium (20% of your Daily Value), calcium (42% of your Daily Value), and iron (84% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "201g raw Scotch kale",
                "27g olive oil (salad or cooking)",
                "0.5g cayenne pepper (spices, red or cayenne)",
                "0.5g paprika (spices)",
                "0.5g cumin (spices, cumin seed)",
                "2g table salt",
              ],
              directions: [
                "Preheat oven to 350 degrees F.",
                "Wash the kale and pat dry with a paper towel. Carefully tear the leaves from the stem, then rip them further into bite-sized pieces. Set aside.",
                "In a medium bowl, mix together the olive oil, cayenne, paprika, cumin, and sea salt. Stir thoroughly to ensure the spices don’t clump together.",
                "Add the kale leaves to the bowl and coat them thoroughly with the mixture of spices and oil.",
                "Lay the kale out on a flat cooking tray, making sure that the pieces do not overlap. Sprinkle with sea salt.",
                "Bake the kale until the edges turn slightly brown, about 10-12 minutes. The chips should be slightly crunchy, particularly around the edges.",
              ],
              nutrition: {
                calories: 328,
                fat: "73%",
                protein: "7%",
                carbs: "20%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "69",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Vegetable 3 Egg Scramble",
              description:
                "1 serving contains 511 Calories. The macronutrient breakdown is 26% carbs, 51% fat, and 23% protein. This is a good source of protein (52% of your Daily Value), fiber (34% of your Daily Value), and potassium (17% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "15 minutes",
              ingredients: [
                "14g salted butter",
                "225g frozen mixed vegetables",
                "3 extra large whole eggs (168g)",
                "0.8g table salt",
                "31g canned tomato sauce",
              ],
              directions: [
                "Melt the butter in a pan over medium heat.",
                "Add the vegetables and sauté until mostly cooked.",
                "Add the beaten eggs and then salt to the pan. Allow to cook until desired doneness.",
                "Serve with tomato sauce. Enjoy!",
              ],
              nutrition: {
                calories: 511,
                fat: "51%",
                protein: "23%",
                carbs: "26%",
              },
            },
            {
              name: "Apple Toast",
              description:
                "1 serving contains 130 Calories. The macronutrient breakdown is 63% carbs, 26% fat, and 11% protein. This is a good source of fiber (14% of your Daily Value).",
              makes: "4 servings",
              prepTime: "3 minutes",
              cookTime: "2 minutes",
              ingredients: [
                "4g unsalted butter",
                "1 slice whole-wheat bread (28g)",
                "2g ground cinnamon",
                "¼ large apple with skin (56g)",
              ],
              directions: [
                "Set oven to broil.",
                "Spread butter on one side of each slice of bread. Place apple slices on the buttered side of the bread. Sprinkle cinnamon on top. Place bread on a baking sheet.",
                "Place in a preheated oven until toasted, about 2 minutes.",
              ],
              nutrition: {
                calories: 130,
                fat: "26%",
                protein: "11%",
                carbs: "63%",
              },
            },
          ],
          lunch: [
            {
              name: "Seedy Avocado Toast",
              description:
                "1 serving contains 278 Calories. The macronutrient breakdown is 30% carbs, 58% fat, and 12% protein. This is a good source of protein (16% of your Daily Value), fiber (34% of your Daily Value), and potassium (10% of your Daily Value).",
              makes: "1 serving",
              prepTime: "10 minutes",
              ingredients: [
                "1 slice multi-grain bread (26g)",
                "½ California avocado, without skin and seed (68g)",
                "6g sunflower seed kernels",
                "5g organic hemp seeds",
                "6g chia seeds",
                "2g table salt",
                "0.5g black pepper",
                "6g lemon juice (from 1 wedge)",
              ],
              directions: [
                "Mash avocado and mix with chia seeds.",
                "Toast the bread and top with the avocado mix, sunflower seeds, hemp seeds, lemon juice, salt, and pepper.",
                "Top with more seeds if desired.",
              ],
              nutrition: {
                calories: 278,
                fat: "58%",
                protein: "12%",
                carbs: "30%",
              },
            },
            {
              name: "Arugula Salad with Grapefruit Dressing",
              description:
                "1 serving contains 420 Calories. The macronutrient breakdown is 28% carbs, 61% fat, and 10% protein. This is a good source of protein (21% of your Daily Value), fiber (17% of your Daily Value), and potassium (12% of your Daily Value).",
              makes: "2 servings",
              prepTime: "15 minutes",
              ingredients: [
                "115g grapefruit, raw (pink, red, or white)",
                "20g arugula, raw",
                "32g pumpkin and squash seed kernels",
                "½ clove minced garlic (2g)",
                "0.5g raw ginger root",
                "14g olive oil (salad or cooking)",
                "24g balsamic vinegar",
                "8g Dijon mustard (Grey Poupon)",
                "15g sweetened dried cranberries",
                "3g scallions (spring onions or scallions, raw)",
              ],
              directions: [
                "To make the dressing, mix minced garlic, ginger, vinegar, olive oil, and mustard together in a sealable container, along with any excess juice from the grapefruit. Shake well to mix.",
                "Arrange arugula and grapefruit sections on a plate. Top with pumpkin seeds, cranberries, and scallions.",
                "Add dressing to taste. Note that you will likely have some dressing left over for another salad.",
              ],
              nutrition: {
                calories: 420,
                fat: "61%",
                protein: "10%",
                carbs: "28%",
              },
            },
          ],
          snack: [
            {
              name: "Peanut Butter Protein Yogurt",
              description:
                "1 serving contains 356 Calories. The macronutrient breakdown is 17% carbs, 25% fat, and 58% protein. This is a good source of protein (93% of your Daily Value), potassium (10% of your Daily Value), and calcium (37% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              ingredients: [
                "240g nonfat Greek yogurt (nonfat, plain)",
                "30g whey protein powder (Gold Standard, any flavor, 100% whey protein)",
                "16g peanut butter (chunk style, without salt)",
              ],
              directions: [
                "Mix protein powder and peanut butter into Greek yogurt until well combined.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 356,
                fat: "25%",
                protein: "58%",
                carbs: "17%",
              },
            },
          ],
          dinner: [
            {
              name: "Lentil Pear Salad",
              description:
                "1 serving contains 769 Calories. The macronutrient breakdown is 35% carbs, 49% fat, and 16% protein. This is a good source of protein (59% of your Daily Value), fiber (90% of your Daily Value), and potassium (30% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "20 minutes",
              ingredients: [
                "198g lentils (mature seeds, cooked, boiled, with salt)",
                "57g hazelnuts or filberts (nuts)",
                "111g pears (raw, ¾ small)",
                "38g feta cheese",
                "40g arugula (raw)",
                "12g Newman's Own low-fat balsamic vinaigrette",
              ],
              directions: [
                "Prepare lentils as per package directions. Slice pears.",
                "Combine all ingredients together in a bowl and toss together well.",
                "Top with the dressing and serve.",
              ],
              nutrition: {
                calories: 769,
                fat: "49%",
                protein: "16%",
                carbs: "35%",
              },
            },
            {
              name: "Edamame Slaw",
              description:
                "1 serving contains 234 Calories. The macronutrient breakdown is 36% carbs, 46% fat, and 18% protein. This is a good source of protein (20% of your Daily Value), fiber (26% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "3 servings",
              prepTime: "15 minutes",
              ingredients: [
                "11g cashew butter (nuts, plain, without salt added)",
                "5g sesame oil (salad or cooking)",
                "12g soy sauce (made from soy, tamari)",
                "7g fresh cilantro (coriander leaves, raw, about 3 sprigs)",
                "32g tangerines (mandarin oranges, raw)",
                "43g carrots (raw)",
                "59g cabbage (raw)",
                "30g broccoli (raw)",
                "52g edamame (frozen, prepared)",
              ],
              directions: [
                "Mix cashew butter, oil, soy sauce, and chopped cilantro together in a small bowl.",
                "Prepare vegetables and add to a large bowl: rough chop tangerine sections, grate carrots into fine strips, julienne cabbage, and rough chop broccoli into small florets.",
                "Add edamame and dressing to the bowl of vegetables.",
                "Place in the refrigerator for about 1 hour to marinate flavors. Serve.",
              ],
              nutrition: {
                calories: 234,
                fat: "46%",
                protein: "18%",
                carbs: "36%",
              },
            },
          ],
        },
        {
          kalories: "2700",
          cardbs: "69",
          fat: "60",
          protein: "68",
          breakfast: [
            {
              name: "Egg-Stuffed Avocado",
              description:
                "1 serving contains 551 Calories. The macronutrient breakdown is 14% carbs, 73% fat, and 13% protein. This is a good source of protein (32% of your Daily Value), fiber (35% of your Daily Value), and potassium (20% of your Daily Value).",
              makes: "2 servings",
              prepTime: "10 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "136g California avocados (raw, without skin and seed)",
                "112g whole extra large eggs",
                "60g light mayonnaise (salad dressing, light)",
                "8g reduced fat sour cream",
                "3g Dijon mustard (Grey Poupon)",
                "15g raw scallions (spring onions or scallions, includes tops and bulb)",
                "2g table salt",
              ],
              directions: [
                "Start by cooking the eggs. Fill a small saucepan 3/4 full of water. Add a good pinch of salt to prevent the eggs from cracking. Bring to a boil.",
                "Using a spoon, dip each egg in and out of the boiling water to prevent cracking. After doing this a few times, carefully set the eggs in the boiling water and cook for 10 minutes. When done, remove from heat and place in a bowl filled with cold water.",
                "Peel and dice the eggs. Finely slice the spring onion.",
                "In a bowl, mix the diced eggs, mayo, sour cream, Dijon mustard, and most of the spring onion, leaving some for garnish. Season with salt and pepper to taste.",
                "Scoop the middle of the avocado out, leaving ½ - 1 inch of the avocado flesh. Cut the scooped avocado into small pieces.",
                "Place the chopped avocado into the bowl with eggs and mix until well combined.",
                "Fill each avocado half with the egg mixture and top with spring onion.",
              ],
              nutrition: {
                calories: 551,
                fat: "73%",
                protein: "13%",
                carbs: "14%",
              },
            },
            {
              name: "Honey Grapefruit with Banana",
              description:
                "1 serving contains 116 Calories. The macronutrient breakdown is 93% carbs, 2% fat, and 5% protein. This is a good source of potassium (9% of your Daily Value) and vitamin C (64% of your Daily Value).",
              makes: "3 servings",
              prepTime: "5 minutes",
              ingredients: [
                "153g pink/red/white grapefruit (raw)",
                "50g raw banana",
                "2g fresh spearmint",
                "7g honey",
              ],
              directions: [
                "Drain grapefruit sections, reserving 1/4 cup juice.",
                "Combine grapefruit sections, juice, and remaining ingredients in a medium bowl. Toss gently to coat.",
                "Serve immediately or cover and chill. Enjoy!",
              ],
              nutrition: {
                calories: 116,
                fat: "2%",
                protein: "5%",
                carbs: "93%",
              },
            },
          ],
          lunch: [
            {
              name: "Oatmeal Banana Peanut Butter Chocolate Shake",
              description:
                "1 shake contains 670 Calories. The macronutrient breakdown is 40% carbs, 25% fat, and 35% protein. This is a good source of protein (109% of your Daily Value), fiber (34% of your Daily Value), and potassium (16% of your Daily Value).",
              makes: "1 shake",
              prepTime: "5 minutes",
              ingredients: [
                "60g whey protein powder (Gold Standard, any flavor)",
                "1 medium raw banana (118g)",
                "40g plain rolled oats (dry oatmeal)",
                "32g smooth peanut butter (with salt)",
                "473g plain clean water",
              ],
              directions: [
                "Put all ingredients into a blender.",
                "Blend until smooth.",
                "Use unsweetened almond milk (30 calories, 1g carbohydrate, 1g protein, 0g fat per cup) in place of water if you prefer a creamier shake.",
              ],
              nutrition: {
                calories: 670,
                fat: "25%",
                protein: "35%",
                carbs: "40%",
              },
            },
            {
              name: "Turkish Style Salad",
              description:
                "1 serving contains 31 Calories. The macronutrient breakdown is 21% carbs, 75% fat, and 4% protein. This has a relatively low calorie density, with 67 Calories per 100g.",
              makes: "8 servings",
              prepTime: "15 minutes",
              ingredients: [
                "11g red, ripe raw tomatoes",
                "3g olive oil (salad or cooking)",
                "17g peeled raw cucumber",
                "6g raw onions",
                "0.7g raw parsley",
                "3g sweet raw green bell pepper",
                "1g raw lemon juice (¼ wedge yields)",
                "4g red wine vinegar",
              ],
              directions: [
                "Chop up all ingredients and toss with oil, lemon juice, and vinegar.",
                "Enjoy!",
              ],
              nutrition: {
                calories: 31,
                fat: "75%",
                protein: "4%",
                carbs: "21%",
              },
            },
          ],
          snack: [
            {
              name: "Vegan Waldorf Salad",
              description:
                "1 salad contains 387 Calories. The macronutrient breakdown is 43% carbs, 50% fat, and 7% protein. This is a good source of fiber (29% of your Daily Value), potassium (13% of your Daily Value), and vitamin B6 (24% of your Daily Value).",
              makes: "2 salads",
              prepTime: "3 minutes",
              ingredients: [
                "30g English walnuts",
                "42g raw lemon (½ fruit, without peel)",
                "28g Silk vanilla soy yogurt (⅛ container)",
                "18g seeded raisins",
                "15g imitation mayonnaise salad dressing (soybean)",
                "111g apples (with skin, ½ large)",
                "64g raw celery (1 large stalk, 11 inches long)",
              ],
              directions: [
                "Chop the apple, celery, and walnuts (if necessary).",
                "Mix chopped ingredients with yogurt and juice from a lemon half.",
                "Top with raisins and mayonnaise substitute, and mix lightly.",
              ],
              nutrition: {
                calories: 387,
                fat: "50%",
                protein: "7%",
                carbs: "43%",
              },
            },
          ],
          dinner: [
            {
              name: "Summer Pasta",
              description:
                "1 serving contains 631 Calories. The macronutrient breakdown is 38% carbs, 43% fat, and 19% protein. This is a good source of protein (54% of your Daily Value), potassium (8% of your Daily Value), and calcium (64% of your Daily Value).",
              makes: "6 servings",
              prepTime: "10 minutes",
              cookTime: "5 minutes",
              ingredients: [
                "62g Roma tomatoes (red, ripe, raw, also called Italian or plum tomato)",
                "1g fresh basil",
                "18g olive oil (salad or cooking)",
                "76g part skim mozzarella cheese",
                "3g raw garlic (1 clove, minced)",
                "0.02g black pepper (³⁄₁₆ dash)",
                "76g dry whole wheat pasta",
                "0.3g garlic powder (spices)",
              ],
              directions: [
                "Chop the tomatoes and basil. Mince the garlic. Shred the mozzarella.",
                "Combine tomatoes, cheese, basil, garlic, olive oil, garlic powder, and black pepper in a medium bowl. Set aside.",
                "Meanwhile, cook pasta according to package directions.",
                "Drain pasta, and transfer to a serving bowl. Toss with the tomato mixture. Serve.",
              ],
              nutrition: {
                calories: 631,
                fat: "43%",
                protein: "19%",
                carbs: "38%",
              },
            },
            {
              name: "Baked Kale Chips",
              description:
                "1 serving contains 328 Calories. The macronutrient breakdown is 20% carbs, 73% fat, and 7% protein. This is a good source of potassium (20% of your Daily Value), calcium (42% of your Daily Value), and iron (84% of your Daily Value).",
              makes: "1 serving",
              prepTime: "5 minutes",
              cookTime: "10 minutes",
              ingredients: [
                "201g raw Scotch kale",
                "27g olive oil (salad or cooking)",
                "0.5g cayenne pepper (spices, red or cayenne)",
                "0.5g paprika (spices)",
                "0.5g cumin (spices, cumin seed)",
                "2g table salt",
              ],
              directions: [
                "Preheat oven to 350 degrees F.",
                "Wash the kale and pat dry with a paper towel. Carefully tear the leaves from the stem, then rip them further into bite-sized pieces. Set aside.",
                "In a medium bowl, mix together the olive oil, cayenne, paprika, cumin, and sea salt. Stir thoroughly to ensure the spices don’t clump together.",
                "Add the kale leaves to the bowl and coat them thoroughly with the mixture of spices and oil.",
                "Lay the kale out on a flat cooking tray, making sure that the pieces do not overlap. Sprinkle with sea salt.",
                "Bake the kale until the edges turn slightly brown, about 10-12 minutes. The chips should be slightly crunchy, particularly around the edges.",
              ],
              nutrition: {
                calories: 328,
                fat: "73%",
                protein: "7%",
                carbs: "20%",
              },
            },
          ],
        },
      ],
    },
  },
  // {
  //   kalories: "1200",
  //   cardbs: "60",
  //   fat: "27",
  //   protein: "60",
  //   breakfast: [],
  //   lunch: [],
  //   snack: [],
  //   dinner: [],
  // },
  // 2200: [
  //   {
  //     kalories: "",
  //     cardbs: "",
  //     fat: "",
  //     protein: "",
  //     breakfast: [],
  //     lunch: [],
  //     snack: [],
  //     dinner: [],
  //   },
  // ],
  getters: {
    training: (state) => state.training,
    anything: (state) => state.anything,
    keto: (state) => state.keto,
    vegan: (state) => state.vegan,
    vegetarian: (state) => state.vegetarian,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    async getTrainings({ commit }, params) {
      try {
        const response = await getTrainings(params);

        if (response.status === requestStatus.success) {
          if (response && response.response && response.response.data) {
            commit("set", {
              type: "training",
              items: response.response.data,
            });
            return true;
          } else {
            commit("set", {
              type: "training",
              items: null,
            });
            return false;
          }
        }

        return false;
      } catch (error) {
        console.error("Error in getTrainings:", error);
        return false; // Request failed due to an error
      }
    },
    async clearTraining({ commit }) {
      commit("set", {
        type: "training",
        items: null,
      });
    },
  },
};
