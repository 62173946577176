<template>
  <div class="loader-wrp" v-if="isVisible">
    <div class="loader-wrapper">
      <div class="packman"></div>
      <div class="dots">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalLoader",
  data: () => {
    return {
      isVisible: false,
    };
  },
  created() {
    window.getApp.$on("LOADER_VISIBILITY", (show) => {
      this.isVisible = show;
      document.documentElement.style.overflow = show ? "hidden" : "auto";
    });
  },
};
</script>

<style lang="scss">
.loader-wrp {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: rgba(250, 250, 250, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #f09f8f;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
.loader-wrapper {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.loader-wrapper .packman::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: #f09f8f;
  border-radius: 100px 100px 0 0;
  transform: translate(-50%, -50%);
  animation: pac-top 0.5s linear infinite;
  transform-origin: center bottom;
}

.loader-wrapper .packman::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background-color: #f09f8f;
  border-radius: 0 0 100px 100px;
  transform: translate(-50%, 50%);
  animation: pac-bot 0.5s linear infinite;
  transform-origin: center top;
}

@keyframes pac-top {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-30deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0);
  }
}

@keyframes pac-bot {
  0% {
    transform: translate(-50%, 50%) rotate(0);
  }

  50% {
    transform: translate(-50%, 50%) rotate(30deg);
  }

  100% {
    transform: translate(-50%, 50%) rotate(0);
  }
}

.dots .dot {
  position: absolute;
  z-index: -1;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f09f8f;
}

.dots .dot:nth-child(1) {
  left: 90px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(2) {
  left: 60px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(3) {
  left: 30px;
  animation: dot-stage1 0.5s infinite;
}

.dots .dot:nth-child(4) {
  left: 10px;
  animation: dot-stage2 0.5s infinite;
}

@keyframes dot-stage1 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-24px, 0);
  }
}

@keyframes dot-stage2 {
  0% {
    transform: scale(1);
  }

  5%,
  100% {
    transform: scale(0);
  }
}
</style>
