import { service_url } from "@/api/config";
import Http from "../api/http";

export function getTrainings(signature) {
  return Http._axios({
    url: `${service_url}/api/v1/trainings/${signature}`,
    method: "get",
  });
}

export function getGuides() {
  return Http._axios({
    url: `${service_url}/api/v1/guides`,
    method: "get",
  });
}
