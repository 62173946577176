<template>
  <v-app>
    <notifications group="foo" />
    <GlobalLoader />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import GlobalLoader from "./components/GlobalLoader";
export default {
  name: "App",
  components: {
    GlobalLoader,
  },
  created() {
    window.getApp = this;
  },
  data: () => ({}),
};
</script>
