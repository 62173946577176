import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import trainings from "./modules/trainings";

Vue.use(Vuex);

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    auth,
    trainings,
  },
});

export default store;
