import { getProfile } from "../../api/auth";
import { requestStatus } from "../../services/index";

export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    async getCurrentUser({ commit }, params) {
      await getProfile(params)
        .then((response) => {
          if (response.status === requestStatus.success) {
            if (response && response.response && response.response.data) {
              commit("set", {
                type: "currentUser",
                items: response.response.data,
              });
            } else {
              // localStorage.removeItem("user");
              // window.location.href = "/login";
            }
          }
        })
        .catch(() => {
          // localStorage.removeItem("user");
          // window.location.href = "/login";
        });
    },
  },
};
